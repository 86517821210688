import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";

import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { MatExpansionPanel } from "@angular/material";
import { GridApi } from "ag-grid-community";
import { SearchService } from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { CreateTemplateComponent } from "./create-template-modal/create-template-modal.component";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { DialogService } from "src/app/services/dialog.service";
import { PassTemplate } from "src/app/models/pass-template.model";
import { UpdateTemplateComponent } from "./edit-template-modal/edit-template-modal.component";
import { ViewTemplateComponent } from "./view-template-modal/view-template-modal.component";
import { OrganizationService } from "src/app/services/organization.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";
import { PassService } from "src/app/services/pass.service";

@Component({
  selector: "app-template-modal",
  templateUrl: "./template-modal.component.html",
  styleUrls: ["./template-modal.component.css"],
})
export class TemplateComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;

  private gridApi: GridApi;
  private applicationUsers = [];

  protected isRowSelected: boolean = false;
  protected selectedRequest = null;
  protected selectedPassTemplate: PassTemplate;
  gridOptions: Object;
  columnDefs: object = [{}];
  
  protected rowData: Array<any> = [];
  protected step = 0;
  protected rowClassRules;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive : boolean = false;
  // protected columnDefs: object;
  public defaultColDef : object;

  constructor(
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected searchService: SearchService,
    protected userService: UserService,
    protected notificationService: NotificationService,
    protected passTemplateService: PassTemplateService,
    protected dialogService: DialogService,
    private organizationService: OrganizationService,
    protected siteService:SiteService,
    protected messageService: MessageService,
    protected passService: PassService
  ){
    this.gridOptions = {
      getRowClass: (params) =>{
        if(params.data.Active === 0){
          return 'row-inactive';
        }
      }
    }
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Pass Template");

     //labels by message service
     if (localStorage.getItem('TemplateLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('TemplateLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Pass Template",
        "PassTemplate"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('TemplateLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
            })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        // this.rowData = this.rowData.filter(
        //   (f) =>
        //     f.OrganizationID == this.userService.organizationID &&
        //     ((f.IsPersonal == 1 && f.CreatedBy == this.userService.userID) ||
        //       f.IsPersonal == 2)
        // );
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          // this.rowData = this.rowData.filter(
          //   (f) =>
          //     f.OrganizationID == this.userService.organizationID &&
          //     ((f.IsPersonal == 1 && f.CreatedBy == this.userService.userID) ||
          //       f.IsPersonal == 2)
          // );
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
         this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          // this.rowData = this.rowData.filter(
          //   (f) =>
          //     f.OrganizationID == this.userService.organizationID &&
          //     ((f.IsPersonal == 1 && f.CreatedBy == this.userService.userID) ||
          //       f.IsPersonal == 2)
          // );  
          this.gridApi.paginationGoToPage(0);
                
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    //  template data changed
    this.passTemplateService.passTemplateDataChanged.subscribe(
      (data: boolean) => {
        this.isRowSelected = false;
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
        
            // this.rowData = this.rowData.filter(
            //   (f) =>
            //     f.OrganizationID == this.userService.organizationID &&
            //     ((f.IsPersonal == 1 &&
            //       f.CreatedBy == this.userService.userID) ||
            //       f.IsPersonal == 2)
            // );
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    );
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDefs();
  }

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        width: 20,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },

      {
        headerName: this.screenLabels["TemplateNameAgGrid"]
          ? this.screenLabels["TemplateNameAgGrid"]
          : "Template Name",
        // headerName: "Template Name",
        field: "TemplateName",
        sortable: true,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["TemplateCatgeoryAgGrid"]
          ? this.screenLabels["TemplateCatgeoryAgGrid"]
          : "Template Category",
        // headerName:"Template Category",
        field: "CategoryName",
        sortable: true,
        filter: true,
        hide: false,
      },
    ];
  }
  // invoked when ag-grid is initialized
  protected onGridReady(params): void {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // On Create Template Pass
  onCreateTemplatePass() {
    const dialogRef = this.dialog.open(CreateTemplateComponent, {
      disableClose: true,
      data: [],
      backdropClass: "backdropBackground",
    });
  }

  //on organization dropdown change
  onOrganizationChange($event) {
    var data = $event;

    // log organization change
    this.organizationService.logOrganizationChange(
      this.searchService.getOrganizationID(),
      data.value
    );

    this.searchService.setOrganizationID(data.value);

    this.userService.organizationID = data.value;
    this.userService.userOrganizations.forEach((element) => {
      if (element.OrganizationID == this.userService.organizationID)
        this.userService.IsAdmin = element.IsAdmin
          ? element.IsAdmin == 1
            ? 1
            : 0
          : 0;
    });
    this.userService.organizationChanged.emit(true);
    this.searchService.setPageName("Pass Template");
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = [];
        this.rowData = this.searchService.searchRecords;
        // this.rowData = this.rowData.filter(
        //   (f) =>
        //     f.OrganizationID == this.userService.organizationID &&
        //     ((f.IsPersonal == 1 && f.CreatedBy == this.userService.userID) ||
        //       f.IsPersonal == 2)
        // );
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    this.passService.getUserTemplateCategoriesAPI(this.userService.userID, this.userService.organizationID);
  }
  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: PassTemplate[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedPassTemplate = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
    if(this.selectedPassTemplate.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // Invoked when Edit Template Category
  onEditTemplate($event) {
    if (this.selectedPassTemplate) {
      this.dialog.open(UpdateTemplateComponent, {
        disableClose: true,
        data: this.selectedPassTemplate,
      });
    }
  }

  // Invoked when View Pass template
  openPassTemplate($event) {
    var selectedPassTemplate: PassTemplate = $event.data;
    this.dialog.open(ViewTemplateComponent, {
      disableClose: true,
      data: selectedPassTemplate,
    });
  }

  // Delete Pass Templates
  DeletePassTemplate() { 
    var message: string = this.screenLabels["DeleteTemplateWarningMsg"] ? this.screenLabels["DeleteTemplateWarningMsg"] : "Are you sure you want to Delete Pass Template?";
    const deleteLabel = this.screenLabels["DeletePassTemplateConfirmLabel"] ? this.screenLabels["DeletePassTemplateConfirmLabel"] : "Delete Pass Template";
    const successLabel = this.screenLabels["DeleteTemplateSuccessMsg"] ? this.screenLabels["DeleteTemplateSuccessMsg"] : "Template was Deleted Successfully.";
    this.dialogService
      .openConfirmDialog(deleteLabel, message)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.selectedPassTemplate.Active  = 0
          this.passTemplateService
            .deletePassTemplateAPI(this.selectedPassTemplate)
            .then(() => {
              this.notificationService.success(
                successLabel
              );
            });
        }
      });
  }
}
