import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { ViewEntryPointModalComponent } from "../view-entry-point/view-entry-point-modal.component";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { SearchService } from "src/app/services/search.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { Printers } from "src/app/models/printers.model";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-create-entry-point",
  templateUrl: "./create-entry-point.component.html",
  styleUrls: ["./create-entry-point.component.css"],
})
export class CreateEntryPointComponent implements OnInit, AfterViewChecked {
  @ViewChild("createEntryPointForm") createEntryPointForm: NgForm;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;

  protected step = 0;

  protected entryPoint: EntryPoint;
  protected entryPointStation: EntryPointStation;
  protected addedEntryPointStations: EntryPointStation[] = [];
  protected entryPointZones: EntryPointZone[] = [];

  ipPattern =
    "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
  // descriptionFormControl = new FormControl("", [Validators.required]);
  //hostnameFormControl = new FormControl("", []);
  //phoneFormControl = new FormControl("", [Validators.required]);
  // directionFormControl = new FormControl("");
  // entryPointZoneFormControl = new FormControl("", [Validators.required]);
  descriptionFormControl = new FormControl("", [Validators.required,this.noWhitespaceValidator]);
  hostnameFormControl = new FormControl("", [Validators.required, this.noWhitespaceValidator]);
  phoneFormControl = new FormControl("", [Validators.required]);
  ipaddrsFormControl = new FormControl("", [ Validators.pattern(this.ipPattern),]);
  directionFormControl = new FormControl("", [Validators.required]);
  entryPointZoneFormControl = new FormControl("", [Validators.required]);
  printerhostnameFormControl = new FormControl("", [this.noWhitespaceValidator]);
  printeripaddrsFormControl = new FormControl("", [ Validators.pattern(this.ipPattern),]);
  addedHostFormControl = new FormControl("", [this.noWhitespaceValidator]);
  addedEPHostFormControl = new FormControl("", [this.noWhitespaceValidator]);

  protected onHide: boolean = true;
  protected disableOnAdd: boolean = true;

  //protected printerHostName: string;
  //protected printerIPAddress: any;
  //protected employeePrinter: Array<any> = [ {name:'dummyData',ip:'10.10.10.10'} ];
  //protected employeePrinter = Array<{name: string, ip: string}>;
  //protected employeePrinter: { name: string; ip: string }[] = [];
  protected onPrinterHide: boolean = true;
  protected disableOnAddPrinter: boolean = true;
  protected isEnabled: boolean = false;
  protected cloudPrintEnableEP: boolean = false;
  protected showPrinters: boolean = false;
  protected enableHostError: boolean = false;
  protected enableIPError: boolean = false;
  protected printer: Printers;
  protected addedPrinters: Printers[] = [];
  protected disableOnSave: boolean = false;
  protected enableHostErrorEP: boolean = false;
  protected disableCreateOnHost: boolean = false;
  protected enableIPErrorEP: boolean = false;
  protected iPAdressArray:Array<String> = [];
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected formControlValidator:boolean = false;
  protected EPFormControlValidator:boolean = false;
  protected addedFormControlValidator:boolean = false;
  protected addedEPFormControlValidator:boolean = false;
  protected enableAddedErrorMsg:Array<boolean> = [];
  protected enableAddedEPErrorMsg:Array<boolean> = [];
  protected disableForAddedEPError:boolean = false;
  protected disableForAddedPrintrError:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPoint,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewEntryPointModalComponent>,
    protected entryPointService: EntryPointService,
    private userService: UserService,
    private notificationService: NotificationService,
    private entryPointZoneService: EntryPointZoneService,
    private requiredFieldsService: RequiredFieldsService,
    private searchService: SearchService,
    private siteService: SiteService,
    private utilService: UtilityService,
    protected messageService: MessageService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }

    //labels by message service
    if (localStorage.getItem('CreateEntryPointLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateEntryPointLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Entry Point Modal",
        "EntryPoint"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateEntryPointLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.entryPoint = new EntryPoint(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      this.userService.userID,
      null,
      null,
      null,
      null
    );
    this.entryPointStation = new EntryPointStation(
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      this.userService.userID,

      this.utilService.getCurrentSiteDT()
    );
    this.printer = new Printers(
      0,
      null,
      null
    );
    if (this.entryPointZones.length == 0) {
      this.entryPointZoneService
        .getAllEntryPointZonesAPI()
        .then(() => {
          this.entryPointZones = [
            ...this.entryPointZoneService.getEntryPointZone(),
          ];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }

    if (localStorage.getItem("isCloudPrinting") === "true") {
      this.isEnabled = true;
    }
    else if (localStorage.getItem("isCloudPrinting") === "false") {
      this.isEnabled = false;
    }

    if (this.isEnabled) {
      this.showPrinters = true;
    }
    else {
      this.showPrinters = false;
    }
    this.onValidatingAddButton();
    this.onValidatingAddPrinterButton();
    this.onDisableSave();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onMainHeaderClose() {
    this.freezePanel.open();
  }
  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      "Create Entry Point",
      this.createEntryPointForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCloudPrintChange() { }

  //...............Printer Stuff...................
  onDefaultPrinterSelection(event) {
    //this.addPrinterFieldValue();
    this.onValidatingAddPrinterButton();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.addedPrinters.push(this.printer);
      this.printer = new Printers(
        0,
        null,
        null
      );
      this.printeripaddrsFormControl.reset();
      this.printerhostnameFormControl.reset();
      this.addedPrinters.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });

      this.deletePrinterEmptyValue();
      this.disableOnAddPrinter = false;
    }
    this.onDisableSave();
  }

  onPrinterHostNameChange(event) {
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }
    //validation for hostname
    this.printerHostNameValidation();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }
    this.onDisableSave();
  }

  onCreatePrinterHostName(event, index){
    this.onCreateDisableSave(event);
    //for added Printers empty space validation
   /* this.addedPrinters.forEach((ele,i) => {
      this.enableAddedErrorMsg[i] = false;
      this.disableForAddedPrintrError = false;
      console.log("value 1",this.disableForAddedPrintrError);
      if(index === i) {
        let results = (event || '').trim().length ? { 'whitespace': false } : { 'whitespace': true };
        console.log("partt",(event || '').trim().length)
        if(results.whitespace === true) {
        this.enableAddedErrorMsg[i] = true;
        this.disableForAddedPrintrError = true;
        console.log("value 2",this.disableForAddedPrintrError);
        return this.disableForAddedPrintrError;
        }
      }
    }); */
  }
  
  onCreateEntryPointHostName(event, index){
    this.onCreateDisableSave(event);
    //for added EPs emptyspace validation
  /*  this.addedEntryPointStations.forEach((ele,i) => {
      this.enableAddedEPErrorMsg[i] = false;
      this.disableForAddedEPError = false;
      if(index === i) {
        let results = (event || '').trim().length ? { 'whitespace': false } : { 'whitespace': true };
        if(results.whitespace === true) {
        this.enableAddedEPErrorMsg[i] = true;
        //made sure savebtn know the error
        this.disableForAddedEPError = true;
        return this.disableForAddedEPError;
        }
      }
    }); */
  }

  onPrinterIPAddressChange(event) {
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }

    this.printerIpAddressValidation();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }
    this.onDisableSave();
  }

  printerHostNameValidation() {
    this.enableHostError = false;
    //duplicate validation for hostname  
    this.addedPrinters.forEach(element => {
      if (this.printer.HostName.toLowerCase() === element.HostName.toLowerCase()) {
        this.enableHostError = true;
        return this.enableHostError;
      }
    })
    return this.enableHostError;
  }

  printerIpAddressValidation() {
    this.enableIPError = false;
    //Duplicate validation for ipaddress  
    this.iPAdressArray.forEach(element => {
      if ((this.printer.IPAddress == element) && element != null) {
        this.enableIPError = true;
        return this.enableIPError;
      }
      else if((this.printer.IPAddress == this.entryPointStation.IPAddress) && this.printer.IPAddress != null && this.entryPointStation.IPAddress != null) {
        this.enableIPError = true;
        return this.enableIPError;
      }
    });
    return this.enableIPError;
  }
  onValidatingAddPrinterButton() {
    //for enabling add-if we have 1row
    if (this.onPrinterHide && this.addedPrinters.length >= 1) {
      this.disableOnAddPrinter = true;
    } else if (!this.onPrinterHide && this.addedPrinters.length >= 1) {
      this.disableOnAddPrinter = false;
    } else if (!this.onPrinterHide && this.addedPrinters.length == 0) {
      this.disableOnAddPrinter = false;
    } else if (this.onPrinterHide && this.addedPrinters.length == 0) {
      this.disableOnAddPrinter = true;
    }
  }
  addPrinterFieldValue() {
    if (!this.onPrinterHide) {
      this.onPrinterHide = true;
    }
    this.onValidatingAddPrinterButton();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.addedPrinters.push(this.printer);
      this.printer = new Printers(
        0,
        null,
        null
      );
      this.printeripaddrsFormControl.reset();
      this.printerhostnameFormControl.reset();

      this.addedPrinters.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
    }
    this.disableOnAddPrinter = true;

    this.onDisableSave();

  }

  deletePrinterEmptyValue() {
    this.onPrinterHide = false;
    this.printeripaddrsFormControl.reset();
    this.printerhostnameFormControl.reset();
    //for enabling add-if we have 1row
    this.onValidatingAddPrinterButton();
    this.onDisableSave();
    this.getPrinterHostReqErrorMessage();
  }

  deletePrinterFieldValue(index: number) {
    this.addedPrinters = this.addedPrinters.filter(
      (e) => e !== this.addedPrinters[index]
    );
    this.iPAdressArray = [];
    this.addedEntryPointStations.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    this.addedPrinters.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    //for enabling add-if we have 1row
    this.onValidatingAddPrinterButton();
    this.onDisableSave();
    this.onCreateDisableSave(this.data);

    //for added Printers emptyspace validation
   /* this.addedPrinters.forEach((ele,i) => {
      this.enableAddedErrorMsg[i] = false;
      this.disableForAddedPrintrError = false;
      if(index === i) {
        let results = (ele.HostName || '').trim().length ? { 'whitespace': false } : { 'whitespace': true };
        console.log("1st partttttt",(ele.HostName || '').trim().length);
        if(results.whitespace === true) {
        this.enableAddedErrorMsg[i] = true;
        this.disableForAddedPrintrError = false;
        return this.disableForAddedPrintrError;
        }
      }
    }); */
  }

  //...............Printer Stuff...................End
  //...............Entry Point Station..............
  onDisableSave() {
    if (this.showPrinters) {
      if ((this.onPrinterHide && !this.printer.HostName) || this.enableHostError || this.enableIPError) {
        this.disableOnSave = true;
      }
      else if ((this.onHide && !this.entryPointStation.HostName) || this.enableHostErrorEP || this.enableIPErrorEP) {
        this.disableOnSave = true;
      }
      else {
        this.disableOnSave = false;
      }
    }
    else if (!this.showPrinters) {
      if (this.onHide && !this.entryPointStation.HostName) {
        this.disableOnSave = true;
      }
      else {
        this.disableOnSave = false;
      }
    }
  }

  // validation added for Host name in Printers and Entry points
  onCreateDisableSave(event) {

    this.disableCreateOnHost = false;
    
      this.addedPrinters.forEach(elements => {
      if(elements.HostName === "" && elements.Active ){
      this.disableCreateOnHost = true;
      }
    });
    this.addedEntryPointStations.forEach(element => {
      if(element.HostName === "" && element.Active ){
      this.disableCreateOnHost = true;
      }
    });
  }

  onHostNameChange(event) {
    if (this.entryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP)
      this.disableOnAdd = false;
    else
      this.disableOnAdd = true;
    this.hostNameValidation();
    if (this.entryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP)
      this.disableOnAdd = false;
    else
      this.disableOnAdd = true;
    this.onDisableSave();
  }

  onIPAddressChange(event) {
    if (this.entryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP)
      this.disableOnAdd = false;
    else
      this.disableOnAdd = true;
    this.ipAddressValidation();
    if (this.entryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP)
      this.disableOnAdd = false;
    else
      this.disableOnAdd = true;
    this.onDisableSave();
  }

  hostNameValidation() {
    this.enableHostErrorEP = false;
    //duplicate validation for hostname  
    this.addedEntryPointStations.forEach(element => {
      if (this.entryPointStation.HostName.toLowerCase() === element.HostName.toLowerCase()) {
        this.enableHostErrorEP = true;
        return this.enableHostErrorEP;
      }
    })
    return this.enableHostErrorEP;
  }

  ipAddressValidation() {
    this.enableIPErrorEP = false;
    //Duplicate validation for ipaddress  
    this.iPAdressArray.forEach(element => {
      if ((this.entryPointStation.IPAddress == element) && element != null) {
        this.enableIPErrorEP = true;
        return this.enableIPErrorEP;
      }
      else if((this.printer.IPAddress == this.entryPointStation.IPAddress) && this.printer.IPAddress != null && this.entryPointStation.IPAddress != null) {
        this.enableIPErrorEP = true;
        return this.enableIPErrorEP;
      }
    });
    return this.enableIPErrorEP;
  }

  onValidatingAddButton() {
    //for enabling add-if we have 1row
    if (this.onHide && this.addedEntryPointStations.length >= 1) 
      this.disableOnAdd = true;
    else if (!this.onHide && this.addedEntryPointStations.length >= 1) 
      this.disableOnAdd = false;
    else if (!this.onHide && this.addedEntryPointStations.length == 0) 
      this.disableOnAdd = false;
    else if (this.onHide && this.addedEntryPointStations.length == 0) 
      this.disableOnAdd = true;
    
  }

  // Add EntryPointStations in the UI
  addFieldValue() {
    if (!this.onHide) 
      this.onHide = true;
    //for enabling add-if we have 1row
    this.onValidatingAddButton();

    if (this.entryPointStation.HostName) {
      this.addedEntryPointStations.push(this.entryPointStation);
      this.entryPointStation = new EntryPointStation(
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        this.userService.userID,
        this.utilService.getCurrentSiteDT()
      );

      this.ipaddrsFormControl.reset();
      this.hostnameFormControl.reset();
      this.addedEntryPointStations.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
    }

    this.disableOnAdd = true;
    this.onDisableSave();
  }

  // Remove Entry Point from UI
  deleteFieldValue(index: number) {
    this.addedEntryPointStations = this.addedEntryPointStations.filter(
      (e) => e !== this.addedEntryPointStations[index]
    );
    this.iPAdressArray = [];
    this.addedEntryPointStations.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    this.addedPrinters.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.onDisableSave();
    this.onCreateDisableSave(this.data);

    //for added EPs emptyspace validation
    /*this.addedEntryPointStations.forEach((ele,i) => {
      this.enableAddedEPErrorMsg[i] = false;
      this.disableForAddedEPError = false;
      if(index === i) {
        let results = (ele.HostName || '').trim().length ? { 'whitespace': false } : { 'whitespace': true };
        console.log("1st part",(ele.HostName || '').trim().length);
        if(results.whitespace === true) {
        this.enableAddedEPErrorMsg[i] = true;
        this.disableForAddedEPError = true;
        return  this.disableForAddedEPError;
        }
      }
    }); */
  }

  deleteEmptyFieldValue() {
    this.onHide = false;
    this.ipaddrsFormControl.reset();
    this.hostnameFormControl.reset();
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.onDisableSave();
    this.getHostRequiredErrorMessage();
  }

  //...............Entry Point Station..............End
  onSaveChanges() {
    if (this.entryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP) 
      this.addFieldValue();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) 
      this.addPrinterFieldValue();
    const entryPointPromise = this.entryPointService
      .createEntryPointAPI(this.entryPoint, this.addedPrinters, this.addedEntryPointStations, this.cloudPrintEnableEP)
      .then(() => {
        this.notificationService.success(this.entryPointService.message);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.onNoClick();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  entryPointStationFormGrp: FormGroup = new FormGroup({
     phone: this.phoneFormControl,
    ipaddrs: this.ipaddrsFormControl,
    hostname: this.hostnameFormControl,
    description: this.descriptionFormControl,
  });
  
  noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  getDescRequiredErrorMessage() {
    if (this.descriptionFormControl.hasError('whitespace')) {
      if (this.descriptionFormControl.value !== "") return " ";
      else return this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value";
    }
  }
  getPrinterHostReqErrorMessage() {
    if (this.onPrinterHide) {
      if (this.printerhostnameFormControl.hasError('whitespace')) {
        this.formControlValidator = true;
        return this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value";
      }
      else this.formControlValidator = false;
    }
    else this.formControlValidator = false;
  }
  getHostRequiredErrorMessage() {
    if (this.onHide) {
      if (this.hostnameFormControl.hasError('whitespace')) {
        this.EPFormControlValidator = true;
        return this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value";
      }
      else this.EPFormControlValidator = false;
    }
    else this.EPFormControlValidator = false;
  }
  getPhoneRequiredErrorMessage() {
    return this.phoneFormControl.hasError("required")
      ? this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value"
      : " ";
  }
  getDirectionRequiredErrorMessage() {
    return this.directionFormControl.hasError("required")
      ? this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value"
      : " ";
  }
}
