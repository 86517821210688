import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { NgForm , FormControl, Validator, Validators, AbstractControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { LoginRegistrationService } from "../services/login-registration.service";
import { NotificationService } from "../services/notification.service";
import { ISubmitUserRoleRequestAPI } from "../services/user-invitation.service";
import { ViewSelectorService } from "../services/view-selector.service";
import { UserService } from "../services/user.service";
import { MatSelectChange } from "@angular/material";

@Component({
  selector: "app-login-registration",
  templateUrl: "./desktop/login-registration.component.desktop.html",
  styleUrls: ["./desktop/login-registration.component.desktop.css"],
})
export abstract class LoginRegistrationComponent implements OnInit {
  emailFormControl = new FormControl('',[
    Validators.required,
    Validators.email,
    this.customEmailValidation.bind(this),
  ])
  constructor(
    private router: Router, 
    protected authService: AuthService, 
    protected viewSelectorService: ViewSelectorService, 
    protected loginRegistrationService: LoginRegistrationService, 
    protected notificationService: NotificationService,
    protected userService: UserService
    ) {
    this.isLoginBtnDisabled = false;
    if (viewSelectorService.getIsMobile()) {
      this.isMobile = true;
    }
  }

  formLoading = false;
  labelPosition = "after";
  public guestDomainRegistration: boolean;
  public wbDomain: boolean;
  public guestDomainLogin: boolean;
  public isLoginBtnDisabled: boolean;
  public tabIndex = 0;
  protected isMobile = false;
  protected sites: any;
  protected selectedSite: any;
  protected selectedSiteID: number;
  protected selectedOrganizationID: number;
  protected organizations: any;
  protected orgsLoaded: boolean = true;
  protected preferredlanguage = [];
  protected oldLanguageID:any;
  protected languageChanged: boolean = false;
  protected selectedLanguageID: number = 1;

  @ViewChild("emailField") emailField: ElementRef;
  @ViewChild("userRegisterForm") userRegisterForm: NgForm;

  ngOnInit() {
    this.loginRegistrationService.getSiteListAPI().subscribe((res: any) => {
      this.sites = res['body'];
    });
    this.userService.getPreferredLanguage().subscribe((response)=>{
      this.preferredlanguage = response ;
    })
  }

  customEmailValidation(control: AbstractControl): { [key: string]: any } | null {
    const email = control.value as string;
   if(email){
    // Split the email into local and domain parts
    const [localPart, domainPart] = email.split('@');
  
    // Custom validation for the local part
    if (localPart) {
      // Check if local part contains only numbers
      if (/^\d+$/.test(localPart)) {
        return { 'customValidation': true, 'numericOnly': true };
      }
  
      // Check if local part contains more than one dot
      const dotCount = localPart.split('.').length - 1;
      if (dotCount > 1) {
        return { 'customValidation': true, 'moreThanOneDot': true };
      }

      const hyphenCount = localPart.split('-').length - 1;
    if (hyphenCount > 1) {
      return { 'customValidation': true, 'moreThanOneHyphen': true };
    }
    // Check if local part contains numbers or hyphens initially
    if (/^[0-9-]/.test(localPart)) {
      return { 'customValidation': true, 'numbersOrHyphenInitially': true };
    }
    // Check if local part ends with a hyphen
    if (localPart.endsWith('-')) {
      return { 'customValidation': true, 'hyphenAtEnd': true };
    }
    }

    if (domainPart) {
      // Check if domain part contains hyphen
      if (domainPart.includes('-')) {
        return { 'customValidation': true, 'hyphenInDomain': true };
      }
      // Check if domain part contains numbers
    if (/\d/.test(domainPart)) {
      return { 'customValidation': true, 'numbersInDomain': true };
    }
    }
   }
    return null;
  }
  

  public registerTab() {
    this.tabIndex = 1;
  }

  public loginTab() {
    this.tabIndex = 0;
  }

  public onLoadingClick() {
    this.formLoading = false;
  }

  onBlurRegistration(event: any) {
    const userEmail = event.target.value;
    const domain = userEmail.substring(userEmail.lastIndexOf("@") + 1);
    if (domain === "warnerbros.com" || domain === "wb.com" || domain === "") {
      this.guestDomainRegistration = false;
    } else {
      this.guestDomainRegistration = true;
    }
  }

  onBlurLogin(event: any) {
    const userEmail = event.target.value;
    const domain = userEmail.substring(userEmail.lastIndexOf("@") + 1);
    if (domain === "warnerbros.com" || domain === "wb.com" || domain === "") {
      this.guestDomainLogin = false;
    } else {
      this.guestDomainLogin = true;
    }
  }

  myLoadEvent() {
    this.formLoading = true;
  }

  onSiteChange(event) {
    this.selectedSite = event.value
    this.loginRegistrationService.getSelfRegOrganizations(this.selectedSite).subscribe((res: any) => {
      let response = res.body;
      this.orgsLoaded = false;

      this.organizations = response;
    })
    this.selectedOrganizationID = null;
  }

  onOrgSelectionChange(event) {
    this.selectedOrganizationID = event.value;
  }

  onSelectionChange(event: MatSelectChange) {

    const newValue = event.value;
    localStorage.setItem("PrefLanguage", newValue); 

    // if(this.oldLanguageID === event.value) {
    //   this.languageChanged = false;
    // }
    // else {
    //   this.languageChanged = true;
    // }
  }
  
  onRegister() {
    const body: ISubmitUserRoleRequestAPI = {
      UserIDToSubmit: null,
      SiteID: this.userRegisterForm.value.site,
      Email: this.emailFormControl.value,
      FirstName: this.userRegisterForm.value.firstName,
      LastName: this.userRegisterForm.value.lastName,
      Phone: this.userRegisterForm.value.phone,
      UserID: null,
      OrganizationID: this.userRegisterForm.value.organization,
      RequesterComments: this.userRegisterForm.value.comments,
      Role: [],
      Team: []
    }
    this.loginRegistrationService.submitUserRoleRequestBySelf(body).subscribe((res: any) => {
      this.userRegisterForm.resetForm();
      this.emailFormControl.reset('')
      this.notificationService.success(res.body.message);
    },
    (err) => {
      console.log(err);
      console.log(err.message);
      this.userRegisterForm.resetForm();
      this.emailFormControl.reset('')
      this.notificationService.failure(err.message);
     }
    )
  }

}

