import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { DialogService } from "../../../services/dialog.service";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { SiteService } from "src/app/services/site.service";
import { Printers } from "src/app/models/printers.model";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-update-entry-point",
  templateUrl: "./update-entry-point.component.html",
  styleUrls: ["./update-entry-point.component.css"],
})
export class UpdateEntryPointComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected step = 0;
  protected tempEntryPointStation: EntryPointStation;
  protected tempEntryPointStations: EntryPointStation[] = [];
  protected entryPoint: EntryPoint;
  protected entryPointStations: EntryPointStation[] = [];
  protected entryPointStationsIndexMarkedForDeletion: number[] = [];
  //protected addedEntryPointStations: EntryPointStation[] = [];

  protected entryPointDescription: string;
  protected entryPointPhone: string;
  protected entryPointDirection: string;
  protected entryPointZoneID: number;

  protected isEntryPointStationsLoading: boolean = true;
  protected entryPointZones: EntryPointZone[] = [];

  ipPattern =
    "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
  descriptionFormControl = new FormControl("", [Validators.required, this.noWhitespaceValidator]);
  hostnameFormControl = new FormControl("", [Validators.required, this.noWhitespaceValidator]);
  phoneFormControl = new FormControl("", [Validators.required]);
  ipaddrsFormControl = new FormControl("", [Validators.pattern(this.ipPattern),]);
  directionFormControl = new FormControl("", [Validators.required]);
  entryPointZoneFormControl = new FormControl("", [Validators.required]);
  printerhostnameFormControl = new FormControl("", [this.noWhitespaceValidator]);
  printeripaddrsFormControl = new FormControl("", [Validators.pattern(this.ipPattern),]);
  //addedHostFormControl = new FormControl("", [this.noWhitespaceValidator]);
  //addedEPHostFormControl = new FormControl("", [this.noWhitespaceValidator]);

  protected onHide: boolean = true;
  protected disableOnAdd: boolean = true;
  protected onPrinterHide: boolean = true;
  protected disableOnAddPrinter: boolean = true;
  protected isEnabled: boolean = false;
  protected cloudPrintEnableEP: boolean = false;
  protected showPrinters: boolean = false;
  protected enableHostError: boolean = false;
  protected enableIPError: boolean = false;
  protected printer: Printers;
  protected addedPrinters: Printers[] = [];
  protected disableOnSave: boolean = false;
  protected disableOnHostName: boolean = false;
  protected enableHostErrorEP: boolean = false;
  protected enableIPErrorEP: boolean = false;
  protected isPrinterLoading: boolean = true;
  protected iPAdressArray: Array<String> = [];
  protected phoneIsUSA: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected formControlValidator:boolean = false;
  protected EPFormControlValidator:boolean = false;
  protected addedFormControlValidator:boolean = false;
  protected addedEPFormControlValidator:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPoint,
    protected dialogRef: MatDialogRef<UpdateEntryPointComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    protected dialogService: DialogService,
    protected entryPointZoneService: EntryPointZoneService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }

    //labels by message service
    if (localStorage.getItem('UpdateEntryPointLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UpdateEntryPointLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Update Entry Point Modal",
        "EntryPoint"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UpdateEntryPointLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.entryPoint = { ...this.data };

    // this.entryPointDescription = this.data.Description;
    // this.entryPointPhone = this.data.Phone;
    // this.entryPointDirection = this.data.EmailDirections;
    // this.entryPointZoneID = this.data.EntryPointZoneID;

    this.tempEntryPointStation = new EntryPointStation(
      0,
      this.data.EntryPointID,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.printer = new Printers(
      0,
      null,
      null
    );
    if (this.entryPointZones.length == 0) {
      this.entryPointZoneService
        .getAllEntryPointZonesAPI()
        .then(() => {
          this.entryPointZones = [
            ...this.entryPointZoneService.getEntryPointZone(),
          ];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }

    if (this.entryPointService.getEntryPoints().length) {
      this.entryPointService.getEntryPointByID(this.siteService.selectedSiteID, this.data.EntryPointID).subscribe((res: any) => {

        let response = res.body;
        this.entryPointDescription = response.Description;
        this.entryPointPhone = response.Phone;
        this.entryPointDirection = response.EmailDirections;
        this.entryPointZoneID = response.EntryPointZoneID;
        this.cloudPrintEnableEP = response.CloudPrintEnabled;

        this.isPrinterLoading = true;
        this.isEntryPointStationsLoading = true;
        // if(response.Entrypointstations.length > 0){
        //   this.isEntryPointStationsLoading = false;
        // }
        this.tempEntryPointStations = response.EntryPointStations;
        this.isEntryPointStationsLoading = false;
        this.addedPrinters = response.Printers;
        this.isPrinterLoading = false;
      });
    }
    else {
      this.isEntryPointStationsLoading = false;
    }

    if (localStorage.getItem("isCloudPrinting") === "true") {
      this.isEnabled = true;
    }
    else if (localStorage.getItem("isCloudPrinting") === "false") {
      this.isEnabled = false;
    }

    if (this.isEnabled) {
      this.showPrinters = true;
    }
    else {
      this.showPrinters = false;
    }

    //for Duplicate ipaddress
    setTimeout(() => {
      this.tempEntryPointStations.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
      this.addedPrinters.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
    }, 3000);

    this.onPrinterHide = false;
    this.onHide = false;
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.onValidatingAddPrinterButton();
    this.onDisableSave();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  entrydataForm: FormGroup = new FormGroup({
    hostname: this.hostnameFormControl,
    description: this.descriptionFormControl,
    phone: this.phoneFormControl,
    ipaddrs: this.ipaddrsFormControl,
  });

  onCloudPrintChange() { }

  //...............Printer Stuff...................
  onDefaultPrinterSelection(event) {
    //this.addPrinterFieldValue();
    this.onValidatingAddPrinterButton();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.addedPrinters.push(this.printer);
      this.printer = new Printers(
        0,
        null,
        null
      );
      this.printeripaddrsFormControl.reset();
      this.printerhostnameFormControl.reset();

      this.addedPrinters.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
      this.deletePrinterEmptyValue();
      this.disableOnAddPrinter = false;
    }
    this.onDisableSave();
  }

  onPrinterHostNameChange(event) {
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }
    //validation for hostname
    this.printerHostNameValidation();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }
    this.onDisableSave();
  }

  onExistPrinterHostName(event) {
    this.onDisableHostName(event);
  }
  onExistEntryPointHostNamee(event) {
    this.onDisableHostName(event);
  }

  onPrinterIPAddressChange(event) {
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }

    this.printerIpAddressValidation();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.disableOnAddPrinter = false;
    } else {
      this.disableOnAddPrinter = true;
    }
    this.onDisableSave();
  }

  printerHostNameValidation() {
    this.enableHostError = false;
    //duplicate validation for hostname  
    this.addedPrinters.forEach(element => {
      if (this.printer.HostName.toLowerCase() === element.HostName.toLowerCase()) {
        this.enableHostError = true;
        return this.enableHostError;
      }
    })
    return this.enableHostError;
  }

  printerIpAddressValidation() {
    this.enableIPError = false;
    //Duplicate validation for ipaddress  
    this.iPAdressArray.forEach(element => {
      if ((this.printer.IPAddress == element) && element != null) {
        this.enableIPError = true;
        return this.enableIPError;
      }
      else if ((this.printer.IPAddress == this.tempEntryPointStation.IPAddress) && this.printer.IPAddress != null && this.tempEntryPointStation.IPAddress != null) {
        this.enableIPError = true;
        return this.enableIPError;
      }
    });
    return this.enableIPError;
  }
  onValidatingAddPrinterButton() {
    //for enabling add-if we have 1row
    if (this.onPrinterHide && this.addedPrinters.length >= 1) {
      this.disableOnAddPrinter = true;
    } else if (!this.onPrinterHide && this.addedPrinters.length >= 1) {
      this.disableOnAddPrinter = false;
    } else if (!this.onPrinterHide && this.addedPrinters.length == 0) {
      this.disableOnAddPrinter = false;
    } else if (this.onPrinterHide && this.addedPrinters.length == 0) {
      this.disableOnAddPrinter = true;
    }
  }
  addPrinterFieldValue() {
    if (!this.onPrinterHide) {
      this.onPrinterHide = true;
    }
    this.onValidatingAddPrinterButton();
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.addedPrinters.push(this.printer);
      this.printer = new Printers(
        0,
        null,
        null
      );
      this.printeripaddrsFormControl.reset();
      this.printerhostnameFormControl.reset();
      this.addedPrinters.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
    }
    this.disableOnAddPrinter = true;

    this.onDisableSave();

  }

  deletePrinterEmptyValue() {
    this.onPrinterHide = false;
    this.printeripaddrsFormControl.reset();
    this.printerhostnameFormControl.reset();
    //for enabling add-if we have 1row
    this.onValidatingAddPrinterButton();
    this.onDisableSave();
    this.getPrinterHostReqErrorMessage();
  }

  deletePrinterFieldValue(index: number) {
    //  this.addedPrinters = this.addedPrinters.filter(
    //   (e) => e !== this.addedPrinters[index]
    // );
    this.addedPrinters[index].Active = false;
    this.addedPrinters = this.addedPrinters.filter(ele => ele.Active !== false);
    this.iPAdressArray = [];
    this.tempEntryPointStations.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    this.addedPrinters.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    //for enabling add-if we have 1row
    this.onValidatingAddPrinterButton();
    this.onDisableSave();
    this.onDisableHostName(this.data);
  }

  //...............Printer Stuff...................End
  //...............Entry Point Station..............
  onDisableSave() {
    if (this.showPrinters) {
      if ((this.onPrinterHide && !this.printer.HostName) || this.enableHostError || this.enableIPError) {
        this.disableOnSave = true;
      }
      else if ((this.onHide && !this.tempEntryPointStation.HostName) || this.enableHostErrorEP || this.enableIPErrorEP) {
        this.disableOnSave = true;
      }
      else {
        this.disableOnSave = false;
      }
    }
    else if (!this.showPrinters) {
      if (this.onHide && !this.tempEntryPointStation.HostName) {
        this.disableOnSave = true;
      }
      else {
        this.disableOnSave = false;
      }
    }
  }

  // validation added for Host name in Printers and Entry points
  onDisableHostName(event) {
    this.disableOnHostName = false;
    this.tempEntryPointStations.forEach(element => {
      if (element.HostName === "" && element.Active) {
        this.disableOnHostName = true;
      }
    });
    this.addedPrinters.forEach(elements => {
      if (elements.HostName === "" && elements.Active) {
        this.disableOnHostName = true;
      }
    });
  }

  onHostNameChange(event) {
    if (this.tempEntryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP) {
      this.disableOnAdd = false;
    } else {
      this.disableOnAdd = true;
    }
    this.hostNameValidation();
    if (this.tempEntryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP) {
      this.disableOnAdd = false;
    } else {
      this.disableOnAdd = true;
    }
    this.onDisableSave();
  }

  onIPAddressChange(event) {
    if (this.tempEntryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP) {
      this.disableOnAdd = false;
    } else {
      this.disableOnAdd = true;
    }
    this.ipAddressValidation();
    if (this.tempEntryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP) {
      this.disableOnAdd = false;
    } else {
      this.disableOnAdd = true;
    }
    this.onDisableSave();
  }

  hostNameValidation() {
    this.enableHostErrorEP = false;
    //duplicate validation for hostname  
    this.tempEntryPointStations.forEach(element => {
      if (this.tempEntryPointStation.HostName.toLowerCase() === element.HostName.toLowerCase()) {
        this.enableHostErrorEP = true;
        return this.enableHostErrorEP;
      }
    })
    return this.enableHostErrorEP;
  }

  ipAddressValidation() {
    this.enableIPErrorEP = false;
    //Duplicate validation for ipaddress 
    this.iPAdressArray.forEach(element => {
      if ((this.tempEntryPointStation.IPAddress == element) && element != null) {
        this.enableIPErrorEP = true;
        return this.enableIPErrorEP;
      }
      else if ((this.printer.IPAddress == this.tempEntryPointStation.IPAddress) && this.printer.IPAddress != null && this.tempEntryPointStation.IPAddress != null) {
        this.enableIPErrorEP = true;
        return this.enableIPErrorEP;
      }

    })
    return this.enableIPErrorEP;
  }

  onValidatingAddButton() {
    //for enabling add-if we have 1row
    if (this.onHide && this.tempEntryPointStations.length >= 1) {
      this.disableOnAdd = true;
    } else if (!this.onHide && this.tempEntryPointStations.length >= 1) {
      this.disableOnAdd = false;
    } else if (!this.onHide && this.tempEntryPointStations.length == 0) {
      this.disableOnAdd = false;
    } else if (this.onHide && this.tempEntryPointStations.length == 0) {
      this.disableOnAdd = true;
    }
  }

  // Add Entry Point Station to UI
  addFieldValue() {
    if (!this.onHide) {
      this.onHide = true;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    // if (this.entrydataForm.valid) {
    if (this.tempEntryPointStation.HostName) {
      this.tempEntryPointStation.EntryPointID = this.data.EntryPointID;
      this.tempEntryPointStations.push(this.tempEntryPointStation);

      this.tempEntryPointStation = new EntryPointStation(
        0,
        this.data.EntryPointID,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      this.ipaddrsFormControl.reset();
      this.hostnameFormControl.reset();
      this.tempEntryPointStations.forEach((ele) => {
        if (ele.IPAddress != "") {
          this.iPAdressArray.push(ele.IPAddress);
        }
      });
    }
    this.disableOnAdd = true;
    this.onDisableSave();
  }

  // Remove EntryPointStation  from UI
  deleteFieldValue(index: number) {
    this.tempEntryPointStations[index].Active = false;
    this.iPAdressArray = [];
    this.tempEntryPointStations.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    this.addedPrinters.forEach((ele) => {
      if (ele.IPAddress != "") {
        this.iPAdressArray.push(ele.IPAddress);
      }
    });
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.onDisableSave();
    this.onDisableHostName(this.data);
  }

  deleteEmptyFieldValue() {
    this.onHide = false;
    this.ipaddrsFormControl.reset();
    this.hostnameFormControl.reset();
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.onDisableSave();
    this.getHostRequiredErrorMessage();
  }

  // Save the Changes Made
  onSaveChanges() {
    if (this.tempEntryPointStation.HostName && !this.enableHostErrorEP && !this.enableIPErrorEP) {
      this.addFieldValue();
    }
    if (this.printer.HostName && !this.enableHostError && !this.enableIPError) {
      this.addPrinterFieldValue();
    }

    // this.data.Description = this.entryPointDescription;
    // this.data.Phone = this.entryPointPhone;
    // this.data.EmailDirections = this.entryPointDirection;
    // this.data.EntryPointZoneID = this.entryPointZoneID;

    this.dialogService
      .openConfirmDialog(
        this.screenLabels["EditEntryPointConfirmLabel"] ? this.screenLabels["EditEntryPointConfirmLabel"] : "Confirm Entry Point Update",
        this.screenLabels["EditEntryPointConfirmMsg"] ? this.screenLabels["EditEntryPointConfirmMsg"] : "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        //update EntryPoint Details
        if (confirm) {
          this.entryPointService
            .updateEntryPointAPI(
              this.data.EntryPointID,
              this.entryPointDescription,
              this.entryPointPhone,
              this.entryPointDirection,
              this.entryPointZoneID,
              true,
              this.addedPrinters,
              this.tempEntryPointStations,
              this.cloudPrintEnableEP
            )
            .then(() => {
              this.notificationService.success(this.entryPointService.message);
            });
        }
      });

    this.onNoClick();
  }


  noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  getDescRequiredErrorMessage() {
    if (this.descriptionFormControl.hasError('whitespace')) {
      if (this.descriptionFormControl.value !== "") return " ";
      else return this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value";
    }
  }
  getPrinterHostReqErrorMessage() {
    if (this.onPrinterHide) {
      if (this.printerhostnameFormControl.hasError('whitespace')) {
        console.log("if pr host");
        this.formControlValidator = true;
        return this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value";
      }
      else this.formControlValidator = false;
    }
    else this.formControlValidator = false;
  }
  getHostRequiredErrorMessage() {
    if (this.onHide) {
      if (this.hostnameFormControl.hasError('whitespace')) {
        this.EPFormControlValidator = true;
        return this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value";
      }
      else this.EPFormControlValidator = false;
    }
    else this.EPFormControlValidator = false;
  }
  getDirectionRequiredErrorMessage() {
    return this.directionFormControl.hasError("required")
      ? this.screenLabels["EnterAValueMsg"] ? this.screenLabels["EnterAValueMsg"] : "You must enter a value"
      : " ";
  }
}
