import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatExpansionPanel } from '@angular/material';
import { Domain } from 'domain';
import { DomainModel } from 'src/app/models/domain-type.model';
import { DomainService } from 'src/app/services/domain.service';
import { LabelService } from 'src/app/services/label.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { SiteService } from 'src/app/services/site.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-create-domain',
  templateUrl: './create-domain.component.html',
  styleUrls: ['./create-domain.component.css']
})
export class CreateDomainComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("domainForm") domainForm: NgForm;
  domainFormControl = new FormControl("", [Validators.required]);
  domainPattern = '^[^-].*[A-Za-z0-9-]+\\.[a-z]{2,4}$';
  domainPatternFormControl = new FormControl("", [
    Validators.pattern(this.domainPattern),
  ]);
  protected step = 0;
  protected domain: DomainModel;
  protected domainTypes: any;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DomainModel,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateDomainComponent>,
    protected domainService: DomainService,
    private userService: UserService,
    private siteService: SiteService,
    protected labelService: LabelService,
    protected requiredFieldsService : RequiredFieldsService,
    protected spinnerService: SpinnerService,
    protected messageService: MessageService
  ) { }

  ngOnInit(): void {

//labels by message service
if (localStorage.getItem('CreateDomainLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateDomainLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Domain Modal",
    "Domain"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateDomainLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0); 
    // initialize labels
    this.labelService.initScreenLabels(
      this.siteService.selectedSiteID,
      "Create Domain Modal",
      "Domain"
    );

    this.domain = new DomainModel(
      0,
      null,
      null,
      0,
      0,
      null,
      null,
      this.userService.userID,
      null
    );

    this.domainService.getDomainPickListAPI().then(() => {
      this.domainTypes = this.domainService.domainTypeTables;
      let defaultValue = this.domainTypes.filter((c) => c.DisplayValue === "Internal").map(ele => ele.PickListID);
      this.domain.DomainCategory = defaultValue[0];
      setTimeout(() => {
        this.spinnerService.setIsLoading(false);
      }, 0); 
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
 
  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Add Domain',
      this.domainForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    const domainId = this.domainService.createDomainAPI(
      this.domain
    );
    this.onNoClick();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }


}
