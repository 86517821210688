import { Injectable, Output, EventEmitter } from "@angular/core";
import { MatDialogConfig } from "@angular/material";
import { NewpassModalComponentMobile } from "../newpass-modal/mobile/newpass-modal.component.mobile";
import { NewpassModalComponentDesktop } from "../newpass-modal/desktop/newpass-modal.component.desktop";
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from "@angular/common/http";
import { DialogService } from "./dialog.service";
import { NotificationService } from "./notification.service";
import { UserService } from "./user.service";
import { BehaviorSubject, Observable, Subject, interval } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SearchService } from "./search.service";
import { UtilityService } from "./utility.service";
import { SiteService } from "./site.service";
import { DatePipe } from "@angular/common";
import { __core_private_testing_placeholder__ } from "@angular/core/testing";
import * as _ from 'lodash';
import { I } from "@angular/cdk/keycodes";
@Injectable({
  providedIn: "root",
})
export class PassService {
  @Output() dataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelInitiated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openViewPass: EventEmitter<boolean> = new EventEmitter<boolean>();

  public newPassOpen = false;
  public PassOpen = false;
  public viewPassOpen = false;
  public duplicatePassOpen = false;
  public editPassOpen = false;
  public ignoreLocks: number;
  public partyID: number;
  public passID: number;
  public message: string;
  public photoURL: string;
  public image;
  public existingImage: boolean = false;
  public existingPhotoUrl: string;
  public passCheckedIn: boolean;
  public validationResponse: Array<any>;
  public ValidationStatus: string;
  public reasonOfVisit: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  public siteTypes: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  public tempData;
  public passes: Array<{
    PassID: number;
    VisitorFirstName: string;
    VisitorMiddleName: string;
    VisitorLastName: string;
    VisitorNameSuffix: string;
    VisitorCompany: string;
    VisitorEmail: string;
    VisitorPhone: string;
    VisitorCountry: number;
    StartTime: string;
    EndTime: string;
    EstArrivalTime: string;
    EstDepartureTime: string;
    VisitReason: number;
    IncludeNonWorkingdays: boolean;
    ExpectedEntryPoint: number;
    EntryPointGroupID: number;
    EntryPointGroupName: string;
    EntryType: number;
    NotesInternal: string;
    NotesPass: string;
    ParkingLotID: number;
    ParkingSpot: string;
    DestinationBldg: number;
    DestinationBlgFlr: number;
    DestinationRoom: string;
    HostFirstName: string;
    HostMiddleName: string;
    HostLastName: string;
    HostNameSuffix: string;
    HostCompany: string;
    HostPhone: string;
    HostEmail: string;
    HostTWID: string;
    OrganizationID: number;
    Authorizer: number;
    Operator: number;
    PhotoPass: number;
    Active: boolean;
    CopyFromPassID: string;
    PartyID: number;
    EmployeeRef: number;
    UserID: number;
    IsApprovalPending: string;
    IsPOI: number;
  }> = [];
  public sortedTemplates: any;
  public userTemplateCategoryAPiData: any;
  private validEntryPointGroupForCheckIns: { EntryPointGroupID: number; SiteID: number; EntryPointGrpName: string }[];
  public tempId = [];
  public apiBody;
  public passTemplateEndDate: any;
  public UserTaskID: number;
  public passPrintTemplate: any;
  public cloudPrintStatusCode: any;
  public cloudPrintPdfStatusCode: any;
  public subject = new BehaviorSubject<Array<any>>([]);
  public visitorCountryList: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  public passMessage: any;
  public passData: boolean = false;
  public passMsg: boolean = false;
  public cloudPrinted;
  passIDSubject = new Subject();

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private userService: UserService,
    private searchService: SearchService,
    private utilService: UtilityService,
    private siteService: SiteService,
    private datePipe: DatePipe,
  ) {
    this.validEntryPointGroupForCheckIns = [];

  }

  //for getting the userid
  setPassIDFromAPI(passID: number) {
    this.passIDSubject.next({ passID: passID })
  }
  getPassIDFromAPI(): Observable<any> {
    return this.passIDSubject.asObservable();
  }
  // get reason for visit from pick list table
  public getVisitReasonsAPI() {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
      }[]
    >((resolve, reject) => {
      this.http
        .post(
          apiURL,
          {
            TableName: "ReasonForVisit",
            SiteID: this.siteService.selectedSiteID,
          },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.reasonOfVisit = success.body.map((e) => ({
              PickListID: e.PickListID,
              TableName: e.TableName,
              FieldName: e.FieldName,
              DisplayValue: e.DisplayValue,
              Active: e.Active,
            }));
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getSiteTypeAPI() {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
      }[]
    >((resolve, reject) => {
      this.http
        .post(
          apiURL,
          {
            TableName: "type",
            SiteID: 0,
          },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.siteTypes = success.body.map((e) => ({
              PickListID: e.PickListID,
              TableName: e.TableName,
              FieldName: e.FieldName,
              DisplayValue: e.DisplayValue,
              Active: e.Active,
            }));
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // create pass
  public createPassAPI(body) {
    body.forEach((element) => {
      element.SiteID = this.siteService.selectedSiteID;
    });
    const apiURL = environment.getAPI("passCreate");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const isBulkCreate = body.length > 1;

    body.forEach((item) => {
      item.Status = "Valid";
      item.OrganizationID = this.userService.organizationID;
    });

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;

            if (success.body.Message === undefined && !isBulkCreate) {
              // duplicate found on a single pass, show option to allow duplicate
              this.dialogService
                .openConfirmDialog(
                  "Duplicate Pass Detected",
                  "It looks like we have a Duplicate Pass in the System! Do you still want to create the Pass as a Duplicate ?"
                )
                .afterClosed()
                .subscribe((confirmation: boolean) => {
                  if (confirmation) {
                    body[0].AllowDup = true;

                    // retry creating the pass
                    this.createPassAPI(body).then(() => {
                      this.notificationService.success(this.message);
                    });
                  } else {
                    this.notificationService.success("The Pass was Rejected.");
                  }
                });
            } else if (success.body.Message === undefined && isBulkCreate) {
              // duplicate(s) found on a bulk creation, show pass creation failed alert
              this.dialogService.openAlertDialogDialog(
                "Passes Rejected",
                "The Passes could not created please try again."
              );
            }
            resolve(success);
            this.dataChanged.emit(true);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public createPassAPINew(data) {
    const apiURL = environment.getAPI("passCreateNew");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };
    const body: {
      UserID: number;
      PassLength: number;
      TempPassStartID: number;
    } = {
      UserID: this.userService.userID,
      PassLength: data.passLength,
      TempPassStartID: data.tmpPassId,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    // this.dataChanged.emit(true);
    return res;
  }

  public createTempPassCheck(body, singlePass?) {

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };
    if (singlePass) {
      this.apiBody = []
      this.apiBody.push(body)
    } else {
      this.apiBody = body;
    }
    this.apiBody.forEach((item) => {
      item.SiteID = this.siteService.selectedSiteID
      item.Status = "Valid";
      item.OrganizationID = this.userService.organizationID;
    });
    const isBulkCreate = body.length > 1;

    const apiURL = environment.getAPI("tempPassCreate");

    const res = this.http.post<any>(apiURL, this.apiBody, httpOptions);

    return res;
  }

  public updateTempPassCheck(data, del?) {

    const body = {
      AllowDup: data.AllowDup,
      EntryType: data.EntryType,
      TmpPassID: data.TmpPassID,
      VisitorCompany: data.VisitorCompany,
      VisitorEmail: data.VisitorEmail,
      VisitorFirstName: data.VisitorFirstName,
      VisitorLastName: data.VisitorLastName,
      VisitorMiddleName: data.VisitorMiddleName,
      VisitorNameSuffix: data.VisitorNameSuffix,
      VisitorPhone: data.VisitorPhone,
      VisitorCountry: data.VisitorCountry,
      Action: (del) ? 'DELETE' : 'UPDATE'
    }
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };

    const isBulkCreate = data.length > 1;
    const apiURL = environment.getAPI("tempPassUpdate");

    const res = this.http.post<any>(apiURL, body, httpOptions);

    return res;
  }

  public passValidateGenUserTaskID(data) {
    data.forEach(ele => {
      if (ele.IsADuplicate === true && ele.IgnoreDuplicate === true) {
        this.tempId.push(ele.TmpPassID)
      }
    });
    if (this.tempId.length > 0) {
      const body: {
        OrganizationID: number;
        UserID: number;
        SiteID: number;
        PassLength: number;
        TempPassDuplicateIDs: Array<any>
      } = {
        OrganizationID: this.userService.organizationID,
        UserID: this.userService.userID,
        SiteID: this.siteService.selectedSiteID,
        PassLength: data.length,
        TempPassDuplicateIDs: this.tempId
      };
      this.apiBody = body;
    } else {
      const body: {
        OrganizationID: number;
        UserID: number;
        SiteID: number;
        PassLength: number;
      } = {
        OrganizationID: this.userService.organizationID,
        UserID: this.userService.userID,
        SiteID: this.siteService.selectedSiteID,
        PassLength: data.length
      };
      this.apiBody = body;
    }
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };

    // const isBulkCreate = body.length > 1;
    const apiURL = environment.getAPI("submitDuplicateCheck");

    const res = this.http.post<any>(apiURL, this.apiBody, httpOptions);
    return res;
  }

  public getUserTaskStatus(data) {
    this.UserTaskID = data
    const body: {
      UserTaskID: number;
    } = {
      UserTaskID: data
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      observe: "response",
    };
    const apiURL = environment.getAPI("getUserTaskStatus");
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  //Get Duplicate passes if any 

  public getDuplicatePasses() {

    const body: { UserID: number } = { UserID: this.userService.userID };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };
    const apiURL = environment.getAPI("getDuplicatePasses");
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  //generate party pass

  public generatePartyPass() {
    const body: { UserID: number } = { UserID: this.userService.userID };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };
    const apiURL = environment.getAPI("partyCreateNew");
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public cancelUser(data) {
    const body: { UserTaskID: number } = { UserTaskID: data };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": this._getOktaToken()
      }),
      observe: "response",
    };
    const apiURL = environment.getAPI("cancelUserTask");
    const res = this.http.post<any>(apiURL, body, httpOptions);
    // this.cancelInitiated.emit(true);
    return res;
  }

  // get passes
  public getAllPasses() {
    const apiURL = environment.getAPI("getPass");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(
          apiURL,
          {
            PassID: "",
            UserID: this.userService.userID,
            SiteID: this.siteService.selectedSiteID,
          },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passes = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // update pass API call
  public updatePassAPI(body: any) {
    const apiURL = environment.getAPI("passUpdate");
    body[0].SiteID = this.siteService.selectedSiteID;
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            if (success.status == 202) {
              this.dialogService
                .openConfirmDialog(
                  "Duplicate Pass Detected",
                  "It looks like we have a Duplicate Pass in the System! Do you still want to update the Pass as a Duplicate ?"
                )
                .afterClosed()
                .subscribe((confirmation: boolean) => {
                  if (confirmation) {
                    body[0].AllowDup = true;
                    // retry creating the pass
                    this.updatePassAPI(body).then(() => {
                      this.notificationService.success(this.message);
                    });
                  }
                });
            } else {
              this.notificationService.success(this.message);
            }
            resolve(success);
            this.dataChanged.emit(true);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get Party ID API call
  public getPartyIDAPI() {
    const apiURL = environment.getAPI("partyCreate");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, { "UserID": this.userService.userID }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.partyID = +success.body.PartyID;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get passes for same party
  public getPassesfromSameParty(partyID: number, passID: number) {
    if (this.passes.length) {
      if (partyID != null) {
        return this.passes.filter((p) => p.PartyID === partyID);
      } else {
        return this.passes.filter(
          (p) => p.PartyID == null && p.PassID === passID
        );
      }
    }
  }

  // create a record for pass transaction create
  public passTransactionCreateAPI(
    passID: number,
    action: string,
    status?: string  // ParkingPrint, CheckIn, PassPrint, CheckOut, CheckInApprovalRequest
  ): Promise<any> {
    const apiURL = environment.getAPI("passTransactionCreate");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body: {
      PassID: number;
      EntryPointID: number;
      Operator: number;
      TransactionTime: Date;
      ActionType: string;
      UserID: number;
      SiteID: number;
      Status: string;
    } = {
      PassID: passID,
      EntryPointID: this.searchService.getEntryPointID(),
      Operator: this.userService.userID,
      TransactionTime: this.utilService.getCurrentSiteDT(),
      ActionType: action,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
      Status: status ? status : null
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.photoURL = success.body.PhotoURL;
            if (this.image) {
              this.uploadFiles(this.photoURL, this.image);
              this.photoURL = ""
              this.existingImage = false;
            }

            resolve(success);
            //this.dataChanged.emit(true);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
  //upload photo to S3
  public uploadFiles(presigned, file: File) {
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "image/jpeg",
        ContentEncoding: 'base64',
        Accept: "*/*",
        Referer: "",
      }),
      observe: "response",
    };
    const req = new HttpRequest("PUT", presigned, file, httpOptions);

    const promise = new Promise((resolve, reject) => {
      this.http
        .request(req)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
    });
    return promise;
  }

  //gets guest photo if checked in before
  public getPrimaryPhotoFiles(passId) {
    const apiURL = environment.getAPI("getPassCheckinPhoto");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const apiBody = { PassID: passId };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, apiBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            if (success.body.PhotoURL == "") {
              this.existingImage = false;
              this.existingPhotoUrl = '';
            }
            else {
              this.existingPhotoUrl = success.body.PhotoURL;
              this.existingImage = true;
            }
            resolve(success);
          },
          (err) => {
            // error
            console.log(err);
            reject(err);
          }
        );
    });
    return promise;
  }

  public getPassTransactionAPI(passID: number): Promise<any> {
    const apiURL = environment.getAPI("getPassTransactionStructuredDetails");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body: {
      PassID: number;
      SiteID: number;
    } = {
      PassID: passID,
      SiteID: this.siteService.selectedSiteID,
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // is pass already checked in
  public isPassCheckedInAPI(passID: number): Promise<any> {
    const apiURL = environment.getAPI("getPassTransactionDetails");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body: {
      PassID: number;
      ActionType: string;
    } = {
      PassID: passID,
      ActionType: "CheckIn", // checking = 8; parking = 9;
    };

    const promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passCheckedIn = success.body.length > 0 ? true : false;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public validatePassAPI(body: Array<any>) {
    const apiURL = environment.getAPI("submitDuplicateCheck");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    this.ValidationStatus = "";
    const promise = new Promise((resolve, reject) => {
      body.forEach((element) => {
        element.SiteID = this.siteService.selectedSiteID;
      });
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success

            this.validationResponse = [];
            this.validationResponse = success.body;
            if (success.status == 202) {
              this.ValidationStatus = "Duplicates Found in Existing Passes";
            } else if (success.status == 203) {
              this.ValidationStatus = "Duplicates Found within the Grid";
            } else if (success.status == 201) {
              this.ValidationStatus =
                "Duplicates Found within the Grid and Existing Passes";
            }
            if (this.ValidationStatus != "")
              this.notificationService.success(this.ValidationStatus);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public triggerPOIApproval(passID: number, status: string): Promise<any> {
    const apiURL = environment.getAPI("TriggerPOICheckinApproval");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body: {
      PassID: number;
      UserID: number;
      SiteID: number;
      Status: string
    } = {
      PassID: passID,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
      Status: status
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;

            resolve(success);
            //this.dataChanged.emit(true);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // invoke the API call to retrieve the Party Passes
  public getPartyPassesAPI(passID: number, partyID?: number) {
    const apiURL = environment.getAPI("getPartyPassFromPass");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      UserID: this.userService.userID,
      PassID: passID,
      PartyID: partyID,
      SiteID: this.siteService.selectedSiteID,
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passes = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // invoke the API call to retrieve endDate
  public getEndDateForTemplate(
    StartDate: Date,
    NumberOfDays: number,
    ExcludeHolidayFlag: number,
    orgEndDate: Date,
    SiteID: number
  ) {
    const apiURL = environment.getAPI("getEndDateFromTemplate");


    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      StartDate: this.datePipe.transform(StartDate, "yyyy-MM-dd"),
      NumberOfDays: NumberOfDays,
      ExcludeHolidayFlag: ExcludeHolidayFlag,
      OrganizationEndDate: this.datePipe.transform(orgEndDate, "yyyy-MM-dd"),
      SiteID: SiteID
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passTemplateEndDate = success.body.EndDate;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public async getPassPrintTemplateAPI(
    passID: number,
    templateID: number
  ): Promise<string> {
    const apiURL = environment.getAPI("getPrintTemplate");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      SiteID: this.siteService.selectedSiteID,
      TemplateID: templateID,
      RecordID: passID,
    };

    const promise = new Promise<string>((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passPrintTemplate = success.status;
            resolve(success.body);
          },
          (err) => {
            // error
            this.passPrintTemplate = err.status;
            reject(err);
          }
        );
    });
    return promise;
  }

  /**
  *  gets list of all the valid entry point group that permits check in
  * @param entryPointID 
  */
  public getValidEntryPointGroupsAPI(entryPointID: number) {
    const apiURL = environment.getAPI("getActiveEntryPointGroup");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      EntryPointID: entryPointID,
    };

    const promise = new Promise<{
      EntryPointGroupID: number;
      SiteID: number;
      EntryPointGrpName: string;
      Active: number;

    }[]>((resolve, reject) => {
      if (entryPointID) {
        this.http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              // success
              const resp: {
                EntryPointGroupID: number;
                SiteID: number;
                EntryPointGrpName: string;
                Active: number;

              }[] = success.body;

              this.validEntryPointGroupForCheckIns = resp.map(r => ({
                EntryPointGroupID: r.EntryPointGroupID,
                SiteID: r.SiteID,
                EntryPointGrpName: r.EntryPointGrpName
              }));
              resolve(resp);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
      else
        reject('The Entry Point ID is invalid');
    });
    return promise;
  }

  public isPassCheckInEntryPointGroupValid(passEntryPointGroupID: number): boolean {
    const validEpg = this.validEntryPointGroupForCheckIns
      .filter(
        epg => epg.EntryPointGroupID === passEntryPointGroupID &&
          epg.SiteID === this.siteService.selectedSiteID);

    return validEpg.length > 0;
  }

  public getUserTemplateCategoriesAPI(UserID: number, OrganizationID: number): Promise<any> {
    const apiURL = environment.getAPI("getPassTemplateCategories");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      OrganizationID: OrganizationID,
      UserID: UserID
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.userTemplateCategoryAPiData = success.body;
            console.log("API response", this.userTemplateCategoryAPiData);
            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }


  private _getOktaToken() {
    let oktaToken = "";

    try {

      if (localStorage.getItem("okta-token-storage") !== '{}') {
        const oktaTokenResponse: string = localStorage.getItem("okta-token-storage");
        const responseObj: Object = JSON.parse(oktaTokenResponse);
        const isEmptyResponse = _.isEmpty(responseObj);

        // @ts-ignore
        oktaToken = !isEmptyResponse ? (responseObj.idToken).idToken : '';
      }
    } catch (ex) {
      console.error(ex);
    }
    finally {
      return oktaToken;
    }

  }

  public checkCloudPrintEnabledAPI(siteID: number, entryPointID: number) {

    const apiURL = environment.getAPI("checkCloudPrintEnabled");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      SiteID: siteID,
      EntryPointID: entryPointID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    // this.dataChanged.emit(true);
    return res;
  }

  public cloudPrintProcessAPI(
    siteID: number,
    entryPointID: number,
    userID: number,
    recordID: number,
    templateID: number,
    passType: Array<String>,
    entryPointStationID?: number,
  ): Promise<any> {
    const apiURL = environment.getAPI("cloudPrintProcess");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      SiteID: siteID,
      EntryPointID: entryPointID,
      UserID: userID,
      RecordID: recordID,
      TemplateID: templateID,
      EntryPointStationID: entryPointStationID,
      PassType: passType
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.cloudPrintStatusCode = success.status;
            this.message = success.body.message;
            this.cloudPrinted = (success.body.cloudPrinted === 0) ? (success.body.cloudPrinted) : null;
            resolve(success);
          },
          (err) => {
            this.cloudPrintStatusCode = err.status;
            reject(err);
          }
        );
    });
    return promise;

  }

  public getCategoryTemplates(TemplateCategoryID: number): Promise<any> {
    const apiURL = environment.getAPI("getPassTemplateFromCategory");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      UserID: this.userService.userID,
      TemplateCategoryID: TemplateCategoryID
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.sortedTemplates = success.body;
            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public getPrintTemplateNameAPI(SiteID: number, UseType: string) {
    const apiURL = environment.getAPI("getPrintTemplateName");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      SiteID: SiteID,
      UseType: UseType
    }

    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;

  }

  public webServerHealthCheck(webServer) {
    const apiURL = environment.getAPI('checkWebServer');
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      observe: "response",
    };
    const body = {
      host: webServer
    };
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
  public getCountryListAPI() {
    const apiURL = environment.getAPI("listCountry");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      TableName: "Pass",
      SiteID: 0
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    let result = res.subscribe((ele: HttpResponse<any>) => {
      this.visitorCountryList = ele.body.map((e) => ({
        PickListID: e.PickListID,
        TableName: e.TableName,
        FieldName: e.FieldName,
        DisplayValue: e.DisplayValue,
        Active: e.Active,
      }));
    })
    return res;
  }

  public getPassDetailsAPI(passID: number) {

    const apiURL = environment.getAPI("getPassDetails");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public getPassDetailsByIDAPI(passID: number) {

    const apiURL = environment.getAPI("getPassDetailsByID");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
      SiteID: this.siteService.selectedSiteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public checkInApprovalAPI(passID: number, status: string) {

    const apiURL = environment.getAPI("checkInApproval");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
      Status: status,
      SiteID: this.siteService.selectedSiteID,
      UserID: this.userService.userID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public getPartyPassByPartyIDAPI(partyID: number, passID: number) {
    const apiURL = environment.getAPI("getPartyPass");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      PartyID: partyID,
      PassID: passID,
      SiteID: this.siteService.selectedSiteID
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            //reset the values
            this.passData = false;
            this.passMsg = false;
            this.passes = [];
            this.passMessage = null;
            // success
            if (success.body.passData) {
              this.passData = true;
              this.passes = success.body.passData;
            }
            if (success.body.Message) {
              this.passMsg = true;
              this.passMessage = success.body.Message;
            }
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get passes for same party
  public getPartyPassResponse() {
    if (this.passData) {
      return this.passes;
    }
    else if (this.passMsg) {
      return this.passMessage;
    }
  }

  public getPassCreateDetailsAPI(passID: number) {
    const apiURL = environment.getAPI("getPassCreateDetails");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public getPassCheckInDetailsAPI(passID: number) {
    const apiURL = environment.getAPI("getPassCheckInDetails");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public redeemPassAPI(passID: number) {
    const apiURL = environment.getAPI("redeemPass");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      PassID: passID
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public checkIsIgnoreLocksAPI(UserID: number, OrganizationID: number, subscribe: boolean) {
    const apiURL = environment.getAPI("checkIsIgnoreLocks");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      UserID: UserID,
      OrganizationID: OrganizationID,
      SiteID: this.siteService.selectedSiteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    if (subscribe) {
      res.subscribe((data: HttpResponse<any>) => {
        this.ignoreLocks = data.body.IsIgnoreLocks;
      })
    }
    return res;
  }

  public sendPassEmailToVisitorAPI(passID: number) {
    const apiURL = environment.getAPI("sendPassEmailToVisitor");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
      IsEmailSendToHost: 0,
      UserID: this.userService.userID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    res.subscribe((data: HttpResponse<any>) => {
      this.message = data.body.Message;
      this.notificationService.success(this.message);
    })
    return res;
  }

  //getPartyPassCreatedCount
  public getPartyPassCreatedCountAPI(partyID: number) {
    const apiURL = environment.getAPI("getPartyPassCreatedCount");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PartyID: partyID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  // pass/getPassFormsBySiteID
  public getPassFormsBySiteIDAPI() {
    const apiURL = environment.getAPI("getPassFormsBySiteID");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      SiteID: this.siteService.selectedSiteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  //tempPassFormCreate
  public tempPassFormCreateAPI(body) {
    const apiURL = environment.getAPI("tempPassFormCreate");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    // if (singlePass) {
    //   this.apiBody = []
    //   this.apiBody.push(body)
    // } else {
    //   this.apiBody = body;
    // }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

}