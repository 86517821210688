import { apiPaths } from "./environmentAPIPaths";

export const environment = {
  VERSION: require("package.json").version,
  environment: "dev",
  production: false,
  baseHref: "/dev",
  isMockAPIResponse: false,
  baseUrl: "https://vms-api-dev.warnerbros.com/",

  S3BucketName: "vms-dev.warnerbros.com",
  CognitoIdentity: "us-east-1:14d008fb-b40a-449b-9dbd-204f5e99f171",

  // oktaConfig: {
  //   clientId: "0oatp0zr7cs6uu4x30h7",
  //   issuer: "https://wmbiz.oktapreview.com",
  //   redirectUri: "https://pass-dev.wbd.com/implicit/callback",
  //   // postLogoutRedirectUri: "http://localhost:8080",
  //   scope: "openid profile email",
  //   // scopes: ["openid", "profile", "email"],
  //   // Override the default authorize and userinfo URLs
  //   authorizeUrl: "https://wmbiz.oktapreview.com/oauth2/v1/authorize",
  //   userinfoUrl: "https://wmbiz.oktapreview.com/oauth2/v1/userinfo",
  //   tokenUrl: "https://wmbiz.oktapreview.com/oauth2/v1/token",
  //   pkce: false,
  //   // tokenManager: {
  //   //   secure: true,
  //   //   storage: "cookie",
  //   // },
  //   logoutURL: "https://wmbiz.oktapreview.com/oauth2/v1/logout",
  //   // tslint:disable-next-line:object-literal-shorthand
  //   // onAuthRequired: onAuthRequired,
  //   // tslint:disable-next-line:object-literal-shorthand
  //   // onSessionTimeout: onSessionTimeout

  // },

  oktaConfig: {
    clientId: "0oatp0zr7cs6uu4x30h7",
    issuer: "https://ssobizqa.wbd.com",
    redirectUri: "https://pass-dev.wbd.com/implicit/callback",
    // postLogoutRedirectUri: "http://localhost:8080",
    scope: "openid profile email",
    // scopes: ["openid", "profile", "email"],
    // Override the default authorize and userinfo URLs
    authorizeUrl: "https://ssobizqa.wbd.com/oauth2/v1/authorize",
    userinfoUrl: "https://ssobizqa.wbd.com/oauth2/v1/userinfo",
    tokenUrl: "https://ssobizqa.wbd.com/oauth2/v1/token",
    pkce: false,
    // tokenManager: {
    //   secure: true,
    //   storage: "cookie",
    // },
    logoutURL: "https://ssobizqa.wbd.com/oauth2/v1/logout",
    // tslint:disable-next-line:object-literal-shorthand
    // onAuthRequired: onAuthRequired,
    // tslint:disable-next-line:object-literal-shorthand
    // onSessionTimeout: onSessionTimeout

  },

  getAPI: (
    apiName: string,
    prefixURL = "https://vms-api-dev.warnerbros.com/" // change the base api URL
  ): string => {
    return prefixURL + apiPaths[apiName];
  },
};
