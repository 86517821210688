import { ViewpassModalComponentDesktop } from "./../viewpass-modal/desktop/viewpass-modal.component.desktop";
import { ViewpassModalComponentMobile } from "./../viewpass-modal/mobile/viewpass-modal.component.mobile";
import { EditPassModalComponentDesktop } from "./../edit-pass-modal/desktop/edit-pass-modal.component.desktop";
import { EditPassModalComponentMobile } from "./../edit-pass-modal/mobile/edit-pass-modal.component.mobile";
import { DuplicatePassModalComponentDesktop } from "./../duplicate-pass-modal/desktop/duplicate-pass-modal.component.desktop";
import { DuplicatePassModalComponentMobile } from "./../duplicate-pass-modal/mobile/duplicate-pass-modal.component.mobile";
import { ViewSelectorService } from "./../services/view-selector.service";
import { Component, OnInit, Renderer2, ViewChild, ElementRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { SearchService } from "../services/search.service";
import moment from "moment";
import { HostListener } from "@angular/core";
import { default as _rollupMoment } from "moment";
import { PassService } from "../services/pass.service";
import { DatePipe } from "@angular/common";
import { BuildingService } from "../services/building.service";
import { EntryPointService } from "../services/entry-point.service";
import { NotificationService } from "../services/notification.service";
import { DialogService } from "../services/dialog.service";
import { RoleMapService } from "../services/role-map.service";
import { IpService } from "../services/ipService.service";
import { SpinnerService } from "../services/spinner.service";
import { AuthService } from "../services/auth.service";
import { WidgetService } from "../services/widget.service";
import { ViewPassTransactionModalComponent } from "../pass-transaction/modals/view-pass-transaction-modal/view-pass-transaction-modal.component";
import { TeamService } from "../services/team-service";
import { OrganizationService } from "../services/organization.service";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import { GridApi } from "ag-grid-community";
import { ISystemSettingsGetAPIResponse } from "../models/interfaces/system-settings-get-api.model";
import { UserService } from "../services/user.service";
import { MessageService } from "../services/message.service";
import { EntryPoint } from "../models/entry-point.model";
import { UserActionsService } from "../services/user-actions.service";
import { SearchFilterModalComponent } from "../nav-bar/modals/search-filter.modal.component";
import { ParkingLotService } from "../services/parking-lot.service";
import { ApprovalService } from "../services/approval.service";

// const moment = _rollupMoment || _moment;

@Component({
  selector: "app-dashboard",
  templateUrl: "./desktop/dashboard.component.desktop.html",
  styleUrls: ["./desktop/dashboard.component.desktop.css"],
})
export abstract class DashboardComponent implements OnInit {
  @ViewChild("hiddenPrintBtn") private hiddenPrintBtn: ElementRef;
  @ViewChild("printContainer") private printContainer: ElementRef;

  isVisitReasonLoading = true;
  isBuildingLoading = true;
  isEntryPointLoading = true;
  visitReasons: Array<any> = [];
  selectedRows;
  itemsProcessed = 0;
  selectedEntryPoint;
  rowData: any;
  params: [];
  private gridApi: GridApi;
  // data for selected row
  passId: any;
  passStatus: string;
  EmployeeRef: any;
  isMultiselect: boolean = false;
  activeOn: any;
  expiresOn: any;
  guestName: any;
  guestFirst: any;
  guestLast: any;
  guestCompany: any;
  guestPhone: any;
  guestEmail: any;
  hostName: any;
  hostFirst: any;
  hostLast: any;
  hostPhone: any;
  hostEmail: any;
  guestReason: any;
  arrivalTime: any;
  departureTime: any;
  guestDestination: any;
  entryType: any;
  entryPoint: any;
  assignedRoom: any;
  assignedParking: any;
  parkingSpot: string;
  passComment: any;
  internalComment: any;
  fullArrival: any;
  fullDeparture: any;
  entryPointGroupID: number;
  @ViewChild("searchField") searchField: ElementRef;
  protected entryPoints: EntryPoint[] = [];
  newNameEntrypoint: any;
  private isMobile = false;
  protected isRowSelected: boolean;
  protected isPassCheckedIn: boolean = false;
  private _settingsAPIResponse: ISystemSettingsGetAPIResponse;
  private createdDate: string;
  private modifiedDate: string;
  private createdBy: string;
  private modifiedBy: string;
  public htmlString = "";
  private today: Date = this.utilService.getCurrentSiteDT();
  private passExpired: boolean;
  protected userOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  protected userRoles: Array<{
    RoleID: number;
    Active: string;
    UserCheckin: boolean;
  }> = [];
  public searchBtnDisabled: boolean;
  public selectedOrg: number = 0;
  public userCheckIn: boolean = false;
  public ipAddress: string;
  public templateID: number;
  protected callInUser: boolean = false;
  AuthoriserOrganizations: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
  dateNow: Date = this.utilService.getCurrentSiteDT();
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive: boolean = false;
  columnDefs: object = [{}];
  mobileColumns: object = [{}];
  public defaultColDef: object;
  protected passIDFromAPI: number = 0;
  protected visitorEmail: string;
  protected passCreateApprovalStatus: string;
  protected approvalRequestExists: boolean = false;
  protected selectedSerchFilter: string = 'Wide';

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    service: ViewSelectorService,
    protected searchService: SearchService,
    protected passService: PassService,
    private datePipe: DatePipe,
    private buildingService: BuildingService,
    private entryPointService: EntryPointService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    public userService: UserService,
    private roleMapService: RoleMapService,
    private ipService: IpService,
    protected authService: AuthService,
    protected widgetService: WidgetService,
    protected teamService: TeamService,
    private organizationService: OrganizationService,
    protected siteService: SiteService,
    private utilService: UtilityService,
    private renderer: Renderer2,
    private userActionsService: UserActionsService,
    protected messageService: MessageService,
    protected parkingLotService: ParkingLotService,
    protected approvalService: ApprovalService
  ) {
    this.siteService.siteSettings;

    this.isRowSelected = false;
    this.callInUser = false;
    this.callInUser = this.authService.validCaller;
    if (service.getIsMobile()) {
      this.isMobile = true;
    }
    // load Grid based on UserRole
    this.roleMapService
      .getFunctions()
      .then(() => { })
      .catch((err) => {
        this.notificationService.failure(err.message);
      });

    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => {
        this.userService
          .getUserAssociationAPI(this.userService.userID, this.siteService.selectedSiteID)
          .then(() => {
           localStorage.setItem('searchType', this.userService.getUserDetails()[0].UserSearchType);
           //fetching searchtype from getuser
           this.selectedSerchFilter = localStorage.getItem('searchType');

            this.userOrganizations = [
              ...this.userService
                .getUserDetails()[0]
                .UserOrganization.map((o) => ({
                  OrganizationID: o.OrganizationID,
                  Name: o.Name,
                  StartDate: o.Startdate,
                  EndDate: o.EndDate,
                  IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
                  Active: o.UserOrganizationActive,
                }))
                .filter((f) => f.Active),
            ];
            this.userService.userOrganizations = this.userOrganizations;

            // setting the organization id for the first time
            if (
              this.userOrganizations &&
              this.userOrganizations.length > 0 &&
              this.userService.organizationID <= 0
            ) {
              this.userService.organizationID = this.userOrganizations[0].OrganizationID;
              this.searchService.setOrganizationID(
                this.userOrganizations[0].OrganizationID
              );
              this.userService.IsAdmin = this.userService.userOrganizations.find(
                (uo) => uo.OrganizationID === this.userService.organizationID
              ).IsAdmin;

              //load User team functions

              this.teamService
                .getUserTeamAPI(this.userService.userID)
                .then(() => {
                  // load the organization basket
                  var userTeams = this.teamService.userTeams;
                  this.userService.userTeams = userTeams.map((team) => ({
                    UserTeamID: team.UserTeamID,
                    TeamID: team.TeamID,
                    Active: team.Active,
                    Name: team.Name,
                    OrganizationID: team.OrganizationID,
                    TemplateCategoryTeamList: team.TemplateCategoryTeamList,
                    OrganizationFunctionTeamList:
                      team.OrganizationFunctionTeamList,
                    AutoAddAllCategories: team.AutoAddAllCategories,
                    AutoAddAllOrgUsers: team.AutoAddAllOrgUsers,
                    ModifiedBy: team.ModifiedBy,
                    ModifiedDate: team.ModifiedDate,
                    ModifiedByName: team.ModifiedByName,
                    CreatedBy: team.CreatedBy,
                    CreatedDate: team.CreatedDate,
                    CreatedByName: team.CreatedByName,
                  }));
                  this.userService.userTeams.forEach((element) => {
                    element.OrganizationFunctionTeamList.forEach(
                      (orgFunction) => {
                        if (
                          orgFunction.FunctionSelected == 1 &&
                          !this.userService.userOrgFunctions[orgFunction.Tag]
                        ) {
                          this.userService.userOrgFunctions[
                            orgFunction.Tag
                          ] = true;
                        }
                      }
                    );
                  });
                  this.passService.getUserTemplateCategoriesAPI(this.userService.userID, this.userService.organizationID);
                  this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, true);
                })
                .catch((err: HttpErrorResponse) => {
                  this.notificationService.failure(err.message);
                });

            }

            // to get Ip Adress
            this.userRoles = [
              ...this.userService
                .getUserDetails()[0]
                .UserRole.map((r) => ({
                  RoleID: r.RoleID,
                  Active: r.UserRoleActive,
                  UserCheckin: this.roleMapService
                    .getSelectedFunctions(r.RoleID)
                    .filter(
                      (f) =>
                        f.Active &&
                        f.FunctionID ==
                        this.roleMapService.getPassCheckinFunctionID()
                    ).length
                    ? true
                    : false,
                }))
                .filter((f) => f.Active),
            ];
            // Check if logged in userRole has checkin privileges
            this.userCheckIn = this.userRoles.filter((f) => f.UserCheckin)
              .length
              ? true
              : false;
            this.userService.userCheckIn = this.userCheckIn;
            this.userService.userDetailsLoaded.emit(true); // emitter to load nav-bar after dashboard is loaded

            if (this.userCheckIn) {
              //if user has checkin then set entrypointdrop and load Passes based on staion Ip
              if (
                this.userOrganizations.length > 0 &&
                this.userService.organizationID <= 0
              ) {
                this.selectedOrg = this.userOrganizations[0].OrganizationID;
                this.userService.organizationID = this.selectedOrg;
                this.userService.IsAdmin = this.userOrganizations[0].IsAdmin
                  ? this.userOrganizations[0].IsAdmin == 1
                    ? 1
                    : 0
                  : 0;
                this.userService.organizationChanged.emit(true);

                this.searchService.setOrganizationID(this.selectedOrg);
              }
            } else {
              //Api call to get entry points
              if (this.userOrganizations.length > 0) {
                this.selectedOrg = this.userOrganizations[0].OrganizationID;
                this.searchService.setOrganizationID(this.selectedOrg);
              }
            }
            this.searchService
              .smartSearchMultiSiteAPI(null, "Pass", "Pass")
              .then(() => {
                this.rowData = this.searchService.searchRecords;
                this.rowData.forEach((element) => {
                  element.StartTime = this.formatDate(element.StartTime);
                  element.EndTime = this.formatDate(element.EndTime);
                });
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.passService
      .getVisitReasonsAPI()
      .then(() => {
        this.isVisitReasonLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // this.buildingService
    //   .parseAPI()
    //   .then(() => {
    //     this.isBuildingLoading = false;
    //   })
    //   .catch((err: HttpErrorResponse) => {
    //     this.notificationService.failure(err.message);
    //   });

    // await for entry point API promise to be resolved
    if (!this.userService.userCheckedInEntryPoint) {
      this.entryPointService
        .parseGetEndpointAPI()
        .then(() => {
          this.isEntryPointLoading = false;
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
    this.userService.userDetailsLoaded.subscribe((dataLoaded: boolean) => {
      this.getDefaultEntryPoint();
    });
    this.defaultColDef = {
      resizable: true,
    }
  }

  // ag-grid
  // ngAfterViewChecked() {


  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows = $event.api.getSelectedRows();
    if (selectedRows.length == 1) {
      //show edit btn if approvalRequestExists=0
      if (this.passService.ignoreLocks) {
        this.approvalService.checkApprovalRequestExistsAPI(selectedRows[0].PassID).subscribe((data: HttpResponse<any>) => {
          if (data.body.ApprovalRequestExists === 1)
            this.approvalRequestExists = true;
          else
            this.approvalRequestExists = false;
        });
      }
      if (selectedRows[0].PassDayStatus == "Expired") {
        this.passExpired = true;
      } else {
        this.passExpired = false;
      }
      if (selectedRows[0].Active === 0 && selectedRows[0].PassDayStatus == "Deleted") {
        this.isActive = false;
      } else {
        this.isActive = true;
      }

      this.visitorEmail = selectedRows[0].VisitorEmail;
      this.passCreateApprovalStatus = selectedRows[0].status;
      this.isMultiselect = false;
      this.isRowSelected = true;
      this.isPassCheckedIn = selectedRows[0].CheckedIn > 0 ? true : false;
      this.passId = $event.api.getSelectedRows()[0].PassID;
      this.EmployeeRef = selectedRows[0].EmployeeRef;
      this.activeOn = new Date(selectedRows[0].StartTime);
      this.expiresOn = new Date(selectedRows[0].EndTime);
      this.guestName = selectedRows[0].guestName;
      this.guestFirst = selectedRows[0].guestFirst;
      this.guestLast = selectedRows[0].guestLast;
      this.guestCompany = selectedRows[0].guestCompany;
      this.guestPhone = selectedRows[0].guestPhone;
      this.guestEmail = selectedRows[0].guestEmail;
      this.hostName = selectedRows[0].hostName;
      this.hostFirst = selectedRows[0].hostFirst;
      this.hostLast = selectedRows[0].hostLast;
      this.hostEmail = selectedRows[0].hostEmail;
      this.hostPhone = selectedRows[0].hostPhone;
      this.guestReason = selectedRows[0].reason;
      this.arrivalTime = moment(selectedRows[0].EstArrivalTime).format(
        "HH:mm"
      );
      this.departureTime = moment(selectedRows[0].EstDepartureTime).format(
        "HH:mm"
      );
      this.fullArrival = new Date(selectedRows[0].fullArr);
      this.fullDeparture = new Date(selectedRows[0].fullDeparture);
      this.guestDestination = selectedRows[0].destination;
      this.entryType = selectedRows[0].entryType;
      this.entryPoint = selectedRows[0].entryPoint;
      this.assignedRoom = selectedRows[0].room;
      this.assignedParking = selectedRows[0].assignedParking;
      this.passComment = selectedRows[0].passComment;
      this.internalComment = selectedRows[0].internalComment;
    }
    if (selectedRows.length > 1) {
      this.isMultiselect = true;
      this.passExpired = false;
      selectedRows.forEach((element) => {
        if (element.PassDayStatus == "Expired") {
          this.passExpired = true;
        }
      });

    } else if (selectedRows.length == 0) {
      this.isMultiselect = false;
      this.isRowSelected = false;
      this.isPassCheckedIn = false;
      this.passExpired = false;
    }
  }

  openPass(event: any) {
    //forcily calling viewpass after creation of pass
    if (event === true) {
      this.callGetPassDetailsAPI(this.passIDFromAPI);
    }
    //opens view pass modal on grid row click, passes row data
    else if (event.type === "rowClicked") {
      this.callGetPassDetailsAPI(event.data.PassID);
    }
  }

  callGetPassDetailsAPI(id) {
    let data;
    this.passService.getPassDetailsByIDAPI(id).subscribe((res: any) => {
      data = res.body;
      if (data.Active > 0) {
        if (
          data.EmployeeRef != null ||
          data.EmployeeRef != undefined
        )
          this.guestName = (
            data.VisitorNameSuffix +
            " " +
            data.VisitorFirstName +
            // " " +
            // data.VisitorMiddleName +
            " " +
            data.VisitorLastName
          )
            .replace("null", "")
            .replace(null, "");

        this.hostName = (
          data.HostFirstName +
          // " " +
          // data.HostMiddleName +
          " " +
          data.HostLastName
        )
          .replace("null", "")
          .replace(null, "");
        this.passId = data.PassID;
        this.hostEmail = data.HostEmail;
        this.hostPhone = data.HostPhone;
        this.activeOn = this.datePipe.transform(
          data.StartTime,
          "shortDate"
        );
        this.arrivalTime = moment(data.EstArrivalTime).format("HH:mm");
        this.departureTime = moment(data.EstDepartureTime).format("HH:mm");
        // if (!this.isVisitReasonLoading) {
        //   this.guestReason = this.passService.reasonOfVisit
        //     .filter((e) => e.PickListID === data.VisitReason)
        //     .map((e) => e.DisplayValue);
        // }
        this.guestReason = data.DisplayValue;

        // if (!this.isBuildingLoading) {
        //   this.guestDestination = this.buildingService
        //     .getBuildings()
        //     .filter((b) => b.BuildingID === data.DestinationBldg)
        //     .map((e) => e.BuildingName);
        //   // data.DestinationBldg;
        // }

        // this.guestReason = data.VisitReason;
        this.entryPoint = data.ExpectedEntryPoint;
        if (data.EntryType === 1) {
          this.entryType = "Drive-on";
        }

        if (data.EntryType === 2) {
          this.entryType = "Walk-on";
        }

        if (!this.isEntryPointLoading) {
          this.entryPoint = this.entryPointService
            .getEntryPoints()
            .filter((ep) => ep.EntryPointID === data.ExpectedEntryPoint)
            .map((ep) => ep.Description);
        }

        this.expiresOn = this.datePipe.transform(data.EndTime, "shortDate");
        this.passComment = data.NotesPass;

        // take the User ID and convert them to string
        this.createdBy = data.CreatedBy ? data.CreatedBy : "";
        this.modifiedBy = data.ModifiedBy ? data.ModifiedBy : "";

        this.createdDate = this.datePipe.transform(
          new Date(data.CreatedDate),
          "short"
        );
        this.modifiedDate = this.datePipe.transform(
          new Date(data.ModifiedDate),
          "short"
        );

        if (this.isMobile) {
          if (this.passService.viewPassOpen) return;
          this.dialog.open(ViewpassModalComponentMobile, {
            disableClose: true,
            data: [{
              guestName: this.guestName,
              hostName: this.hostName,
              guestId: this.passId,
              guestDate: this.activeOn,
              guestArrival: this.arrivalTime,
              guestDeparture: this.departureTime,
              guestDestination: data.Destination,
              EstArrivalTime: data.EstArrivalTime,
              guestReason: this.guestReason,
              entryPoint: this.entryPoint,
              entryType: this.entryType,
              expirationDate: this.expiresOn,
              passComment: this.passComment,
              createdDate: this.createdDate,
              modifiedDate: this.modifiedDate,
              createdBy: this.createdBy,
              modifiedBy: this.modifiedBy,
              partyID: data.PartyID,
              EntryPointGroupName: data.EntryPointGroupName,
              internalComment: data.NotesInternal,
              isNonWorkingDayIncluded:
                data.IncludeNonWorkingdays === 1 ? "Yes" : "No",
              isPhotoPassActive: data.PhotoPass === 1 ? true : false,
              parkingLotID: data.ParkingLotID,
              createdByName: data.CreatedByName,
              modifiedByName: data.ModifiedByName,
              passStatus: data.PassDayStatus,
              organizationName: data.OrganizationName,
              parkingSpot: data.ParkingSpot,
              isPassCheckedIn: data.CheckedIn !== 0,
              entryPointGroupID: data.EntryPointGroupID,
              VisitorFirstName: data.VisitorFirstName,
              // VisitorMiddleName: data.VisitorMiddleName,
              VisitorMiddleName: "",
              VisitorLastName: data.VisitorLastName,
              PassID: data.PassID,
              VisitorCompany: data.VisitorCompany,
              PassForms: data.PassForms,
              ApprovalCheckInPreCheck: data.ApprovalCheckInPreCheck,
              ApprovalPassCreateStatus: data.ApprovalPassCreateStatus,
              PhotoPass: data.PhotoPass
            }],
            backdropClass: "backdropBackground",
          })
          this.passService.viewPassOpen = true;
        } else {
          if (this.passService.viewPassOpen) return;
          this.dialog.open(ViewpassModalComponentDesktop, {

            disableClose: true,
            data: [{
              guestName: this.guestName,
              hostName: this.hostName,
              hostEmail: this.hostEmail,
              hostPhone: this.hostPhone,
              guestId: this.passId,
              guestDate: this.activeOn,
              guestArrival: this.arrivalTime,
              guestDeparture: this.departureTime,
              guestDestination: data.Destination,
              EstArrivalTime: data.EstArrivalTime,
              guestReason: this.guestReason,
              entryPoint: this.entryPoint,
              entryType: this.entryType,
              expirationDate: this.expiresOn,
              passComment: this.passComment,
              createdDate: this.createdDate,
              modifiedDate: this.modifiedDate,
              createdBy: this.createdBy,
              modifiedBy: this.modifiedBy,
              partyID: data.PartyID,
              EntryPointGroupName: data.EntryPointGroupName,
              internalComment: data.NotesInternal,
              isNonWorkingDayIncluded:
                data.IncludeNonWorkingdays === 1 ? "Yes" : "No",
              isPhotoPassActive: data.PhotoPass === 1 ? true : false,
              parkingLotID: data.ParkingLotID,
              createdByName: data.CreatedByName,
              modifiedByName: data.ModifiedByName,
              passStatus: data.PassDayStatus,
              organizationName: data.OrganizationName,
              parkingSpot: data.ParkingSpot,
              isPassCheckedIn: data.CheckedIn !== 0,
              entryPointGroupID: data.EntryPointGroupID,
              VisitorFirstName: data.VisitorFirstName,
              // VisitorMiddleName: data.VisitorMiddleName,
              VisitorMiddleName: "",
              VisitorLastName: data.VisitorLastName,
              PassID: data.PassID,
              VisitorCompany: data.VisitorCompany,
              PassForms: data.PassForms,
              ApprovalCheckInPreCheck: data.ApprovalCheckInPreCheck,
              ApprovalPassCreateStatus: data.ApprovalPassCreateStatus,
              PhotoPass: data.PhotoPass,
              status: data.Status,
              isValidEntryPointGroup: this.passService.isPassCheckInEntryPointGroupValid(data.EntryPointGroupID)
            }],
            backdropClass: "backdropBackground",
          });
          this.passService.viewPassOpen = true;
        }
      }
    });
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "X" && this.searchService.getPageName() === "Pass") {
      this.searchField.nativeElement.focus();
    }
  }
  // opens edit modal, send row data to modal
  updateRecord() {
    // update pass
    if (this.gridApi.getSelectedRows().length) {
      let data;
      this.passService.getPassDetailsAPI(this.passId).subscribe((res: any) => {
        data = res.body[0];
        if (this.isMobile) {
          if (this.passService.editPassOpen) return;
          const dialogRef = this.dialog.open(EditPassModalComponentMobile, {
            disableClose: true,
            data,
            backdropClass: "backdropBackground",
          });
          this.passService.editPassOpen = true;
        } else {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = data;
          dialogConfig.backdropClass = "backdropBackground";
          dialogConfig.disableClose = true;
          dialogConfig.minWidth = '1200px';
          dialogConfig.maxWidth = '2400px';
          dialogConfig.width = '80%';
          if (this.passService.editPassOpen) return;
          const dialogRef = this.dialog.open(EditPassModalComponentDesktop, dialogConfig);
          this.passService.editPassOpen = true;
        }
      });
    }
  }
  public printPassTransaction(passID: number, action?: string): void {
    const actionType = action ? action : "Print"
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, actionType)
          .then(() => {
            //this.notificationService.success(this.passService.message)
            this.passService.redeemPassAPI(passID).then(() => {
            })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }
  public prePrintPassTransaction(passID: number): void {
    this.passService
          .passTransactionCreateAPI(passID, "Print")
          .then(() => {
            //this.notificationService.success(this.passService.message)
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
  }
  getPrintData() {
    this.selectedRows=[];
    this.passService.image = '';
    this.itemsProcessed = 0;
    if (this.gridApi.getSelectedRows().length) {
      setTimeout(() => {
        this.spinnerService.setIsLoading(true);
      }, 0);
      this.selectedRows = this.gridApi.getSelectedRows().length;
      const selectedRows = this.gridApi.getSelectedRows();
      selectedRows.forEach((element) => {
        this.prePrint(element.PassID, "Regular Pass");
      });
    }
  }
  sendPrintData(printData) {
    this.renderer.setProperty(
      this.printContainer.nativeElement,
      "innerHTML",
      printData
    );

    this.hiddenPrintBtn.nativeElement.click();
    this.htmlString = "";
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
  }

  prePrint(passID: number, tempName: string) {
    const type = "PrePrint";
    if (this.userService.userCheckedInEntryPoint === undefined) {
      this.regularPrint(passID, tempName, type)
    } else {
      const entryPointID = typeof this.userService.userCheckedInEntryPoint == 'number' ? this.userService.userCheckedInEntryPoint : this.userService.userCheckedInEntryPoint.EntryPointID;
      this.passService.checkCloudPrintEnabledAPI(this.siteService.selectedSiteID, entryPointID).subscribe(async (res: any) => {
        if (res.body.IsCloudPrint === true) {
          let passType: Array<String>;
          if (tempName === "Regular Pass") {
            this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
              (this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "CloudVisitorPass")).map(ele => ele.Value) : null);
            passType = ['visitor'];
          } else {
            this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
              (this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "CloudParkingPass")).map(ele => ele.Value) : null);
            passType = ['visitor', 'parking'];
          }
          this.passService.cloudPrintProcessAPI(
            this.siteService.selectedSiteID,
            this.userService.userCheckedInEntryPoint,
            this.userService.userID,
            passID,
            this.templateID,
            passType,
            Number(sessionStorage.getItem("EntryPointStationID")),
          )
            .then((success: HttpResponse<any>) => {
              this.printPassTransaction(passID, type);
              this.notificationService.success(this.passService.message);
              if (this.passService.cloudPrinted === 0) {
                this.regularPrint(passID, tempName, type)
              }
            })
            .catch((err: HttpErrorResponse) => {
              console.log("error", err);
              this.regularPrint(passID, tempName, type);
            });
        }
        else if (res.body.IsCloudPrint === false) {
          this.regularPrint(passID, tempName, type)
        }
      })
    }
  }

  async regularPrint(passID: number, tempName: string, type?: string) {
    const printType = type ? type : "Print"
    if (tempName === "Regular Pass") {
      this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
        this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "VisitorPass").map(ele => ele.Value) : null);
    } else {
      this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
        this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "ParkingPass").map(ele => ele.Value) : null);
    }
    try {
      const html = await this.passService.getPassPrintTemplateAPI(
        passID,
        this.templateID
      );
      this.htmlString = this.htmlString.concat(html);
      this.printPassTransaction(passID, printType);
      this.itemsProcessed++;
      if (this.itemsProcessed === this.selectedRows) {
        this.sendPrintData(this.htmlString);
      }
    } catch (err) {

      console.error(err);
      const printError = this.screenLabels["PrintErrorMsg"] ? this.screenLabels["PrintErrorMsg"] : "Print Error";
      const printErrorMessage = this.screenLabels["CouldNotShowPrintMsg"] ? this.screenLabels["CouldNotShowPrintMsg"] : "Could not show print dialog";
      this.dialogService.openAlertDialogDialog(
        printError,
        printErrorMessage
      );
    }
  }
  // opens create new pass modal with duplicate details of selected pass
  duplicateRecord() {
    let data;
    if (this.gridApi.getSelectedRows().length) {
      //const data = this.gridApi.getSelectedRows()[0];
      this.passService.getPassDetailsAPI(this.passId).subscribe((res: any) => {
        data = res.body[0];
        if (this.isMobile) {
          if (this.passService.duplicatePassOpen) return;
          const dialogRef = this.dialog.open(DuplicatePassModalComponentMobile, {
            disableClose: true,
            data,
            backdropClass: "backdropBackground",
          });
          this.passService.duplicatePassOpen = true;
        } else {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = data;
          dialogConfig.backdropClass = "backdropBackground";
          dialogConfig.disableClose = true;
          dialogConfig.minWidth = '1200px';
          dialogConfig.maxWidth = '2400px';
          dialogConfig.width = '80%';
          if (this.passService.duplicatePassOpen) return;
          const dialogRef = this.dialog.open(DuplicatePassModalComponentDesktop, dialogConfig);
          this.passService.duplicatePassOpen = true;
        }
      });
    }
  }
  // invoked on every keystroke when the user types into the search bar to check if the pattern matches that the format from QR Scanner
  protected async checkScanPattern() {
    const pattern = /\X[0-9]+\X/;
    if (
      this.searchService.key.match(pattern) &&
      this.searchService.getPageName() === "Pass"
    ) {
      this.searchService.key = this.searchService.key
        .replace("X", "")
        .replace("X", "");

      this.passService.getPassDetailsByIDAPI(Number(this.searchService.key)).subscribe(async(res: any) => {
        var data = res.body;
        if (data && data.Active > 0) {
          this.callGetPassDetailsAPI(this.searchService.key);
          this.clearSearchField();
          return;
        }
        else{
          // this.searchService.key = null;
           const noRecordsFoundLabel = this.screenLabels["NoRecordsFoundMsg"] ? this.screenLabels["NoRecordsFoundMsg"] : "No Records Found";
           const noRecordsFoundMsg = this.screenLabels["NoRecordsFoundErrorMsg"] ? this.screenLabels["NoRecordsFoundErrorMsg"] : "The information you entered does not match our records, modify your search and try again.";
 
           this.dialogService.openAlertDialogDialog(
             noRecordsFoundLabel,
             noRecordsFoundMsg);
             this.clearSearchField();
             return;
         }
      });
    }
  }
  onSearchBtnClick() {
    this.spinnerService.setIsLoading(true);
    const key = this.searchField.nativeElement.value.trim()
      ? this.searchField.nativeElement.value.trim()
      : null;
    this.searchService
      .smartSearchMultiSiteAPI(key)
      .then(() => {
        var rowData = this.searchService.searchRecords;
        rowData.forEach((element) => {
          var expTime = moment(element.EstDepartureTime).format("HH:mm");
          element.StartTime = this.formatDate(element.StartTime);
          element.EndTime = this.formatDate(element.EndTime);
          var endDate = element.EndTime;
          var expired;
          if (expTime !== null && endDate !== null) {
            var fullDate = endDate.concat(" ", expTime);
            if (this.today > new Date(fullDate) && element.Active == 1) {
              expired = true;
              element.PassStatus = "Expired";
            } else {
              expired = false;
            }
          }
          if (element.Active == 1 && !expired) {
            element.PassStatus = "Active";
          } else if (element.Active == 0) {
            element.PassStatus = "Inactive";
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.emit(true);
  }
  clearSearchField() {
    this.searchField.nativeElement.value = "";
    this.onSearchBtnClick();
  }
  onSerchFilterChange(event) {
    this.selectedSerchFilter = event.value;
    //setting searchtype so that updated searchtype is reflected in other screens as well
    localStorage.setItem('searchType', this.selectedSerchFilter);
    this.searchService.userSearchTypeUpdate(this.selectedSerchFilter, this.userService.userID).subscribe((res:HttpResponse<any>) => {
      console.log("response",res);
    })
  }
  // delete selected row
  onRemoveSelected() {
    const deletePass = this.screenLabels["DeletePassMsg"] ? this.screenLabels["DeletePassMsg"] : "Delete Pass";
    const replaceID = this.screenLabels["DeletePassWarningMsg"] ? this.screenLabels["DeletePassWarningMsg"] : "Are you sure you want to remove pass #<<id1>> ?";
    const deletePassMsg = replaceID.replace("<<id1>>", this.passId)

    const confirmDialogRef = this.dialogService.openConfirmDialog(
      deletePass,
      deletePassMsg
    );

    confirmDialogRef.afterClosed().subscribe((confirm: Boolean) => {
      if (this.gridApi.getSelectedRows().length && confirm) {
        setTimeout(() => {
          this.spinnerService.setIsLoading(true);
        }, 0);
        let data = this.gridApi.getSelectedRows()[0];
        data.Active = false;
        data.AllowDup = true;
        data.UserID = this.userService.userID;
        this.passService
          .updatePassAPI([data])
          .then(() => {
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
            const replaceID = this.screenLabels["DeletePassSuccessMsg"] ? this.screenLabels["DeletePassSuccessMsg"] : "Pass #<<id1>> has been removed.";
            const deleteSuccessMsg = replaceID.replace("<<id1>>", this.passId)

            this.notificationService.success(
              deleteSuccessMsg
            );
          })
          .catch((err) => {
            this.notificationService.failure(err.message);
          });
      }
    });
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
  }
  onFilterBtnClick() {
    const dialogRef = this.dialog.open(SearchFilterModalComponent, {
      disableClose: false,
      data: { pageName: this.searchService.getPageName() },
      backdropClass: "backdropBackground",
    });
  }

  async getDefaultEntryPoint() {
    await this.entryPointService.parseGetEndpointAPI();
    this.userService.userCheckedInEntryPoint;
    this.entryPoints = this.entryPointService.getEntryPoints();

    if (typeof this.userService.userCheckedInEntryPoint === 'number') {
      const idToName = this.entryPoints.find((e) => e.EntryPointID === this.userService.userCheckedInEntryPoint)
      this.selectedEntryPoint = idToName;
    }
    else if (this.userService.userCheckedInEntryPoint && typeof this.userService.userCheckedInEntryPoint !== 'number') {
      const oldEntryPointName = this.entryPoints.find((e) => e.Description === this.userService.userCheckedInEntryPoint.Description && e.HostName === this.userService.userCheckedInEntryPoint.HostName);
      this.selectedEntryPoint = oldEntryPointName;
    }
  }
  ngOnInit() {
    this.searchService.setPageName("Pass");

    this._settingsAPIResponse = this.siteService._siteSettings;
    this.callInUser = this.authService.validCaller;

    //labels by message service
    if (localStorage.getItem('DashboardLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('DashboardLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        this.searchService.getPageName(),
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('DashboardLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.passService.getPassIDFromAPI().subscribe((data) => {
      this.passIDFromAPI = data.passID;
    });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach((element) => {
              element.StartTime = this.formatDate(element.StartTime);
              element.EndTime = this.formatDate(element.EndTime);
            });

            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.roleMapService
          .getRoleMappingsAPI()
          .then(() => {
            this.userService
              .getUserAssociationAPI(this.userService.userID, this.siteService.selectedSiteID)
              .then(() => {
                localStorage.setItem('searchType', this.userService.getUserDetails()[0].UserSearchType);
                //fetching searchtype from getuser
                this.selectedSerchFilter = localStorage.getItem('searchType');

                this.userOrganizations = [
                  ...this.userService
                    .getUserDetails()[0]
                    .UserOrganization.map((o) => ({
                      OrganizationID: o.OrganizationID,
                      Name: o.Name,
                      StartDate: o.Startdate,
                      EndDate: o.EndDate,
                      IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
                      Active: o.UserOrganizationActive,
                    }))
                    .filter((f) => f.Active),
                ];
                this.userService.organizationID = 0;
                this.userService.userOrganizations = this.userOrganizations;
                // setting the organization id for the first time
                if (
                  this.userOrganizations &&
                  this.userOrganizations.length > 0 &&
                  this.userService.organizationID <= 0
                ) {
                  this.userService.organizationID = this.userOrganizations[0].OrganizationID;
                  this.searchService.setOrganizationID(
                    this.userOrganizations[0].OrganizationID
                  );
                  this.userService.IsAdmin = this.userService.userOrganizations.find(
                    (uo) => uo.OrganizationID === this.userService.organizationID
                  ).IsAdmin;

                  //load User team functions

                  this.teamService
                    .getUserTeamAPI(this.userService.userID)
                    .then(() => {
                      // load the organization basket
                      var userTeams = this.teamService.userTeams;
                      this.userService.userTeams = userTeams.map((team) => ({
                        UserTeamID: team.UserTeamID,
                        TeamID: team.TeamID,
                        Active: team.Active,
                        Name: team.Name,
                        OrganizationID: team.OrganizationID,
                        TemplateCategoryTeamList: team.TemplateCategoryTeamList,
                        OrganizationFunctionTeamList:
                          team.OrganizationFunctionTeamList,
                        AutoAddAllCategories: team.AutoAddAllCategories,
                        AutoAddAllOrgUsers: team.AutoAddAllOrgUsers,
                        ModifiedBy: team.ModifiedBy,
                        ModifiedDate: team.ModifiedDate,
                        ModifiedByName: team.ModifiedByName,
                        CreatedBy: team.CreatedBy,
                        CreatedDate: team.CreatedDate,
                        CreatedByName: team.CreatedByName,
                      }));
                      this.userService.userTeams.forEach((element) => {
                        element.OrganizationFunctionTeamList.forEach(
                          (orgFunction) => {
                            if (
                              orgFunction.FunctionSelected == 1 &&
                              !this.userService.userOrgFunctions[orgFunction.Tag]
                            ) {
                              this.userService.userOrgFunctions[
                                orgFunction.Tag
                              ] = true;
                            }
                          }
                        );
                      });
                    })
                    .catch((err: HttpErrorResponse) => {
                      this.notificationService.failure(err.message);
                    });
                  this.passService.getUserTemplateCategoriesAPI(this.userService.userID, this.userService.organizationID)
                  this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, true);
                }

                // to get Ip Adress
                this.userRoles = [
                  ...this.userService
                    .getUserDetails()[0]
                    .UserRole.map((r) => ({
                      RoleID: r.RoleID,
                      Active: r.UserRoleActive,
                      UserCheckin: this.roleMapService
                        .getSelectedFunctions(r.RoleID)
                        .filter(
                          (f) =>
                            f.Active &&
                            f.FunctionID ==
                            this.roleMapService.getPassCheckinFunctionID()
                        ).length
                        ? true
                        : false,
                    }))
                    .filter((f) => f.Active),
                ];
                // Check if logged in userRole has checkin privileges
                this.userCheckIn = this.userRoles.filter((f) => f.UserCheckin)
                  .length
                  ? true
                  : false;
                this.userService.userCheckIn = this.userCheckIn;
                this.userService.userDetailsLoaded.emit(true); // emitter to load nav-bar after dashboard is loaded

                if (this.userCheckIn) {
                  //if user has checkin then set entrypointdrop and load Passes based on staion Ip
                  if (
                    this.userOrganizations.length > 0 &&
                    this.userService.organizationID <= 0
                  ) {
                    this.selectedOrg = this.userOrganizations[0].OrganizationID;
                    this.userService.organizationID = this.selectedOrg;
                    this.userService.IsAdmin = this.userOrganizations[0].IsAdmin
                      ? this.userOrganizations[0].IsAdmin == 1
                        ? 1
                        : 0
                      : 0;
                    this.searchService.setOrganizationID(this.selectedOrg);
                    this.userService.organizationChanged.emit(true);
                  }
                } else {
                  //Api call to get entry points
                  if (this.userOrganizations.length > 0) {
                    this.selectedOrg = this.userOrganizations[0].OrganizationID;
                    this.searchService.setOrganizationID(this.selectedOrg);
                  }
                }
                this.searchService
                  .smartSearchMultiSiteAPI(null, "Pass", "Pass")
                  .then(() => {
                    this.rowData = this.searchService.searchRecords;
                    this.rowData.forEach((element) => {
                      element.StartTime = this.formatDate(element.StartTime);
                      element.EndTime = this.formatDate(element.EndTime);
                    });

                    this.gridApi.paginationGoToPage(0);
                  })
                  .catch((err: HttpErrorResponse) => {
                    this.notificationService.failure(err.message);
                  });
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });

      }
    });
    // subscribe to data changed
    this.passService.dataChanged.subscribe((event: boolean) => {
      this.searchService
        .smartSearchMultiSiteAPI(null, "Pass", "Pass")
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.rowData.forEach((element) => {
            element.StartTime = this.formatDate(element.StartTime);
            element.EndTime = this.formatDate(element.EndTime);
          });

          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      this.searchService
        .smartSearchMultiSiteAPI(null, "Pass", "Pass")
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.rowData.forEach((element) => {
            element.StartTime = this.formatDate(element.StartTime);
            element.EndTime = this.formatDate(element.EndTime);
          });

          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });

    });
    this.searchService.entryPointDataChanged.subscribe(
      (dataChanged: boolean) => {
        this.searchService
          .smartSearchMultiSiteAPI(null, "Pass", "Pass")
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach((element) => {
              element.StartTime = this.formatDate(element.StartTime);
              element.EndTime = this.formatDate(element.EndTime);
            });
            this.gridApi.paginationGoToPage(0);

            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    );

    //for triggering view pass modal from service on newpass screen
    this.passService.openViewPass.subscribe((event: boolean) => {
      if (event) {
        this.openPass(true);
      }
    });
  }
  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["VisitorLastName"] ? this.screenLabels["VisitorLastName"] : "Visitor Last Name",
        field: "VisitorLastName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["VisitorFirstName"] ? this.screenLabels["VisitorFirstName"] : "Visitor First Name",
        field: "VisitorFirstName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Destination"] ? this.screenLabels["Destination"] : "Destination",
        field: "BuildingName",
        sortable: true,
        cellClass: (params) => {
          return params.data.DestinationBldgActive === 0
            ? "inActiveLinkedField"
            : "";
        },
      },
      {
        headerName: this.screenLabels["ParkingAssignment"] ? this.screenLabels["ParkingAssignment"] : "Parking Assignment",
        field: "ParkingLotName",
        hide: this.siteService._siteHasParking === true ? false : true,
        sortable: true,
        valueSetter: (params) => {
          var parkingID = params.data.ParkingLotID;
          if (parkingID) {
            var parkingName = this.parkingLotService.getParkingLotName(parkingID);
          }
          return parkingName;
        },
      },
      {
        headerName: this.screenLabels["DateRangeLabel"] ? this.screenLabels["DateRangeLabel"] : "Date Range",
        field: "date",
        width: 360,
        sortable: true,
        valueGetter: (params) => {
          const dateRange = (this.datePipe.transform(
            params.data.StartTime,
            this.siteService.dataTimeFormat.angularDateFormat))
            + '  -  ' + (this.datePipe.transform(
              params.data.EndTime,
              this.siteService.dataTimeFormat.angularDateFormat));
          return dateRange;
        },
      },
      {
        headerName: this.screenLabels["ArrivalTime"] ? this.screenLabels["ArrivalTime"] : "Arrival Time",
        field: "EstArrivalTime",
        cellRenderer: (data) => {
          return data.value
            ? moment(data.value).format(
              this.siteService.dataTimeFormat.momentTimeFormat
            )
            : null;
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["OrganizationLabel"] ? this.screenLabels["OrganizationLabel"] : "Organization",
        field: "OrganizationName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["HostName"] ? this.screenLabels["HostName"] : "Host Name",
        field: "HostName",
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const hostName =
            (params.data.HostLastName) + ',  ' + (params.data.HostFirstName);
          return hostName;
        },
      },
      {
        headerName: this.screenLabels["ReasonForVisit"] ? this.screenLabels["ReasonForVisit"] : "Reason For Visit",
        field: "DisplayValue",
        // width: 175,
        cellClass: (params) => {
          return params.data.VisitReasonActive === 0 ? "inActiveLinkedField" : "";
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["EntryType"] ? this.screenLabels["EntryType"] : "Entry Type",
        field: "EntryType",
        cellRenderer: (data) => {
          if (+data.value === 1) {
            return "Drive-On";
          } else if (+data.value === 2) {
            return "Walk-On";
          }
          return null;
        },
        sortable: true,
        hide: this.siteService._siteHasParking ? false : true
      },
      {
        headerName: this.screenLabels["PassID"] ? this.screenLabels["PassID"] : "Pass ID",
        field: "PassID",
        cellStyle: { color: "#589bf8" },
        // sort: "desc",
        hide: false,
        sortable: true,
        filter: "agNumberColumnFilter",
      },
      {
        headerName: this.screenLabels["StatusBtn"] ? this.screenLabels["StatusBtn"] : "Status",
        field: "PassDayStatus",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["EmployeeRef"] ? this.screenLabels["EmployeeRef"] : "EmployeeRef",
        field: "EmployeeRef",
        hide: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.screenLabels["ActiveOn"] ? this.screenLabels["ActiveOn"] : "Active On",
        field: "StartTime",
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;
        },
        //width: 125,

        filter: "agDateColumnFilter",
        sortable: true,
        hide: true
      },
      {
        headerName: this.screenLabels["ExpiresOn"] ? this.screenLabels["ExpiresOn"] : "Expires On",
        field: "EndTime",
        //width: 125,
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;
        },
        filter: "agDateColumnFilter",
        sortable: true,
        hide: true
      },
      {
        headerName: this.screenLabels["VisitorSuffix"] ? this.screenLabels["VisitorSuffix"] : "Visitor Suffix",
        field: "VisitorNameSuffix",
        hide: true,
        sortable: true,
      },
      // {
      //   headerName: this.screenLabels["VisitorMiddleName"] ? this.screenLabels["VisitorMiddleName"] : "Visitor Middle Name",
      //   field: "VisitorMiddleName",
      //   hide: true,
      //   sortable: true,
      // },
      {
        headerName: this.screenLabels["VisitorCompany"] ? this.screenLabels["VisitorCompany"] : "Visitor Company",
        field: "VisitorCompany",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["VisitorPhoneNumber"] ? this.screenLabels["VisitorPhoneNumber"] : "Visitor Phone Number",
        field: "VisitorPhone",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["VisitorEmail"] ? this.screenLabels["VisitorEmail"] : "Visitor Email",
        field: "VisitorEmail",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["HostLastName"] ? this.screenLabels["HostLastName"] : "Host Last Name",
        field: "HostLastName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["HostFirstName"] ? this.screenLabels["HostFirstName"] : "Host First Name",
        field: "HostFirstName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["HostPhoneNumber"] ? this.screenLabels["HostPhoneNumber"] : "Host Phone Number",
        field: "HostPhone",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["HostEmail"] ? this.screenLabels["HostEmail"] : "Host Email",
        field: "HostEmail",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DepartureTime"] ? this.screenLabels["DepartureTime"] : "Departure Time",
        field: "EstDepartureTime",
        hide: true,
        cellRenderer: (data) => {
          return data.value ? moment(data.value).format("HH:mm") : null;
        },
        sortable: true,
      },

      {
        headerName: this.screenLabels["ExpectedPointID"] ? this.screenLabels["ExpectedPointID"] : "Expected Point ID",
        field: "ExpectedEntryPoint",
        hide: true,
        width: 150,
        cellClass: (params) => {
          return params.data.ExpectedEntryPointActive === 0
            ? "inActiveLinkedField"
            : "";
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["EntryPointGroupID"] ? this.screenLabels["EntryPointGroupID"] : "Entry Point group ID",
        field: "EntryPointGroupID",
        hide: true,
        width: 150,
        sortable: true,
      },
      {
        headerName: this.screenLabels["EntryPointGroupName"] ? this.screenLabels["EntryPointGroupName"] : "Entry Point Group Name",
        field: "EntryPointGroupName",
        hide: true,
        width: 150,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Room"] ? this.screenLabels["Room"] : "Room",
        field: "DestinationRoom",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ParkingSpace"] ? this.screenLabels["ParkingSpace"] : "Parking Space",
        field: "ParkingSpot",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["InternalComment"] ? this.screenLabels["InternalComment"] : "Internal Comment",
        field: "NotesInternal",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["PassComment"] ? this.screenLabels["PassComment"] : "Pass Comment",
        field: "NotesPass",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ArrivalTime"] ? this.screenLabels["ArrivalTime"] : "Arrival Time",
        field: "EstArrivalTime",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DepartureTime"] ? this.screenLabels["DepartureTime"] : "Departure Time",
        field: "EstDepartureTime",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DestinationBldgActive"] ? this.screenLabels["DestinationBldgActive"] : "DestinationBldgActive",
        field: "DestinationBldgActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DestinationBlgFlrActive"] ? this.screenLabels["DestinationBlgFlrActive"] : "DestinationBlgFlrActive",
        field: "DestinationBlgFlrActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: "ExpectedEntryPointActive",
        field: "ExpectedEntryPointActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["EntryPointGroupIDActive"] ? this.screenLabels["EntryPointGroupIDActive"] : "EntryPointGroupIDActive",
        field: "EntryPointGroupIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["OrganizationIDActive"] ? this.screenLabels["OrganizationIDActive"] : "OrganizationIDActive",
        field: "OrganizationIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ParkingLotIDActive"] ? this.screenLabels["ParkingLotIDActive"] : "ParkingLotIDActive",
        field: "ParkingLotIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["EntryTypeActive"] ? this.screenLabels["EntryTypeActive"] : "EntryTypeActive",
        field: "EntryTypeActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["VisitReasonActive"] ? this.screenLabels["VisitReasonActive"] : "VisitReasonActive",
        field: "VisitReasonActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["OperatorActive"] ? this.screenLabels["OperatorActive"] : "OperatorActive",
        field: "OperatorActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AuthorizerActive"] ? this.screenLabels["AuthorizerActive"] : "AuthorizerActive",
        field: "AuthorizerActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CheckedIn"] ? this.screenLabels["CheckedIn"] : "Checked In",
        field: "CheckedIn",
        hide: true,
        sortable: true,
      },
      {
        headerName: "EntryPointGrpName",
        field: "EntryPointGrpName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedByName"] ? this.screenLabels["CreatedByName"] : "CreatedByName",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedByName"] ? this.screenLabels["ModifiedByName"] : "ModifiedByName",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
    this.mobileColumns = [
      {
        headerName: "",
        field: "",
        width: 35,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["LastName"] ? this.screenLabels["LastName"] : "Last Name",
        field: "VisitorLastName",
        sortable: true,
        width: 100,
      },
      {
        headerName: this.screenLabels["FirstName"] ? this.screenLabels["FirstName"] : "First Name",
        field: "VisitorFirstName",
        sortable: true,
        width: 100,
      },
      {
        headerName: this.screenLabels["StatusBtn"] ? this.screenLabels["StatusBtn"] : "Status",
        field: "PassDayStatus",
        hide: false,
        sortable: true,
        lockPosition: true,
        width: 100,
      },
      {
        headerName: this.screenLabels["ID"] ? this.screenLabels["ID"] : "ID",
        field: "PassID",
        cellStyle: { color: "#589bf8" },
        // sort: "desc",
        hide: false,
        sortable: true,
        filter: "agNumberColumnFilter",
        width: 85,
      },
    ];
  }
  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDefs();
  }

  async _loadSiteSettings() {

    this._settingsAPIResponse = await this.siteService.getSiteSettingsAPI();
  }
  //on organization dropdown change

  onOrganizationChange($event) {
    var data = $event;

    // log organization change
    this.organizationService.logOrganizationChange(
      this.searchService.getOrganizationID(),
      data.value
    );

    this.searchService.setOrganizationID(data.value);

    this.userService.organizationID = data.value;
    this.userService.userOrganizations.forEach((element) => {
      if (element.OrganizationID == this.userService.organizationID)
        this.userService.IsAdmin = element.IsAdmin
          ? element.IsAdmin == 1
            ? 1
            : 0
          : 0;
    });

    this.userService.organizationChanged.emit(true);
    this.searchService
      .smartSearchMultiSiteAPI(null, "Pass", "Pass")
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.rowData.forEach((element) => {
          element.StartTime = this.formatDate(element.StartTime);
          element.EndTime = this.formatDate(element.EndTime);
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    this.passService.getUserTemplateCategoriesAPI(this.userService.userID, this.userService.organizationID);
    this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, true);
  }

  formatDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    return formattedDate ? formattedDate : null;
  }

  // the method is invoked to open View Pass Transaction modal
  protected viewPassTransaction(): void {
    if (this.gridApi.getSelectedRows().length) {
      const data = this.gridApi.getSelectedRows()[0];
      if (this.isMobile) {
        const dialogRef = this.dialog.open(ViewPassTransactionModalComponent, {
          disableClose: true,
          data,
          backdropClass: "backdropBackground",
        });
      } else {
        const dialogRef = this.dialog.open(ViewPassTransactionModalComponent, {
          disableClose: true,
          data,
          backdropClass: "backdropBackground",
        });
      }
    }
  }
  // on change of entrypoint dropdown
  async onEntryPointChange(event) {
    const oldEntryPointID = this.searchService.getEntryPointID();
    const newEntryPointID = event.value.EntryPointID;
    const newStationID = event.value.EntryPointStationID;
    const oldStationID = Number(sessionStorage.getItem("EntryPointStationID"));
    const oldEntryPointName = (oldEntryPointID
      ? this.entryPointService
        .getEntryPoints()
        .find((e) => e.EntryPointID === oldEntryPointID).Description
      : null) + oldStationID;
    const fetchDesc = oldEntryPointID ? this.entryPointService.getEntryPoints().find((e) => e.EntryPointID === oldEntryPointID).Description : null;
    const newEntryPointName = event.value.Description + newStationID;

    this.newNameEntrypoint = newEntryPointName;

    if (oldEntryPointName != newEntryPointName) {
      this.spinnerService.setIsLoading(true);
      const switchingEPLabel = this.screenLabels["SwitchingtoEntryPointMsg"] ? this.screenLabels["SwitchingtoEntryPointMsg"] : "Switching to different Entry Point";
      const switchingEPMsg = this.screenLabels["SwitchingtoEntryPointWarningMsg"] ? this.screenLabels["SwitchingtoEntryPointWarningMsg"] : "You are trying to switch to a different Entry Point. Do you want to proceed ?";

      const confirmRef = this.dialogService.openConfirmDialog(
        switchingEPLabel,
        switchingEPMsg
      );
      confirmRef.afterClosed().subscribe(async (confirm: boolean) => {
        if (confirm) {
          sessionStorage.removeItem("EntryPointStationID");
          let entryPointStationValue = newStationID;
          sessionStorage.setItem("EntryPointStationID", entryPointStationValue);
          this.searchService.setEntryPointID(newEntryPointID);
          this.userService.userCheckedInEntryPoint = event.value;
          const EntryPointChange = "Entry Point Change";
          const maskedMsg = "Switching to different Entry Point";

          // log it into user action history
          this.userActionsService.recordUserActionAPI(
            EntryPointChange,
            `Changed From: ${oldEntryPointName} (${oldEntryPointID}) To: ${newEntryPointName} (${newEntryPointID})`
          );

          // get list of all the valid entry points that permits check in
          await this.passService.getValidEntryPointGroupsAPI(newEntryPointID);

          this.searchService.entryPointDataChanged.emit(true);
          this.spinnerService.setIsLoading(false);
        } else {
          this.spinnerService.setIsLoading(false);
          if (oldEntryPointID === 0) {
            //   this.userService.userCheckedInEntryPoint = this.userCheckedInEntryPoint;
            this.newNameEntrypoint = "";
            this.selectedEntryPoint = [];
          }
          else {
            // this.userService.userCheckedInEntryPoint = this.userCheckedInEntryPoint;
            this.newNameEntrypoint = oldEntryPointName;
            const oldEntryPoint = this.entryPoints.find((e) => e.Description === fetchDesc);
            this.selectedEntryPoint = oldEntryPoint;
          }
        }
      });
    }
  }

  resendEmail() {
    if (this.gridApi.getSelectedRows().length) {
      let data = this.gridApi.getSelectedRows()[0];
      const resendEmail = this.screenLabels["NotifyMsg"] ? this.screenLabels["NotifyMsg"] : "Visitor Notification";
      const resendMsg = this.screenLabels["NotifyWarningMsg"] ? this.screenLabels["NotifyWarningMsg"] : "Are you sure you want to resend the email to the visitor ?";

      const confirmDialogRef = this.dialogService.openConfirmDialog(
        resendEmail,
        resendMsg
      );
      confirmDialogRef.afterClosed().subscribe((confirm: Boolean) => {
        if (confirm) {
          this.passService.sendPassEmailToVisitorAPI(data.PassID);
        }
      });
    }
  }
}