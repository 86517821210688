import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatRadioChange,
  MatSelectChange,
  MatCheckboxChange,
  MatChipInputEvent,
  MatAutocompleteTrigger,
  MatAutocompleteSelectedEvent,
} from "@angular/material";
import { map, startWith, min } from "rxjs/operators";

import { SearchService } from "src/app/services/search.service";
import { DialogService } from "src/app/services/dialog.service";
import { Role } from "src/app/models/role.model";
import { FormControl, NgForm } from "@angular/forms";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { RoleMapService } from "src/app/services/role-map.service";
import { Observable } from "rxjs";
import { UtilityService } from "src/app/services/utility.service";
import { UserService } from "src/app/services/user.service";
import { TeamService } from "src/app/services/team-service";
import { SiteService } from "src/app/services/site.service";
import { NotificationService } from "src/app/services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import {
  UserInvitationService,
  ISubmitUserRoleRequestAPI,
} from "src/app/services/user-invitation.service";
import { ISearchUserInviteGetAPI } from "src/app/models/interfaces/search-user-invite-get-api";
import { Team } from "src/app/models/team.model";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-invite-new-user-modal",
  templateUrl: "./invite-new-user-modal.component.html",
  styleUrls: ["./invite-new-user-modal.component.css"],
})
export class InviteNewUserModal implements OnInit {
  @ViewChild("inviteUserForm") inviteUserForm: NgForm;

  private _userInfoResponse: ISearchUserInviteGetAPI;

  protected step = 0;

  protected rolesLoaded: boolean = false;
  protected teamsLoaded: boolean = false;
  protected userDetailsLoading: boolean = false;
  protected roleEditDisabled: boolean = true;
  protected teamEditDisabled: boolean = true;
  protected userAlreadyRegisteredToOrg: boolean = false;
  protected userFound: boolean = false;
  protected allowRecordEdit = false;

  protected allRoles: Role[] = [];
  protected availableOrdAdminRoles: Role[] = [];
  protected allTeams: Team[] = [];

  protected rolesNames: string[] = [];
  protected selectedRoleNames: string[];
  protected requestedRoleNames: string[];
  protected requestedRoleArray: string[];

  protected teamNames: string[] = [];
  protected selectedTeamNames: string[] = [];
  protected existingTeamName: string[] = [];

  protected firstName: string;
  protected middleName: string;
  protected lastName: string;
  protected phone: string;
  protected suffix: string;
  protected employeeRef: string;
  protected userEmail: string;
  protected requesterComments: string;
  private _userIDtoSubmit: number;
  protected existingUserSelected:boolean = false;
  protected enableSubmit: boolean = false; 
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  isValidEmail(email: string): boolean {
    const emailPattern = /^$|^[a-zA-Z0-9._%+-]*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,2}$/
    return emailPattern.test(email);
  }
  
  constructor(
    private _dialogRef: MatDialogRef<InviteNewUserModal>,
    private _roleService: RoleMapService,
    private _utilService: UtilityService,
    private _teamService: TeamService,
    private _userService: UserService,
    private _siteService: SiteService,
    private _userInvitationService: UserInvitationService,
    private _notificationService: NotificationService,
    private _dialogService: DialogService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) { }

  async ngOnInit() {
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    try {
      await this._loadRoles();
      await this._loadTeamNames();
    } catch (err) {
      console.log(err);
    }

//labels by message service
if (localStorage.getItem('InviteNewUserLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('InviteNewUserLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Invite User Team Modal",
      "User"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('InviteNewUserLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Invite User',
      this.inviteUserForm
    );
  }


  protected setStep(index: number) {
    this.step = index;
  }

  protected onNoClick(): void {
    this._dialogRef.close();
  }

  protected prevStep() {
    this.step--;
  }

  protected nextStep() {
    this.step++;
  }

  private async _loadRoles() {
    try {
      await this._roleService.getRoleMappingsAPI();
      console.log(this._roleService.getRoleMappingResponse());

      // filter all roles
      this.allRoles = this._roleService
        .getRoleMappingResponse()
        .filter(
          (r) =>
            r.RoleActive === 1 && r.SiteID === this._siteService.selectedSiteID
        )
        .map((r) => ({
          RoleID: r.RoleID,
          SiteID: r.SiteID,
          RoleName: r.RoleName,
          Notes: undefined,
          Active: r.RoleActive,
        }));

      this.availableOrdAdminRoles = await this._userInvitationService.getOrgAdminAvailableRoles();

      // remove duplicates for role names

      this.rolesNames = this._getOrgAdminRoleNames();

      console.log("Filtered Role", this.rolesNames);

      this.rolesLoaded = true;
    } catch (err) {
      console.error(err);
    }
  }

  private _getOrgAdminRoleNames(): string[] {
    return [
      ...new Set(
        this.availableOrdAdminRoles
          .filter((ar) => ar.SiteID === this._siteService.selectedSiteID)
          .map((ar) => ar.RoleName)
      ),
    ];
  }

  private async _loadTeamNames() {
    try {
      let teams = this._teamService.getTeam();

      if (teams.length === 0) {
        // call the api, since no roles loaded
        await this._teamService.getTeamAPI(null);
        teams = this._teamService.getTeam();
      }

      teams = teams.filter(
        (f) =>
          f.OrganizationID == this._userService.organizationID &&
          f.Active === 1 &&
          f.AutoAddAllOrgUsers == 0
      );

      this.allTeams = teams;

      console.log("Filtered Teams", teams);

      this.teamNames = teams.map((t) => t.Name);
      this.teamsLoaded = true;
    } catch (err) {
      console.error(err);
    }
  }

  private _clearFormFields() {
    this.inviteUserForm.form.controls.firstName.reset();
    this.inviteUserForm.form.controls.middleName.reset();
    this.inviteUserForm.form.controls.lastName.reset();
    this.inviteUserForm.form.controls.suffix.reset();
    this.inviteUserForm.form.controls.phone.reset();
    this.inviteUserForm.form.controls.selectedTeamsChips.reset();
    this.inviteUserForm.form.controls.roleChips.reset();
    this.inviteUserForm.form.controls.requesterComments.reset();

    this.requesterComments = null;

    this.selectedRoleNames = [];
    this.selectedTeamNames = [];
    this.existingTeamName = [];

    this.roleEditDisabled = true;
    this.allowRecordEdit = false;
    this.teamEditDisabled = true;

    this.employeeRef = null;

    this._userIDtoSubmit = null;
  }

  
  protected async searchUser() {
    try {
      const email: string = this.inviteUserForm.form.controls.email.value;
      this.userDetailsLoading = true;
      this.enableSubmit = false;
      this._clearFormFields();

      const res: ISearchUserInviteGetAPI = await this._userInvitationService.searchUserToInviteAPI(
        email
      );

      this._userInfoResponse = res;

      const isUserFound = res.UserFound;

      if (isUserFound === 1) {
        this.firstName = res.FirstName;
        this.middleName = res.MiddleName;
        this.lastName = res.LastName;
        this.phone = res.Phone;
        this.employeeRef = res.EmployeeRef;
        this.userEmail = res.Email;
        this._userIDtoSubmit = res.UserID;

        if (res.IsExistingUser === 1) {
          // filter the pre-existing association of teams for the given site
          this.selectedTeamNames = res.UserTeam.filter(
            (ut) =>
              ut.OrganizationID === this._userService.organizationID &&
              ut.Active === 1
          ).map((ut) => ut.Name);
          console.log("selected team names", this.selectedTeamNames);

          this.existingTeamName = [...this.selectedTeamNames];

          // filter the pre-existing association of roles for the given site
          this.selectedRoleNames = res.Role.filter(
            (r) =>
              r.SiteID === this._siteService.selectedSiteID && r.Active === 1
          ).map((r) => r.RoleName);

          if(res.RequestedRole.length > 0) {
          this.requestedRoleArray = res.RequestedRole.filter(
            (r) =>
              r.Active === 1
          ).map((r) => r.RoleName);
          }

          this.rolesNames = this._getOrgAdminRoleNames().filter(
            (rn) => !this.selectedRoleNames.includes(rn)
          );
          if(res.RequestedRole.length > 0) {
          this.rolesNames = this._getOrgAdminRoleNames().filter(
            (rn) => !this.requestedRoleArray.includes(rn)
          );
          }
        } else {
          this.rolesNames = this._getOrgAdminRoleNames();
        }

        // // to check if the requested user is already associated to the existing organization
        // this.userAlreadyRegisteredToOrg =
        //   res.UserOrganization.filter(
        //     (o) => o.OrganizationID === this._userService.organizationID
        //   ).length > 0;

        this.teamEditDisabled = false;
        this.allowRecordEdit = false;
        this.roleEditDisabled = false;
      } else {
        this.allowRecordEdit = true;
        this.teamEditDisabled = false;
        this.roleEditDisabled = false;

        this.userEmail = email;


        this._userIDtoSubmit = 0;
      }
    } catch (err) {
      this._notificationService.failure(err.message);
    } finally {
      this.userDetailsLoading = false;
      this.enableSubmit = true;
    }
  }

  protected async requestUserAccess() {
    const isDataValid = this._validate();

    console.log("Data Valid", isDataValid);

    if (isDataValid) {
      const frmEmail: string = this.inviteUserForm.form.controls.email.value;

      const requestedRoles = this._getRequestedRoles().map((r) => ({
        RoleID: r.RoleID,
        Active: 1,
      }));

      const requestedTeams = this._getRequestedTeams();

      this.userEmail = this.userEmail ? this.userEmail : frmEmail;

      const body: ISubmitUserRoleRequestAPI = {
        UserIDToSubmit: this._userIDtoSubmit ? this._userIDtoSubmit : 0,
        SiteID: this._siteService.selectedSiteID,
        Email: this.userEmail,
        FirstName: this.firstName,
        LastName: this.lastName,
        Phone: this.phone,
        UserID: this._userService.userID,
        OrganizationID: this._userService.organizationID,
        Role: requestedRoles,
        Team: requestedTeams,
        RequesterComments: this.requesterComments,
      };

      console.log(body);

      this._userInvitationService
        .submitUserRoleRequestAPI(body)
        .then((s) => {
          this._notificationService.success(s.Message);
        })
        .catch((err: HttpErrorResponse) => {
          this._notificationService.failure(err.message);
        });
    }

    this.onNoClick();
  }

  private _validate() {
    const frmEmail: string = this.inviteUserForm.form.controls.email.value;
    if (!this.userEmail && !frmEmail) {
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["MissingEmail"] ? this.screenLabels["MissingEmail"] : "Missing Email",
        this.screenLabels["EmailCannotBeEmpty"] ? this.screenLabels["EmailCannotBeEmpty"] : "Email cannot be empty"
      );
      return false;
    }

    this.firstName = this.firstName ? this.firstName.trim() : this.firstName;
    this.lastName = this.lastName ? this.lastName.trim() : this.lastName;

    if (!this.firstName) {
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["ReqFirstName"] ? this.screenLabels["ReqFirstName"] : "Required First Name",
        this.screenLabels["FirstNameCannotBeEmpty"] ? this.screenLabels["FirstNameCannotBeEmpty"] : "First name cannot be empty"
      );
      return false;
    }

    if (!this.lastName) {
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["ReqLastName"] ? this.screenLabels["ReqLastName"] : "Required Last Name",
        this.screenLabels["LastNameCannotBeEmpty"] ? this.screenLabels["LastNameCannotBeEmpty"] : "Last name cannot be empty"   
      );
      return false;
    }

    if (!this.requestedRoleNames || this.requestedRoleNames.length === 0) {
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["ReqRoles"] ? this.screenLabels["ReqRoles"] : "Required Roles",
        this.screenLabels["RolesCannotBeEmpty"] ? this.screenLabels["RolesCannotBeEmpty"] : "Roles cannot be empty"  
      );
      return false;
    }

    return true;
  }

  private _getRequestedRoles(): Role[] {
    // remove duplicates

    let requestedRoles = this.allRoles.filter((ar) =>
      this.requestedRoleNames.includes(ar.RoleName)
    );

    requestedRoles = this._utilService.removeObjArrayDuplicates(requestedRoles);

    console.log("Requested Roles", requestedRoles);

    return requestedRoles;
  }

  private _getRequestedTeams() {
    let userTeams: {
      UserTeamID: number;
      TeamID: number;
      Name: string;
      OrganizationID: number;
      AutoAddAllOrgUsers: number;
      AutoAddAllCategories: number;
      UserID: number;
      Active: number;
    }[];

    // remove duplicates
    this.selectedTeamNames = this._utilService.removeObjArrayDuplicates(
      this.selectedTeamNames
    );

    if (this._userInfoResponse.UserTeam) {
      userTeams = this._userInfoResponse.UserTeam.filter(
        (ut) =>
          ut.Active === 1 &&
          ut.OrganizationID === this._userService.organizationID &&
          ut.UserID === this._userService.userID
      );
    } else {
      userTeams = [];
    }

    // segregate the teams names that have been added newly
    const addedTeamName = this.selectedTeamNames.filter(
      (st) => !this.existingTeamName.includes(st)
    );

    // segregate the teams names that have been removed newly
    const deletedTeamName = this.existingTeamName.filter(
      (et) => !this.selectedTeamNames.includes(et)
    );

    // get the added teams
    const addedTeam = this.allTeams.filter((t) =>
      addedTeamName.includes(t.Name)
    );

    // get the removed teams
    const removedUserTeam = userTeams.filter((ut) =>
      deletedTeamName.includes(ut.Name)
    );

    // console.log("Deleted TN", deletedTeamName);
    // console.log("Removed T", removedUserTeam);

    const teamAssociation: {
      TeamID: number;
      Active: number;
      // UserTeamID: number;
    }[] = [];

    // push the removed teams into teamAssociation
    removedUserTeam.forEach((rut) => {
      teamAssociation.push({
        TeamID: rut.TeamID,
        Active: 0,
        // UserTeamID: rut.UserTeamID,
      });
    });

    // push the added teams into teamAssociation
    addedTeam.forEach((rut) => {
      teamAssociation.push({
        TeamID: rut.TeamID,
        Active: 1,
        // UserTeamID: 0,
      });
    });

    console.log("Team Association", teamAssociation);

    return teamAssociation;
  }

  searchUserIntegration() {
    const email: string = this.inviteUserForm.form.controls.email.value;
    this._userService
      .searchUserIntegrationAPI(email)
      .then(() => {
       if(this._userService.existingInActiveUser){
        
        this.firstName = this._userService.existingInActiveUser.FirstName;
        this.lastName = this._userService.existingInActiveUser.LastName;
        this.middleName = this._userService.existingInActiveUser.MiddleName;
        this.suffix = this._userService.existingInActiveUser.Suffix;
        this.userEmail = this._userService.existingInActiveUser.Email;
        this.phone = this._userService.existingInActiveUser.Phone;
        this.existingUserSelected = true;
       }
               
      })
      .catch((err: HttpErrorResponse) =>{
        this.firstName = null;
        this.lastName = null;
        this.middleName = null;
        this.suffix = null;
        this.userEmail = null;
        this.phone = null;
        this.existingUserSelected = false;
      }
       
      );
  }
}
