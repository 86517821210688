import {
  ISystemSettingsGetAPIResponse,
  ISiteConfig,
} from "../models/interfaces/system-settings-get-api.model";
import { Role } from "../models/role.model";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service"
import { TimezoneService } from '../services/timezone.service';

class SettingMap {
  POISyllable = { Name: "SoundexSyllable", Category: "POIChecking" };
  SmartSearchRowCount = {
    Name: "SmartSearchLimit",
    Category: "SmartSearch",
  };
  SiteTimeZone = { Name: "SiteTimeZone", Category: "SiteTimeZone" };
}

export class WriteSiteSettingsOperation {
  private _selectedSiteID: number;
  private _selectedSiteSettings: ISiteConfig[];
  protected timezoneService: TimezoneService; 
  constructor(
    private _settingsAPIResponse: ISystemSettingsGetAPIResponse,
    private _poiSyllableCount: number,
    private _poiNameCount: number,
    private _poiArticleCount: number,
    private _poiSuffixCount: number,
    private _smartSearchRowCount: number,
    private _selectedTimeZone: string,
    private _selectedRoles: Role[],
    private _passCheckInThreshold: number,
    private _isParkingEnabled: number,
    private _isTemporaryBadgeEnabled:number,
    private _isPurgeEnabled:number,
    private isPurgeCount: number,
    private _isCheckinPhotoEnabled: number,
    private _isCameraEquipmentEnabled: number,
    private _isCallInPassEnabled: number,
    private _dateFormat: string,
    private _timeFormat: string,
    private _phoneFormat:string,
    private _siteService: SiteService,
    private _notificationService: NotificationService,
    private isDefaultStartTime: string,
    private isDefaultEndTime: string,
    private _isCountryEnabled:number,
    private isReasonForVisit: number,
    private isSiteType: string,
    private _isCloudPrintEnabled:number,
    private _cloudPrintServer: string,
    private isTemplateID: number,
    private isCarTemplateID: number,
    private isCloudTemplateID: number,
    private isCarCloudTemplateID: number,
    private isArchivalCount: number
  ) {
    this._selectedSiteID = this._siteService.selectedSiteID;
    this._selectedSiteSettings = this._settingsAPIResponse.SystemSettings;
  }

  public async save() {
    this._formatPOISyllable();
    this._formatPOINameCount();
    this._formatPOIArticleCount();
    this._formatPOISuffixCount();
    this._formatSmartSearchRowCount();
    this._formatSiteTimeZone();
    this._formatAvailableOrgAdminRoles();
    this._formatDateFormat();
    this._formatTimeFormat();
    this._formatPhoneFormat();
    this._formatParkingEnabled();
    this._formatPassCheckInThreshold();
    this._formatTemporaryBadgeEnabled();
    this._formatPurgeEnabled();
    this._formatPurgeCount();
    this._formatCheckinPassEnabled();
    this._formatCameraEquipmentEnabled();
    this._formatCallinPassEnabled();
    this._formatDefaultStartTime();
    this._formatDefaultEndTime();
    this._formatDefaultReasonForVisit();
    this._formatSiteType();
    this._formatCloudPrintEnabled();
    this._formatCloudPrintServer();
    this._formatTemplateName();
    this._formatCarTemplateName();
    this._formatCloudTemplateName();
    this._formatCarCloudTemplateName();
    this._formatCountryEnabled();
    this._formatArchivalCount();

    try {
      await this._siteService.createUpdateSiteSettingsAPI(
        this._selectedSiteSettings
      );

      this._siteService
        .initializeSiteSettings()
        .then(() => {})
        .catch((err: HttpErrorResponse) => {
          console.error(err);
        });
        this._notificationService.success("Site settings updated successfully!");
    } catch (err) {
      console.error(err);
    }
  }

  private _checkExistingSiteSetting(
    name: string,
    category: string,
    isSingleValued: boolean,
    siteID?: number,
    value?: string
  ): ISiteConfig {
    if (siteID === undefined)
      return this._selectedSiteSettings.find(
        (ss) => ss.Name === name && ss.Category === category
      );
    else if (
      (siteID !== undefined && value === undefined) ||
      (siteID !== undefined && value !== undefined && isSingleValued)
    )
      return this._selectedSiteSettings.find(
        (ss) =>
          ss.Name === name && ss.Category === category && ss.SiteID === siteID
      );
    else if (siteID !== undefined && value !== undefined && !isSingleValued)
      return this._selectedSiteSettings.find(
        (ss) =>
          ss.Name === name &&
          ss.Category === category &&
          ss.SiteID === siteID &&
          ss.Value === value
      );
  }

  private _formatPOISyllable() {
    const settingName = "SoundexSyllable";
    const settingCategory = "POIChecking";

    const value = this._poiSyllableCount.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPOINameCount() {
    const settingName = "NameCount";
    const settingCategory = "POIChecking";

    const value = this._poiNameCount.toString();
 
    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPOIArticleCount() {
    const settingName = "ArticleCount";
    const settingCategory = "POIChecking";

    const value = this._poiArticleCount.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPOISuffixCount() {
    const settingName = "SuffixCount";
    const settingCategory = "POIChecking";

    const value = this._poiSuffixCount.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatSmartSearchRowCount() {
    const settingName = "SmartSearchLimit";
    const settingCategory = "SmartSearch";

    const value = this._smartSearchRowCount.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatSiteTimeZone() {
    const settingName = "SiteTimeZone";
    const settingCategory = "SiteTimeZone";

    const value = this._selectedTimeZone;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatAvailableOrgAdminRoles() {
    const settingName = "OrganizationAdminRole";
    const settingCategory = "OrganizationAdminRole";

    let deletedRoles = this._selectedSiteSettings.filter(
      (s) =>
        s.Category === settingCategory &&
        s.Name === settingName &&
        !this._selectedRoles.map((sr) => sr.RoleID).includes(+s.Value)
    );
    this._selectedRoles.forEach((sr) => {
      this._upsertSiteSettingsValue(
        settingName,
        settingCategory,
        sr.RoleID.toString(),
        1,
        false,
        this._selectedSiteID
      );
    });

    deletedRoles.forEach((sr) => {
      this._upsertSiteSettingsValue(
        settingName,
        settingCategory,
        sr.Value,
        0,
        false,
        this._selectedSiteID
      );
    });
  }

  private _upsertSiteSettingsValue(
    name: string,
    category: string,
    value: string,
    active: number,
    isSingleValued: boolean,
    siteID?: number
  ) {
    let settingRecord = this._checkExistingSiteSetting(
      name,
      category,
      isSingleValued,
      siteID,
      value
    );

    const systemSettingsID =
      settingRecord === undefined ? 0 : settingRecord.SystemSettingsID;

    const siteSettings: ISiteConfig = {
      SystemSettingsID: systemSettingsID,
      SiteID: siteID,
      Name: name,
      Category: category,
      Value: value,
      Active: active,
    };

    if (systemSettingsID !== 0) {
      const index = this._selectedSiteSettings.findIndex(
        (s) => s.SystemSettingsID === systemSettingsID
      );

      this._selectedSiteSettings[index] = siteSettings;
    } else {
      this._selectedSiteSettings.push(siteSettings);
    }
  }

  private _formatDateFormat() {
    const settingName = "DateFormat";
    const settingCategory = "Date";

    const value = this._dateFormat;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatTimeFormat() {
    const settingName = "TimeFormat";
    const settingCategory = "Time";

    const value = this._timeFormat;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPhoneFormat() {
    const settingName = "Phone";
    const settingCategory = "Info";

    const value = this._phoneFormat;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatParkingEnabled() {
    const settingName = "ParkingEnabled";
    const settingCategory = "Parking";

    const value = this._isParkingEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatTemporaryBadgeEnabled() {
    const settingName = "TemporaryBadgeEnabled";
    const settingCategory = "Badge";

    const value = this._isTemporaryBadgeEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatCheckinPassEnabled() {
    const settingName = "Pass";
    const settingCategory = "CameraEquipment";

    const value = this._isCameraEquipmentEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatCameraEquipmentEnabled() {
    const settingName = "PassTransaction";
    const settingCategory = "CheckInPhoto";

    const value = this._isCheckinPhotoEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatCallinPassEnabled() {
    const settingName = "CallInPassEnabled";
    const settingCategory = "CallInPass";

    const value = this._isCallInPassEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPurgeEnabled() {
    const settingName = "EnableDelete";
    const settingCategory = "Pass";

    const value = this._isPurgeEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPurgeCount() {
    const settingName = "DeleteThreshold";
    const settingCategory = "Pass";

    const value = this.isPurgeCount.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatPassCheckInThreshold() {
    const settingName = "CheckInThreshold";
    const settingCategory = "Pass";

    const value = this._passCheckInThreshold.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatDefaultStartTime() {
    const settingName = "DefaultStartTime";
    const settingCategory = "SiteTimeZone";

    const value = this.isDefaultStartTime;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatDefaultEndTime() {
    const settingName = "DefaultEndTime";
    const settingCategory = "SiteTimeZone";

    const value = this.isDefaultEndTime;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatDefaultReasonForVisit() {
    const settingName = "DefaultVisitReason";
    const settingCategory = "Pass";

    //const value = this.isReasonForVisit.toString();
    const value = this.isReasonForVisit==null ? null : this.isReasonForVisit.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatSiteType() {
    const settingName = "Type";
    const settingCategory = "General";

    //const value = this.isSiteType.toString();
    const value = this.isSiteType==null ? null : this.isSiteType.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatCountryEnabled() {
    const settingName = "CountryEnabled";
    const settingCategory = "Country";

    const value = this._isCountryEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatCloudPrintEnabled() {
    const settingName = "CloudPrintingEnabled";
    const settingCategory = "CloudPrinting";

    const value = this._isCloudPrintEnabled.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatCloudPrintServer() {
    const settingName = "CloudPrintWebServer";
    const settingCategory = "CloudPrinting";

    const value = this._cloudPrintServer;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatTemplateName() {
    const settingName = "VisitorPass";
    const settingCategory = "PrintTemplateOptions";

    const value = this.isTemplateID.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatCarTemplateName() {
    const settingName = "ParkingPass";
    const settingCategory = "PrintTemplateOptions";

    const value = this.isCarTemplateID.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatCloudTemplateName() {
    const settingName = "CloudVisitorPass";
    const settingCategory = "PrintTemplateOptions";

    const value = this.isCloudTemplateID.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatCarCloudTemplateName() {
    const settingName = "CloudParkingPass";
    const settingCategory = "PrintTemplateOptions";

    const value = this.isCarCloudTemplateID.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  private _formatArchivalCount() {
    const settingName = "ArchiveThreshold";
    const settingCategory = "Pass";

    const value = this.isArchivalCount.toString();

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
}