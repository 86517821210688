export const apiPaths = {
  authorizeUser: "security/authorizeUser",
  
  // 4af2ayg7ff
  getUpdatedSmartSearch: "general/getUpdatedSmartSearch",
  verifyAuthoriserPin: "security/verifyAuthoriserPin",
  getPOIMatchList: "approval/getPOIMatchList",
  getPartyPassFromPass: "pass/getPartyPassFromPass",
  getPassCheckinPhoto:  "pass/getPassCheckinPhoto",
  getPassTemplateCategories: "pass/getPassTemplateCategories",
  getPassTemplateFromCategory: "pass/getPassTemplateFromCategory",
  TriggerPOICheckinApproval: "approval/TriggerPOICheckinApproval",
  getCombinedLimitRequest: "approval/getCombinedLimitRequest",
  combinedLimitRequestCreate: "approval/combinedLimitRequestCreate",
  getPOIApprovalRequest: "approval/getPOIApprovalRequest",
  combinedLimitRequestUpdate: "approval/combinedLimitRequestUpdate",
  getApprovalRequest: "approval/getApprovalRequest",
  POIApprovalStatusUpdate: "approval/POIApprovalStatusUpdate",
  getSelfRegAndAccessRole: "approval/getSelfRegAndAccessRole",
  getApprovalGroupType: "approval/getApprovalGroupType",
  getApprovalGroupBySiteID: "approval/getApprovalGroupBySiteID",
  cancelOrgLimitRequest: "approval/cancelOrgLimitRequest",
  setUserWidgetConfig: "widget/setUserWidgetConfig",
  getUserWidgetConfig: "widget/getUserWidgetConfig",
  filterWidgetAPI: "widget/filterWidgetAPI",
  getSiteLabels: "general/getSiteLabels",
  getSiteList:"general/getSiteList",
  getSiteListByRole:"general/getSiteListByRole",
  setUserDefaultSite: "general/setUserDefaultSite",
  createUserActionHistory: "user/createUserActionHistory",
  getMultiSiteSmartSearch: "general/getMultiSiteSmartSearch",
  passTemplateCreate: "pass/passTemplateCreate",
  passTemplateUpdate: "pass/passTemplateUpdate",
  getPassTemplate: "pass/getPassTemplate",
  getPassTemplateV2: "pass/getPassTemplateV2",
  templateCategoryCreateUpdate: "orgteam/templateCategoryCreateUpdate",
  getTemplateCategory: "orgteam/getTemplateCategory",
  teamCreateUpdate: "orgteam/teamCreateUpdate",
  userTeamCreateUpdate: "orgteam/userTeamCreateUpdate",
  getTeam: "orgteam/getTeam",
  getOrganizationFunctionDesign: "orgteam/getOrganizationFunctionDesign",
  getUserTeam: "orgteam/getUserTeam",
  getTemplateCategoryByUserOrg: "orgteam/getTemplateCategoryByUserOrg",
  getEndDateFromTemplate: "pass/getEndDateFromTemplate",
  getCurrentSystemTime: "general/getCurrentSystemTime",
  getPrintTemplate: "pass/getPrintTemplate",
  getUserRoleRequest: "user/getUserRoleRequest",
  getSystemSettings: "general/getSystemSettings",
  setSystemSettings: "general/setSystemSettings",
  searchUserToInvite: "user/searchUserToInvite",
  submitUserRoleRequest: "user/submitUserRoleRequest",
  submitUserRoleRequestBySelf: "user/submitUserRoleRequestBySelf",
  updateUserRequestStatus: "user/updateUserRequestStatus",
  cancelUserRoleRequest: "user/cancelUserRoleRequest",
  getFunctionVerificationDetails: "approval/getFunctionVerificationDetails",
  getActiveEntryPointGroup: "master-ep/getActiveEntryPointGroup",  

  setHolidayAPIForYear: "general/setHolidaysForYear",
  setNonWorkingDays: "general/setNonWorkingDays",
  holidays: "general/holidays",
  isAcknowledgementNeeded: "general/isAcknowledgementNeeded",
  getPolicyAcknowledgementUserSettings: "general/getPolicyAcknowledgementUserSettings",
  policyAcknowledgementUserSettingsUpdate: "general/policyAcknowledgementUserSettingsUpdate",

  deactivateUser: "user/deactivateUser",
  deactivateSiteUser: "user/deactivateSiteUser",
  userStatusUpdate: "user/userStatusUpdate",
  createUpdateDomain: "general/createUpdateDomain",
  getApprovalDetails: "user/getApprovalDetails",
  getUserAccessDetails: "user/getUserAccessDetails",
  getLimitRequestDetails: "approval/getLimitRequestDetails",
  checkApprovalRequestExists: "approval/checkApprovalRequestExists",
  getPOIMatchesByPassID: "user/getPOIMatchesByPassID",

  getOrganizationSelfReg: "master-others/getOrganizationSelfReg",
  userCreateRequest: "user/userCreateRequest",
  checkUserRequetExist: "user/checkUserRequetExist",
  removeOrgAccess: "user/removeOrgAccess",
  userSearchTypeCreateUpdate: "user/userSearchTypeCreateUpdate",

  checkUserCanApprove: "user/checkUserCanApprove",
  getAdminListByOrg: "user/getAdminListByOrg",
  userSiteListToAuthorize: "security/userSiteListToAuthorize",
  checkExternalUserActiveBadge: "security/checkExternalUserActiveBadge",
  checkUserAliasEmail: "security/checkUserAliasEmail",

  getEntryPointById: "master-ep/getEntryPointById",
  checkCloudPrintEnabled: "master-ep/checkCloudPrintEnabled",
  cloudPrintProcess: "pass/cloudPrintProcess",
  entryPointCreate: "master-ep/entryPointCreate",
  getEntryPointForEntryPointGroup: "master-ep/getEntryPointForEntryPointGroup",
  entryPointUpdate: "master-ep/entryPointUpdate",
  getParkingLotLimitWithDate: "master-parking/getParkingLotLimitWithDate",
  getEntryPointGroupLimitWithDate: "master-ep/getEntryPointGroupLimitWithDate",
 

  getPrintTemplateName:"pass/getPrintTemplateName",
  //getPrinterList: "pass/getPrinterList",
  listCountry: "pass/listCountry",
  getPassDetails: "pass/getPassDetails",
  getPassDetailsByID: "pass/getPassDetailsByID",
  checkInApproval: "pass/checkInApproval",
  getPartyPass: "pass/getPartyPass",
  redeemPass: "pass/redeemPass",
  checkIsIgnoreLocks: "pass/checkIsIgnoreLocks",
  sendPassEmailToVisitor: "pass/sendPassEmailToVisitor",
  getPartyPassCreatedCount: "pass/getPartyPassCreatedCount",
  getPassFormsBySiteID: "pass/getPassFormsBySiteID",
  tempPassFormCreate: "pass/tempPassFormCreate",

  // uas86ct23k
  getBuilding: "master-others/getBuilding",
  getBuidlingFloorbyBuildingID: "master-others/getBuidlingFloorbyBuildingID",
  entryPointGroupCreate: "master-ep/entryPointGroupCreate",
  getEntryPointGroup: "master-ep/getEntryPointGroup",
  entryPointGroupUpdate: "master-ep/entryPointGroupUpdate",
  getEntryPoint: "master-ep/getEntryPoint",
  //entryPointCreate: "master-ep/entryPointCreate",
  //entryPointUpdate: "master-ep/entryPointUpdate",
  getOrganization: "master-others/getOrganization",
  getPickList: "master-others/getPickList",
  passCreate: "pass/passCreate",
  getPass: "pass/getPass",
  passUpdate: "pass/passUpdate",
  partyCreate: "pass/partyCreate",
  getRoleScreenDesign: "security/getRoleScreenDesign",
  getSearchFilter: "general/getSearchFilter",
  userSearchFilterCreateUpdate: "user/userSearchFilterCreateUpdate",
  getMultiSort: "general/getUserSearchSorting", // used for user multi-sort

  // xf7c4x6td3
  getWidgets: "widget/getWidgets",
  getAvailableParkingAnalytics: "master-parking/getAvailableParkingAnalytics",
  getUserWidgets: "widget/getUserWidgets",
  userWidgetCreateUpdate: "widget/userWidgetCreateUpdate",
  removeUserWidget: "widget/removeUserWidget",
  entryPointZoneCreate: "master-ep/entryPointZoneCreate",
  getEntryPointZone: "master-ep/getEntryPointZone",
  entryPointZoneUpdate: "master-ep/entryPointZoneUpdate",
  getOrganizationLimits: "master-others/getOrganizationLimits",
  organizationUpdate: "master-others/organizationUpdate",
  getParkingLimitAnalytics: "master-parking/getParkingLimitAnalytics",
  getAvailableEntryPointZoneLimitWithDate:
    "master-ep/getAvailableEntryPointZoneLimitWithDate",
  getParkingLotGroup: "master-parking/getParkingLotGroup",
  parkingLotGroupCreate: "master-parking/parkingLotGroupCreate",
  parkingLotGroupUpdate: "master-parking/parkingLotGroupUpdate",
  getParkingLot: "master-parking/getParkingLot",
  parkingLotCreate: "master-parking/parkingLotCreate",
  parkingLotUpdate: "master-parking/parkingLotUpdate",
  passTransactionCreate: "pass/passTransactionCreate",
  getPassTransactionDetails: "pass/getPassTransactionDetails",
  roleCreate: "security/roleCreate",
  roleUpdate: "security/roleUpdate",
  getRoleFunction: "security/getRoleFunction",
  getUser: "user/getUser",
  userCreate: "user/userCreate",
  userUpdate: "user/userUpdate",
  getSymmetryCardInfo: "user/getSymmetryCardInfo",
  getPassTransactionStructuredDetails:
    "pass/getPassTransactionStructuredDetails",
  getApprovalGroupById: "approval/getApprovalGroup",
  approvalGroupUpsert: "approval/approvalGroupUpsert",
  approvalGroupMember: "approval/approvalGroupMember",
  getApprovalTypeConfig: "approval/getApprovalRequestConfig",
  approvalRequestConfigUpsert: "approval/approvalRequestConfigUpsert ",
  approvalTypeUpdate: "approval/approvalTypeUpdate",
  approvalTypeSiteSettingsUpdate: "approval/approvalTypeSiteSettingsUpdate",
  getUserPhysicalMgmtInfo:"user/getUserPhysicalMgmtInfo",
  updateUserOrganization:"user/updateUserOrganization",
  searchUserIntegration:"user/searchUserIntegration",
  userIntegrationAction:"user/userIntegrationAction",
  getUserTaskStatus:"general/getUserTaskStatus",
  cancelUserTask: "general/cancelUserTask",
  passCreateNew: "pass/passCreate",
  tempPassCreate: "pass/tempPassCreate",
  tempPassUpdate: "pass/tempPassUpdate",
  submitDuplicateCheck: "pass/submitDuplicateCheck",
  getDuplicatePasses: "pass/getDuplicatePasses",
  partyCreateNew: "pass/partyCreate",
  getUserRoleFunctionBySite: "security/getUserRoleFunctionBySite",
  getIntegrations: "user/getIntegrations",
  getUserIntegrationInfo: "user/getUserIntegrationInfo",
  checkWebServer: "pass/checkCloudPrintServer",
  // cloudPrintServer: "pass/printPassPdf",
  getLanguageList: "general/getLanguageList",
  getSystemSettingsByLanguage: "general/getSystemSettingsByLanguage",
  getTransalatedMessage:"general/getTranslatedMessage",
  getTagMessageList: "general/getTagMessageList",
  getEmailTemplate: "general/getEmailTemplate",
  getPassCreateDetails: "pass/getPassCreateDetails",
  getPassCheckInDetails: "pass/getPassCheckInDetails",
  getApprovalGroupUseCount: "approval/getApprovalGroupUseCount",
  setIntegrations: "user/setUserIntegrations",
  getApprovalGroupUserList : "approval/getApprovalGroupUserList"
};