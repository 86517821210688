import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { ParkingLot } from "../../../models/parking-lot.model";
import { ParkingLotGroupService } from "../../../services/parking-lot-group.service";
import { ParkingLotGroup } from "../../../models/parking-lot-group.model";
import { HttpErrorResponse } from "@angular/common/http";
import { ParkingLotService } from "../../../services/parking-lot.service";
import { DialogService } from "../../../services/dialog.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-update-parking",
  templateUrl: "./update-parking-modal.component.html",
  styleUrls: ["./update-parking-modal.component.css"],
})
export class UpdateParkingModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("editForm") editForm: NgForm;
  protected step = 0;
  protected parkingLot: ParkingLot;
  protected entryPoints: Array<EntryPoint>;
  protected parkingLotGroups: Array<ParkingLotGroup>;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  // nameFormControl = new FormControl("", [Validators.required]);
  // parkingLotGroupFormControl = new FormControl("", [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingLot,
    protected dialogRef: MatDialogRef<UpdateParkingModalComponent>,
    protected entryPointService: EntryPointService,
    protected parkingLotGroupService: ParkingLotGroupService,
    protected parkingLotService: ParkingLotService,
    protected notificationService: NotificationService,
    protected dialogService: DialogService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {

    //labels by message service
if (localStorage.getItem('EditParkingLotLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('EditParkingLotLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Update Parking Lot Modal",
      "ParkingLot"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('EditParkingLotLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.parkingLot = { ...this.data };
    console.log(this.parkingLot.DefaultEntryPoint);
    if (this.entryPointService.getEntryPoints().length != 0) {
      this.entryPoints = [...this.entryPointService.getEntryPoints()];
    } else {
      this.entryPointService
        .parseGetEndpointAPI()
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
    if (this.parkingLotGroupService.getParkingLotGroups().length != 0) {
      this.parkingLotGroups = [
        ...this.parkingLotGroupService.getParkingLotGroups(),
      ];
    } else {
      this.parkingLotGroupService
        .getParkingLotGroupAPI()
        .then(() => {
          this.parkingLotGroups = [
            ...this.parkingLotGroupService.getParkingLotGroups(),
          ];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      this.messageService.screenLabels["EditParkingLotModalTitle"] ? this.messageService.screenLabels["EditParkingLotModalTitle"] : 'Edit Parking Lot',
      this.editForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  // Method to Update Parking Lot
  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        this.messageService.screenLabels["UpdateParkingLotConfirmLabel"] ? this.messageService.screenLabels["UpdateParkingLotConfirmLabel"] : "Confirm Parking Lot Update",
        this.messageService.screenLabels["UpdateParkingLotConfirmMsg"] ? this.messageService.screenLabels["UpdateParkingLotConfirmMsg"] : "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        const name = this.parkingLot.Name.trim() === this.parkingLot.Name ? this.parkingLot.Name : this.parkingLot.Name.trim();
        this.parkingLot.Name = name ;
        if (confirm) {
          if (!this.parkingLot.DriveOnRequired) {
            this.parkingLot.DefaultEntryPoint = null;
          }
          this.parkingLotService
            .updateParkingLotAPI(this.parkingLot)
            .then(() => {
              this.notificationService.success(
                this.messageService.screenLabels["ParkingLotSuccessMsg"] ? this.messageService.screenLabels["ParkingLotSuccessMsg"] : "Parking Lot Updated Successfully."
              );
            });
        }
      });

    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.parkingLot.Name || !this.parkingLot.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
}
