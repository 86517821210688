import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatExpansionPanel,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatSelectionListChange,
} from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { GridApi } from "ag-grid-community";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import moment from "moment";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { UserService } from "src/app/services/user.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { NgForm } from "@angular/forms";
import { SearchService } from "src/app/services/search.service";
import { DialogService } from "src/app/services/dialog.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { EntryPointGroupService } from "src/app/services/entry-point-group.service";
import { EntryPointService } from "src/app/services/entry-point.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-create-limit-request-modal",
  templateUrl: "./create-limit-request-modal.component.html",
  styleUrls: ["./create-limit-request-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreateLimitRequestModalComponent implements OnInit {
  @ViewChild("filterForm") filterForm: NgForm;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected step = 0;

  protected isOrganizationDataLoaded = false;
  protected isParkingLotDataLoaded = false;
  protected isEntryPointGroupLoaded = false;
  protected isSubmitting: boolean = false;
  protected startDate;
  protected endDate;
  protected minDate = this.utilService.getCurrentSiteDT();
  protected maxDate = new Date(
    this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 6 * 7)
  );

  protected organizations: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  }> = [];

  protected parkingLots: Array<any>;
  protected entryPointGroups: Array<any>;

  protected selectedOrgID: number;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private limitService: LimitRequestService,
    private entryPointGroupService: EntryPointGroupService,
    private entryPointService: EntryPointService,
    protected dialogRef: MatDialogRef<CreateLimitRequestModalComponent>,
    private dialogService: DialogService,
    private siteService: SiteService,
    private utilService: UtilityService,
    protected messageService: MessageService
  ) {}

  async ngOnInit() {
    //labels by message service
    if (localStorage.getItem('CreateLimitRequestLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateLimitRequestLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Limit Request Modal",
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateLimitRequestLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    // get assigned organizations
    await this.loadOrganizationData();
    // get entry point zones
    this.loadEntryPointGroupData();

    // get parking lots
    this.loadParkingLotData();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }


  // invoked when a request is raised
  protected onRequestRaised() {
   // const organizationID = this.filterForm.value.organization;
    const organizationID = this.selectedOrgID;
    const startDate = moment(this.filterForm.value.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.filterForm.value.endDate).format("YYYY-MM-DD");
    const parkingLotID =
      this.filterForm.value.parkingLot === undefined
        ? null
        : this.filterForm.value.parkingLot;
    const entryPointGroupID = this.filterForm.value.entryPointGroup;
    const extraLimit = this.filterForm.value.extraLimit;
    const reason = this.filterForm.value.reason;

    if (extraLimit % 1 != 0) {
      const cancelLabel = this.screenLabels["InvalidLimitMsg"] ? this.screenLabels["InvalidLimitMsg"] : "Invalid Limit Request";
      const cancelMsg = this.screenLabels["SpecifyWholeNumberMsg"] ? this.screenLabels["SpecifyWholeNumberMsg"] : "Please specify a whole number";
      this.dialogService.openAlertDialogDialog(
        cancelLabel,
        cancelMsg
      );

      return false;
    }

    this.isSubmitting = true;

    this.onNoClick();

    this.limitService
      .createLimitRequestAPI(
        organizationID,
        startDate,
        endDate,
        extraLimit,
        entryPointGroupID,
        parkingLotID,
        reason
      )
      .then(() => {
        // reset the form

        this.filterForm.reset();
        this.filterForm.resetForm();

        this.isSubmitting = false;

        // show success message
        this.notificationService.success(this.limitService.message);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  // invoked when the organization data is loaded
  private async loadOrganizationData() {
    const promise = this.userService.getUserAssociationAPI(
      this.userService.userID,
      this.siteService.selectedSiteID
    );

    await promise;

    promise.then(() => {
      this.organizations = [
        ...this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            OrganizationID: o.OrganizationID,
            Name: o.Name,
            StartDate: o.Startdate,
            EndDate: o.EndDate,
          })),
      ];
      this.selectedOrgID = this.organizations[0].OrganizationID;
      this.isOrganizationDataLoaded = true;
     
    });
  }

  onOrganizationChange(event) {
    this.selectedOrgID = event.value;
  //to load parking & epg wrt to org
    this.loadParkingLotData();
    this.loadEntryPointGroupData();
  }
  // invoked to load parking lot data
  protected loadParkingLotData() {
    this.isParkingLotDataLoaded = false;
    this.organizationService
      .getOrganizationByIDAPI(this.selectedOrgID)
      .then(() => {
        const organization = this.organizationService.getOrganizationByIDResponse()[0];
        const tempParking = organization.OrganizationParkingLimitArray.filter(
          (op) => !op.Regulated && op.Active
        );
        
        const parkings = [];
        tempParking.forEach((item) => {
          // parking.push(item.ParkingLotArray);
          item.ParkingLotArray.forEach((p) => parkings.push(p));
        });

        this.parkingLots = parkings;
        if (this.parkingLots.length > 0) this.isParkingLotDataLoaded = true;
      });
    // .catch((err: HttpErrorResponse) =>
    //   this.notificationService.failure(err.message)
    // );
  }

  // invoked to load Entry Point Zone data
  protected loadEntryPointGroupData() {
    this.isEntryPointGroupLoaded = false;

    this.entryPointGroupService
      .getAllEntryPointGroupsAPI(this.selectedOrgID)
      .then(() => {
        const tempEntryPointGroups = this.entryPointGroupService.getEntryPointGroupResponse();

        this.entryPointService
          .parseGetEndpointAPI()
          .then(() => {
            const entryPoints = this.entryPointService
              .getEntryPoints()
              .filter((ep) => ep.EntryPointZoneID !== null)
              .map((o) => o.EntryPointID);

            const filteredEntryPointGroups = tempEntryPointGroups
              .filter((epg) => epg.EntryPointGroupActive && entryPoints.includes(epg.EntryPointID))
              .map((o) => ({
                EntryPointGroupID: o.EntryPointGroupID,
                EntryPointGrpName: o.EntryPointGrpName,
              }));

            //remove duplicates
            const uniqueArray = filteredEntryPointGroups.filter(
              (obj, index) => {
                const _thing = JSON.stringify(obj);
                return (
                  index ===
                  filteredEntryPointGroups.findIndex((obj) => {
                    return JSON.stringify(obj) === _thing;
                  })
                );
              }
            );
            this.entryPointGroups = uniqueArray;
          })
          .catch();

        this.isEntryPointGroupLoaded = true;
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
