import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogService } from '../services/dialog.service';
import { CreateApprovalMemberComponent } from './modals/create-approval-member/create-approval-member.component';
import { GridApi } from 'ag-grid-community';
import { SearchService } from '../services/search.service';
import { ApprovalGroup } from '../models/approval-group-model';
import { ApprovalGroupService } from '../services/approval-group.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { ApprovalGroupMember } from '../models/approval-group-member-model';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-approval-member',
  templateUrl: './approval-member.component.html',
  styleUrls: ['./approval-member.component.css']
})
export class ApprovalMemberComponent implements OnInit {

  public gridApi: GridApi;
  protected isRowSelected: boolean;
  private paginationPageSize;
  protected selectedApprovalMember: any;
  protected selectedApprovalGroupName: any;
  protected approvalGroupMember: ApprovalGroupMember;
  protected rowData: any[];
  protected selectedApprovalGroupId: number;
  protected approvalGroups: ApprovalGroup[];
  protected approvalGroupMemColumnDefs:any ;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  capacityMessage: string;

  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected searchService: SearchService,
    protected approvalGroupService: ApprovalGroupService,
    protected notificationService: NotificationService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
  }

  ngOnInit(): void {
    this.searchService.hideSearch();
    this.approvalGroupMember = new ApprovalGroupMember(
      null,
      null
    );

    //labels by message service
if (localStorage.getItem('ApprovalGroupMemberLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ApprovalGroupMemberLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "approval Group Member",
      "approvalGroupMember"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ApprovalGroupMemberLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.searchService
      .smartSearchMultiSiteAPI(null, "Approval Group")
      .then(() => {
        const data = this.searchService.searchRecords;
        this.approvalGroups = data;
        this.selectedApprovalGroupId = this.approvalGroups[0] ? this.approvalGroups[0].ApprovalGroupID : null;
        this.approvalGroupService
          .getApprovalGroupById(this.selectedApprovalGroupId)
          .then((res) => {
            this.rowData = [];
            const approvalGroupList = <ApprovalGroup>res;
            approvalGroupList.Users.forEach(ele => {
              if (ele.Active === 1) {
                this.rowData.push(ele)
              }
            });
            this.setColumnDef();
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      });

    this.approvalGroupService.approvalMemberDataChanged.subscribe((e) => {
      this.approvalGroupService
        .getApprovalGroupById(this.selectedApprovalGroupId)
        .then((res) => {
          this.rowData = [];
          const approvalGroupList = <ApprovalGroup>res;
          approvalGroupList.Users.forEach(ele => {
            if (ele.Active === 1) {
              this.rowData.push(ele)
            }
          });
          this.setColumnDef();
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    })
  }

  setColumnDef() {
    this.approvalGroupMemColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 30,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true
      },
      {
        headerName:this.screenLabels["FirstName"]
        ? this.screenLabels["FirstName"]
        : "First Name", 
        field: "FirstName",
        hide: false,
        resizable: true,
        sortable: true,
        width: 500
      },
      {
        headerName: this.screenLabels["LastName"]
        ? this.screenLabels["LastName"]
        : "Last Name",
        field: "LastName",
        hide: false,
        sortable: true,
        resizable: true,
        width: 500
      },
      {
        headerName:this.screenLabels["Email"]
        ? this.screenLabels["Email"]
        : "Email", 
        field: "Email",
        hide: false,
        sortable: true,
        resizable: true,
        width: 500
      }
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  onCreateNewApprovalMember() {
    this.dialog.open(CreateApprovalMemberComponent, {
      disableClose: true,
      data: {
        approvalGroupId: this.selectedApprovalGroupId,
        name: this.selectedApprovalGroupName,
        currentUsers: this.rowData
      },
    });
  }


  onGridReady(params) {
    this.gridApi = params.api;
  }

  onSelectionChanged($event) {
    const selectedRows: any[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedApprovalMember = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }

  onFirstDataRendered() {
    this.gridApi.sizeColumnsToFit();
  }

  onApprovalGroupSelectionChange(event) {
    this.approvalGroupService
      .getApprovalGroupById(this.selectedApprovalGroupId)
      .then((res) => {
        this.rowData = [];
        const approvalGroupList = <ApprovalGroup>res;
        approvalGroupList.Users.forEach(ele => {
          if (ele.Active === 1) {
            this.rowData.push(ele)
          }
        });
        this.selectedApprovalGroupName = approvalGroupList.Name;
        this.setColumnDef();
      })

  }

  removeApprovalGroupMember() {
    //var firstName = this.selectedApprovalMember.FirstName;
    //var lastName = this.selectedApprovalMember.LastName;
    var message: string = this.screenLabels["DeleteApprovalGroupConfirmMsg"] ? this.screenLabels["DeleteApprovalGroupConfirmMsg"] : "Are you sure you want to Delete Approval member <<id1>> <<id2>> ?";
    this.capacityMessage = message.replace("<<id1>>", this.selectedApprovalMember.FirstName).replace("<<id2>>",this.selectedApprovalMember.LastName); 
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteApprovalMemberConfirmLabel"] ? this.screenLabels["DeleteApprovalMemberConfirmLabel"] : "Delete Approval Group Member", this.capacityMessage)     
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          const users = [];
          users.push({
            "AGUserID": this.selectedApprovalMember.UserID,
            "Active": 0
          });
          this.approvalGroupMember.Users = users;
          this.approvalGroupMember.ApprovalGroupID = this.selectedApprovalGroupId;
          this.approvalGroupService.removeApprovalGroupMember(this.approvalGroupMember).then((res) => {
              this.onApprovalGroupSelectionChange(this.selectedApprovalGroupId);
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        }
      });
  }


}
