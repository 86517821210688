import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpHeaders, HttpResponse, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UserService } from "./user.service";
import { NumberFilter } from "ag-grid-community";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
import { UserActionsService } from "./user-actions.service";
import { Organization } from "../users/modals/create-user/create-user-modal.component";
import { OrganizationModel } from "../models/organization-model";
import internal from "assert";
import { UtilityService } from "./utility.service";
import { NotificationService } from "./notification.service";
import { ActiveDescendantKeyManager } from "@angular/cdk/a11y";
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  private organizationModel: OrganizationModel[];
  public message: string;
  public createdOrganizationID: number;
  public includeNonWorkingDays: number;
  @Output() organizationLimitUpdated: EventEmitter<boolean>;
  // public parkingLimitAPIResponse: Array<{
  //   OrganizationID: number;
  //   OrganizationName: string;
  //   OrganizationLimit: number;
  //   ParkingLotGroupID: number;
  //   ParkingLotGroupName: string;
  //   ParkingLotID: number;
  //   ParkingLotName: string;
  //   AllocatedParkingCount: number;
  //   BalanceParkingCount: number;
  // }> = [];
  public parkingLimitAPIResponse: any = [];
  public entryPointZoneLimitAPIResponse: Array<{
    // EntryPointGroupID: number;
    // EntryPointGroupName: string;
    // MinAllocatedZoneLimit: number;
    RemainingEntries: number;
    // EntryPointZoneLimitID: number;
    // EntryPointZoneName: string;
    Regulated: boolean;
  }> = [];
  private organizationByIDAPIResponse: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    SelfRegAllowed: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
    OrganizationParkingLimitArray: Array<{
      OrganizationParkingLimitID: number;
      Limit: number;
      ParkingLotArray: Array<{
        ParkingLotName: string;
        ParkingLotID: number;
      }>;

      ParkingLotGroupID: number;
      ParkingLotGroupName: string;
      Active: boolean;
      Regulated: boolean;
    }>;
    unlinkedParkingLotGroupArray: Array<{
      ParkingLotGroupID: number;
      ParkingLotGroupName: string;
      Regulated: boolean;
    }>;
    OrganizationEntryPointLimit: Array<{
      OrgEntryPointZoneLimitID: number;
      Limit: number;
      EntryPoint: Array<{
        EntryPointZoneId: number;
        EntryPointID: number;
        Description: string;
      }>;

      EntryPointZoneID: number;
      EntryPointZoneName: string;
      Active: boolean;
      Regulated: boolean;
    }>;
    unlinkedEntryPointZone: Array<{
      EntryPointZoneID: number;
      EntryPointZoneName: string;
      Regulated: boolean;
    }>;
  }> = [];

  public organizations: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  }> = [];
  //EmployeeData: OrganizationModel;
  //dialogData: any;
  details: any;
  modelDetails: OrganizationModel;



  constructor(
    private http: HttpClient,
    private userService: UserService,
    private siteService: SiteService,
    private userActionsService: UserActionsService,
    private utilService: UtilityService,
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {
    this.organizationModel = [];
    this.organizationLimitUpdated = new EventEmitter<boolean>();
  }

  //get reason for visit from pick list table
  public getOrganizationAPI(): Promise<any> {
    const apiURL = environment.getAPI("getOrganization");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
      }[]
    >((resolve, reject) => {
      this.http
        .post(apiURL, { SiteID: this.siteService.selectedSiteID }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.organizations = success.body;
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getOrganizations() {
    return [...this.organizations];
  }

  public getOrganizationByIDAPI(organizationID: number): Promise<any> {
    const apiURL = environment.getAPI("getOrganizationLimits");
    // const apiURL = "https://xjj35lj6lc.execute-api.us-west-2.amazonaws.com/dev/getOrganizationLimits";
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<any[]>((resolve, reject) => {
      this.http
        .post(apiURL, { OrganizationID: organizationID, SiteID: this.siteService.selectedSiteID }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.organizationByIDAPIResponse = success.body;
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getOrganizationByIDResponse() {
    return [...this.organizationByIDAPIResponse];
  }

  public updateOrganizationLimitAPI(
    OrganizationID: number,
    EndDate: string,
    UserID: number,
    SiteID: number,
    StartDate: string,
    Internal: number,
    SelfRegAllow: number,
    Active: number,
    Name: string,
    parkingLimitData: {
      OrganizationParkingLimitID: number;
      Limit: number;
      ParkingLotGroupID: number;
      ParkingLotGroupName: string;
      Active: boolean;
      Regulated: boolean;
    }[],
    entryPointZoneLimitData: {
      OrgEntryPointZoneLimitID: number;
      Limit: number;
      EntryPointZoneID: number;
      EntryPointZoneName: string;
      Active: boolean;
      Regulated: boolean;
    }[],
    adminsToAdd: Array<any> = [],
    adminsToRemove: Array<any> = []
  ): Promise<any> {
    const apiURL = environment.getAPI("organizationUpdate");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const parkingGroupDetails = parkingLimitData.map((lt) => ({
      OrganizationParkingLimitID: lt.OrganizationParkingLimitID,
      ParkingLotGroupID: lt.ParkingLotGroupID,
      Regulated: lt.Regulated,
      Limit: lt.Limit === null ? 0 : lt.Limit,
      Active: lt.Active,
    }));
    const entryPointZoneDetails = entryPointZoneLimitData.map((lt) => ({
      OrgEntryPointZoneLimitID: lt.OrgEntryPointZoneLimitID,
      EntryPointZoneID: lt.EntryPointZoneID,
      Regulated: lt.Regulated,
      Limit: lt.Limit,
      Active: lt.Active,
    }));
    const body = {
      OrganizationID: OrganizationID,
      EndDate: EndDate,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
      StartDate: StartDate,
      Internal: Internal,
      SelfRegAllowed: SelfRegAllow,
      Active: Active,
      Name: Name,
      ParkingLotGroup: parkingGroupDetails,
      EntryPointZone: entryPointZoneDetails,
      addAdmin: adminsToAdd,
      removeAdmin: adminsToRemove
    };

    const promise = new Promise<any[]>((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.message = success.body.Message;
            this.organizationLimitUpdated.emit(true);
            resolve(success.body);
          },
          (err: HttpErrorResponse) => {
            // error

            console.log(err);
            console.log(err.error.Message);
            this.notificationService.failure(err.error.Message);
            reject(err);
          }
        );
    });
    return promise;

    // return null;
  }

  public getParkingLimitAnalyticsAPI(
    StartDate: string,
    EndDate: string,
    OrganizationID: number,
    PassID: number,
    ParkingLotID: number,
    IncludeNonWorkingDays: number
  ): Promise<any> {
    const apiURL = environment.getAPI("getParkingLimitAnalytics");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      StartDate: StartDate,
      EndDate: EndDate,
      OrganizationID: OrganizationID,
      SiteID: this.siteService.selectedSiteID,
      PassID: PassID,
      ParkingLotID: ParkingLotID,
      IncludeNonWorkingDays: IncludeNonWorkingDays
    };

    const promise = new Promise<any[]>((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.parkingLimitAPIResponse = success.body;
            this.message = success.body.Message;
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;

    // return null;
  }

  public getParkingLimitAnalyticsByID(
    organizationId: number,
    parkingLlotID: number
  ) {
    return this.parkingLimitAPIResponse.filter(
      (f) =>
        f.OrganizationID == organizationId &&
        f.ParkingLotID == parkingLlotID &&
        f.BalanceParkingCount > 0
    ).length > 0
      ? true
      : false;
  }

  public getEntryPointZoneLimitAnalyticsAPI(
    StartDate: string,
    EndDate: string,
    OrganizationID: number,
    PassID: number,
    EntryPointGroupID: number,
    IncludeNonWorkingDay: number,
    SiteID: number
  ): Promise<any> {
    const apiURL = environment.getAPI(
      "getAvailableEntryPointZoneLimitWithDate"
    );

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    if (IncludeNonWorkingDay) {
      this.includeNonWorkingDays = 1
    } else {
      this.includeNonWorkingDays = 0
    }
    const startDate = this.datePipe.transform(StartDate, "yyyy-MM-dd");
    const endDate = this.datePipe.transform(EndDate, "yyyy-MM-dd");
    const body = {
      StartDate: startDate,
      EndDate: endDate,
      OrganizationID: OrganizationID,
      PassID: PassID,
      EntryPointGroupID: EntryPointGroupID,
      IncludeNonWorkingDays: this.includeNonWorkingDays,
      SiteID: SiteID
    };

    const promise = new Promise<any[]>((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.entryPointZoneLimitAPIResponse = success.body;
            this.message = success.body.Message;
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;

    // return null;
  }

  // the method is used to log organization change into user action table
  public async logOrganizationChange(
    oldOrganizationID: number,
    newOrganizationID: number
  ) {
    const userOrganizations = this.userService.userOrganizations;

    const oldOrganizationName = oldOrganizationID
      ? userOrganizations.find((uo) => uo.OrganizationID === oldOrganizationID)
        .Name
      : null;
    const newOrganizationName = newOrganizationID
      ? userOrganizations.find((uo) => uo.OrganizationID === newOrganizationID)
        .Name
      : null;

    await this.userActionsService.recordUserActionAPI(
      "Organization Change",
      `Changed From: ${oldOrganizationName} (${oldOrganizationID}) To: ${newOrganizationName} (${newOrganizationID})`
    );
  }

  // Method to add a Parking Lot
  public createOrganizationLimit(
    organizationModel: OrganizationModel,
    parkingLimitData: {
      OrganizationParkingLimitID: number;
      Limit: number;
      ParkingLotGroupID: number;
      ParkingLotGroupName: string;
      Active: boolean;
      Regulated: boolean;
    }[],
    entryPointZoneLimitData: {
      OrgEntryPointZoneLimitID: number;
      Limit: number;
      EntryPointZoneID: number;
      EntryPointZoneName: string;
      Active: boolean;
      Regulated: boolean;
    }[]): number {
    let temp: OrganizationModel = { ...organizationModel };
    let OrganizationID: number = this.organizationModel.length + 1;
    temp.OrganizationID = OrganizationID;
    temp.CreatedDate = this.utilService.getCurrentSiteDT();
    this.organizationModel.push(temp);

    this.createOrganizationLimitAPI(
      organizationModel,
      parkingLimitData,
      entryPointZoneLimitData
    ).then(() => {

      this.organizationLimitUpdated.emit(true);
    });
    return this.createdOrganizationID;
  }

  public createOrganizationLimitAPI(
    organizationModel: OrganizationModel,
    parkingLimitData: {
      OrganizationParkingLimitID: number;
      Limit: number;
      ParkingLotGroupID: number;
      ParkingLotGroupName: string;
      Active: boolean;
      Regulated: boolean;
    }[],
    entryPointZoneLimitData: {
      OrgEntryPointZoneLimitID: number;
      Limit: number;
      EntryPointZoneID: number;
      EntryPointZoneName: string;
      Active: boolean;
      Regulated: boolean;
    }[]
  ): Promise<any> {
    const apiURL = environment.getAPI("organizationUpdate");

    const parkingGroupDetails = parkingLimitData.map((lt) => ({
      OrganizationParkingLimitID: lt.OrganizationParkingLimitID,
      ParkingLotGroupID: lt.ParkingLotGroupID,
      Regulated: lt.Regulated,
      Limit: lt.Limit === null ? 0 :lt.Limit,
      Active: lt.Active,
    }));
    const entryPointZoneDetails = entryPointZoneLimitData.map((lt) => ({
      OrgEntryPointZoneLimitID: lt.OrgEntryPointZoneLimitID,
      EntryPointZoneID: lt.EntryPointZoneID,
      Regulated: lt.Regulated,
      Limit: lt.Limit,
      Active: lt.Active,
    }));
    const postBody = {
      OrganizationID: organizationModel.OrganizationID,
      EndDate: organizationModel.EndDate,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
      StartDate: organizationModel.StartDate,
      Internal: organizationModel.Internal ? 1 : 0,
      SelfRegAllowed: organizationModel.SelfRegAllowed ? 1 : 0,
      Active: organizationModel.Active,
      Name: organizationModel.Name,
      ParkingLotGroup: parkingGroupDetails,
      EntryPointZone: entryPointZoneDetails,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.organizationLimitUpdated.emit(true);
            this.createdOrganizationID = success.body.OrganizationID;
            this.notificationService.success(success.body.Message);
            resolve(success.body);
          },

          (err: HttpErrorResponse) => {
            // error

            console.log(err);
            console.log(err.error.Message);
            this.notificationService.failure(err.error.Message);
            reject(err);
          }
        );
    });
    return promise;
  }
  public getAdminListByOrg(organizationID: number) {
    const apiURL = environment.getAPI("getAdminListByOrg");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      OrganizationID: organizationID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
  
}
