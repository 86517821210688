import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSelectChange,
  MatAutocompleteTrigger,
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
  MatSelect,
} from "@angular/material";
import { FormControl, NgForm } from "@angular/forms";
import { EntryPointGroup } from "../../../models/entry-point-group.model";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointService } from "../../../services/entry-point.service";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointGroupService } from "../../../services/entry-point-group.service";
import { DialogService } from "../../../services/dialog.service";
import { MatExpansionPanel } from "@angular/material";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "../../../services/utility.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-update-entry-point-group",
  templateUrl: "./update-entry-point-group.component.html",
  styleUrls: ["./update-entry-point-group.component.css"],
})
export class UpdateEntryPointGroupComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  @ViewChild("groupInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild("editForm") editForm: NgForm;
  protected items: Array<EntryPoint> = [];
  protected groupNames: Array<EntryPoint> = [];
  protected basket: Array<EntryPoint> = ([] = []);
  protected originalBasket: Array<EntryPoint> = [];
  protected deletedBasket: Array<EntryPoint> = [];
  public groupControl = new FormControl();
  public filteredGroups: Observable<String[]>;
  public selectedGroupNames: Array<String> = ([] = []);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  protected entryPointGroup: EntryPointGroup;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isSaveButtonDisabled: boolean = false;
  protected entryGroupNamesValue: Array<EntryPoint> = [];
  protected entryGroupNames: Array<EntryPoint> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPointGroup,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<UpdateEntryPointGroupComponent>,
    private notificationService: NotificationService,
    private entryPointService: EntryPointService,
    private entryPointGrpService: EntryPointGroupService,
    private dialogService: DialogService,
    private siteService: SiteService,
    private utilService: UtilityService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {
    this.entryPointGroup = { ...data };
  }

  ngOnInit(): void {

//labels by message service
if (localStorage.getItem('UpdateEntryPointGroupLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('UpdateEntryPointGroupLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Edit Entry Point Group Modal",
      "EntryPointGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('UpdateEntryPointGroupLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.entryPointService
      .parseGetEndpointAPI(this.entryPointGroup.EntryPointGroupID)
      .then(() => {
        this.entryPointGrpService
          .getAllEntryPointGroupsAPI()
          .then(() => {
            const mappedEntryPoint = this.entryPointGrpService.getGroupMappings(
              this.data.EntryPointGroupID
            ).EntryPoints;
            if (mappedEntryPoint) {
              this.basket = mappedEntryPoint;
              this.basket.forEach(element => { this.selectedGroupNames.push(element.Description) });
            }
            this.entryPointService.getEntryPointForEntryPointGroup().subscribe(
              (entryGroupNames: Array<EntryPoint>) => {
                this.entryGroupNamesValue = entryGroupNames;
                this.entryGroupNames = this.entryGroupNamesValue.filter(entryItem => 
                  !this.basket.some(basketItem => basketItem.Description === entryItem.Description))
              },
              (error) => {
                console.error('Failed to load entry points', error);
              }
            );

              if(this.entryGroupNamesValue.length > 0){
              this.entryGroupNames = this.entryGroupNamesValue.filter(entryItem => 
                !this.basket.some(basketItem => basketItem.Description === entryItem.Description)
            );
              }
            this.items = this.entryPointService
              .getEntryPoints()
              .filter((x) => !this.basket.includes(x));

            this.originalBasket = [...this.basket];
            return;
            this.originalBasket = [];
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
            console.error(err);
          });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
      this.filteredGroups = this.groupControl.valueChanges.pipe(
        map((entryName) => this.filterOnValueChange(entryName))
      );
  }

  private filterOnValueChange(entryName: string): String[] {
    let result: String[] = [];
    let allEntryLessSelected = this.entryGroupNames.filter(
      (user) => this.basket.indexOf(user) < 0
    );
    if (entryName) {
      result = this.filterEntry(allEntryLessSelected, entryName);
    } else {
      result = allEntryLessSelected.map((entry) => entry.Description);
    }
    return result;
  }

  private filterEntry(
    entryList: Array<EntryPoint> = [],
    entryName: String
  ): String[] {
    let filteredEntrysList: Array<EntryPoint> = [];
    const filterValue = entryName.toLowerCase();
    let entryMatchingEntryName = entryList.filter(
      (entry) => entry.Description.toLowerCase().indexOf(filterValue) === 0
    );
    if (entryMatchingEntryName.length) {
      filteredEntrysList = entryMatchingEntryName;
    } else {
      filteredEntrysList = entryList;
    }
    return filteredEntrysList.map((entry) => entry.Description);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Edit Entry Point Group',
      this.editForm
    );
  }

  private selectEntry(entryName) {
    let foundEntry = this.entryGroupNames.filter((item) => item.Description == entryName);
    if (foundEntry.length) {
      this.basket.push(foundEntry[0]);
      this.selectedGroupNames.push(foundEntry[0].Description);
    }
  }
  public entrySelected(event: MatAutocompleteSelectedEvent): void {
    this.selectEntry(event.option.value);
    this.resetInputs();
  }
  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }
  public addEntry(event: MatChipInputEvent): void {
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (this.matAutocomplete.isOpen) {
      return;
    }
    const value = event.value;
    if ((value || "").trim()) {
      this.selectEntry(value.trim());
    }
    this.resetInputs();
  }

  public removeGroup(role): void {
    const index = this.basket.indexOf(role);
    const templateIndex = this.entryGroupNames.indexOf(role);
    if (index >= 0) {
      this.basket.splice(index, 1);
        if (templateIndex < 0) {this.entryGroupNames.push(role);
        }
      this.resetInputs();
    }
}

  private resetInputs() {
    // clear input element
    this.userInput.nativeElement.value = "";
    // clear control value
    this.groupControl.setValue(null);
  }
  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }
  onEditEntryPointgroup() {
    this.dialog.open(UpdateEntryPointGroupComponent, {
      disableClose: true,
      data: this.data,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["EntryPointGroupConfirmLabel"]? this.screenLabels["EntryPointGroupConfirmLabel"]:"Confirm Entry Point Group",
        this.screenLabels["EntryPointGroupConfirmMsg"]? this.screenLabels["EntryPointGroupConfirmMsg"]:"Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
        const mappedEntryPoint = this.entryPointGrpService.getGroupMappings(
          this.data.EntryPointGroupID
        ).EntryPoints;
        if (mappedEntryPoint) this.originalBasket = mappedEntryPoint;
        if (this.basket) {
          let difference = this.originalBasket.filter(
            (x) => !this.basket.includes(x)
          );

          // unlink entry point association for the old entry points
          difference.forEach((item) => {
            item.Active = 0;
          });
          this.basket = this.basket.concat(difference);
        }

        this.entryPointGrpService
          .updateEntryPointGroupAPI(
            this.entryPointGroup.EntryPointGroupID,
            this.entryPointGroup.EntryPointGrpName,
            true,
            this.basket
          )
          .then(() => {
            this.notificationService.success(this.entryPointGrpService.message);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
        }
        this.onNoClick();
      });
  }

  // Code for Drag and Drop
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  checkInput(){
    const trimmedGrpName: string = this.entryPointGroup.EntryPointGrpName.trim();
    this.isSaveButtonDisabled = trimmedGrpName === '';
  }
}
