import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSelectChange,
  MatExpansionPanel,
} from "@angular/material";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPoint } from "../models/entry-point.model";
import { RoleMapService } from "../services/role-map.service";
import { DialogService } from "../services/dialog.service";
import { UserService } from "../services/user.service";
import { OrganizationService } from "../services/organization.service";
import { NotificationService } from "../services/notification.service";
import { PassService } from "../services/pass.service";
import { DatePipe } from "@angular/common";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-view-user-modal",
  templateUrl: "./create-badge-holder-pass-modal.component.html",
  styleUrls: ["./create-badge-holder-pass-modal.component.css"],
})
export class CreateBadgeHolderPassModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  protected step = 0;
  protected userKey: any = "";
  protected userSelected: boolean = false;
  private apiBody: any = {};
  protected symmetryUsers: Array<{
    SymmetryCardHolderRawID: number;
    EmployeeRef: string;
    FirstName: string;
    LastName: string;
  }> = [];

  protected FirstName: string;
  protected LastName: string;
  protected Name: string;
  protected EmployeeRef: number = 0;
  protected appLanguage;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateBadgeHolderPassModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected organizationService: OrganizationService,
    private userService: UserService,
    private dialogService: DialogService,
    protected passService: PassService,
    private datePipe: DatePipe,
    protected siteService:SiteService,
    private utilService: UtilityService,
    protected messageService: MessageService,
  ) {
    this.userSelected = false;
    document.addEventListener("keydown", (e) => {
      if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
        e.stopImmediatePropagation();
      }
    });
  }

  ngOnInit(): void {

    //labels by message service
if (localStorage.getItem('CreateTemporaryBadgeLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateTemporaryBadgeLabels'));
  this.messageService.loadScreenLabelsByLanguage(this.appLanguage);
} else {
  this.messageService.getLabelLanguageObs(
    "Create Temporary Badge Modal",
      "Pass"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateTemporaryBadgeLabels', JSON.stringify(this.appLanguage));
    this.messageService.loadScreenLabelsByLanguage(this.appLanguage);
  })
}

   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // private loadSymmetryUsers() {
  //   this.userService
  //     .getSymmetryCardInfoApi()
  //     .then(() => {
  //       this.symmetryUsers = this.userService.getSymmetryCardInfo();
  //     })
  //     .catch((err: HttpErrorResponse) =>
  //       this.notificationService.failure(err.message)
  //     );
  // }

  onSelectionChange(event: MatSelectChange) {
    const employeeRef = event.value;
    const user = this.symmetryUsers.filter(
      (su) => su.EmployeeRef === employeeRef
    )[0];
    this.FirstName = user.FirstName;
    this.LastName = user.LastName;
    this.Name = this.FirstName + " " + this.LastName;
    this.EmployeeRef = employeeRef;
    this.userSelected = true;
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  searchSymmetryUsers() {
    this.userService
      .getSymmetryCardInfoApi(this.userKey)
      .then(() => {
        this.symmetryUsers = this.userService.getSymmetryCardInfo();
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
    this.FirstName = "";
    this.LastName = "";
    this.userSelected = false;
    this.EmployeeRef = 0;
  }

  onSaveChanges() {
    this.apiBody.EmployeeRef = this.EmployeeRef;
    this.apiBody.VisitorFirstName =
      this.FirstName === "" ? null : this.FirstName;
    this.apiBody.VisitorLastName =
      this.LastName == undefined ? null : this.LastName;
    this.apiBody.StartTime = this.datePipe.transform(
      this.utilService.getCurrentSiteDT(),
      "yyyy-MM-dd"
    );
    this.apiBody.EndTime = this.datePipe.transform(
      this.utilService.getCurrentSiteDT(),
      "yyyy-MM-dd"
    );
    this.apiBody.EstArrivalTime = this.datePipe.transform(
      this.utilService.getCurrentSiteDT(),
      "yyyy-MM-dd HH:mm"
    );
    var end = this.utilService.getCurrentSiteDT();
    end.setHours(23, 59, 59, 999);

    this.apiBody.EstDepartureTime = this.datePipe.transform(
      end,
      "yyyy-MM-dd HH:mm"
    );
    this.apiBody.OrganizationID = this.userService.organizationID;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.Active = true;
    this.apiBody.UserID = this.userService.userID;
    this.apiBody.VisitorNameSuffix = null;
    this.apiBody.VisitorCompany = null;
    this.apiBody.VisitorEmail = null;
    this.apiBody.VisitorPhone = null;
    this.apiBody.VisitReason = null;
    this.apiBody.IncludeNonWorkingdays = null;
    this.apiBody.ExpectedEntryPoint = null;
    this.apiBody.EntryPointGroupID = null;
    this.apiBody.EntryType = null;
    this.apiBody.NotesInternal = null;
    this.apiBody.NotesPass = null;
    this.apiBody.ParkingLotID = null;
    this.apiBody.DestinationBldg = null;
    this.apiBody.DestinationBlgFlr = null;
    this.apiBody.DestinationRoom = null;
    this.apiBody.HostFirstName = null;
    this.apiBody.HostMiddleName = null;
    this.apiBody.HostLastName = null;
    this.apiBody.HostNameSuffix = null;
    this.apiBody.HostCompany = null;
    this.apiBody.HostPhone = null;
    this.apiBody.HostEmail = null;
    this.apiBody.HostTWID = null;
    this.apiBody.Operator = null;
    this.apiBody.PhotoPass = null;
    this.apiBody.Active = true;
    this.apiBody.CopyFromPassID = null;
    this.apiBody.PartyID = null;
    this.apiBody.VisitorEmail = null;
    this.passService
      .createPassAPI([this.apiBody])
      .then(() => {
        this.notificationService.success(this.passService.message);
        console.log(this.passService.passID);
        //this.checkInGuest(this.passService.passID);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );

    this.onNoClick();
  }

  public checkInGuest(passID: number): void {
    this.passService
      .passTransactionCreateAPI(passID, "CheckIn")
      .then(() => {
        this.notificationService.success(this.passService.message);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }
}
