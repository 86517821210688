import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PassService } from "../services/pass.service";
import { HttpErrorResponse, HttpClient, HttpResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { ParkingLotService } from "../services/parking-lot.service";
import { DialogService } from "../services/dialog.service";
import { UserService } from "../services/user.service";
import { AuthService } from "../services/auth.service";
import { MatExpansionPanel } from "@angular/material";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import { WebcamComponent } from "../webcam/webcam.component"
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import moment from "moment-timezone";
import { ReadSiteSettingsOperations } from "../site-settings/read-site-settings-operations";
import { cloneDeep } from "lodash";
import { ISystemSettingsGetAPIResponse } from "../models/interfaces/system-settings-get-api.model";
import { Time } from "@angular/common";
import { TimezoneService } from "../services/timezone.service";
import { MessageService } from "../services/message.service";
import { EntryPointService } from "../services/entry-point.service";
import { SearchService } from "../services/search.service";
export interface DialogData { }

@Component({
  selector: "app-viewpass-modal",
  templateUrl: "./desktop/viewpass-modal.component.desktop.html",
  styleUrls: ["./desktop/viewpass-modal.component.desktop.css"],
})
export abstract class ViewpassModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  @ViewChild("hiddenBtn") private hiddenBtn: ElementRef;
  @ViewChild("printHtmlContainer") private printHtmlContainer: ElementRef;

  protected isCheckInDisabled: boolean = false;
  public isApprovalTriggred: boolean = false;
  public canCheckOut: boolean = false;
  protected isPartyInfoAvailable = false;
  protected passFromSameParty: Array<any>;
  protected parkingLotName: string;
  protected disableParkingPass: boolean = true;
  public myAngularxQrCode: string = null;
  public qrdata: string = null;
  public elementType: "img" | "url" | "canvas" | "svg" = null;
  public level: "L" | "M" | "Q" | "H";
  public width: number;
  public entryPointGroups: any;
  public entryPointGroupName: string;
  public passStatus: string;
  public expired: boolean;
  public printPassDisabled: boolean = false;
  public today: Date = this.utilService.getCurrentSiteDT();
  dateNow: Date = this.utilService.getCurrentSiteDT();

  showView: boolean = false;
  timeIssued: Date;
  protected step = 0;
  protected callInUser: boolean = false;
  protected passInfoLoaded: boolean = false;
  protected isCorrectEntryPointGroup: boolean = undefined;
  protected createdDate: string;
  protected modifiedDate: string;
  protected thresholdExceeded: boolean;
  protected thresholdTime: string;
  protected passCheckInThreshold: number;
  protected partyPrintPassDisabled: boolean = false;
  public templateID: number;
  private _settingsAPIResponse: ISystemSettingsGetAPIResponse;

  //webcam
  public showWebcam = false;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 300 },
    height: { ideal: 400 }
  };

  protected walkOn: boolean = false;
  protected driveOn: boolean = false;
  public printPassDisabledTwo: boolean = false;
  protected checkOutDisabled: boolean = false;
  protected passCheckInValue: number = 0;
  public disableOnCloudPrintAPI: boolean = false;
  protected printedPassID: number = 0;
  protected printerList: any;
  protected list: Array<any>;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected details: any;
  protected currentTime: any;
  protected passThresholdTime: any;
  protected passExpires: any;
  protected showCheckInProgress: boolean = false;
  protected scheduledPass: boolean = false;
  protected passResponse: any;
  protected passtriggeredAlready: boolean = false;
  protected passWithFormsApproved: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewpassModalComponent>,
    private passService: PassService,
    private notificationService: NotificationService,
    private parkingLotService: ParkingLotService,
    private dialogService: DialogService,
    public userService: UserService,
    protected authService: AuthService,
    private entryPointGroupService: EntryPointGroupService,
    private entryPointService: EntryPointService,
    private timezoneService: TimezoneService,
    public siteService: SiteService,
    private utilService: UtilityService,
    private renderer: Renderer2,
    protected messageService: MessageService,
    protected searchService: SearchService

  ) {
    this.details = this.data[0];
    if (this.details.ApprovalCheckInPreCheck === null)
      this.showCheckInProgress = true;
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
      },
      false
    );

    this.checkPartyPass();
    this.passService.getPrimaryPhotoFiles(this.details.guestId);
    if (
      this.details.parkingLotID != undefined &&
      this.details.parkingLotID != null &&
      this.details.parkingLotID > 0
    ) {
      this.disableParkingPass = false;
      if (this.parkingLotService.getParkingLot().length > 0) {
        this.parkingLotName =
          this.parkingLotService
            .getParkingLot()
            .filter((p) => p.ParkingLotID === this.details.parkingLotID).length > 0
            ? this.parkingLotService
              .getParkingLot()
              .filter((p) => p.ParkingLotID === this.details.parkingLotID)[0].Name
            : "";
      } else {
        this.parkingLotService.getParkingLotAPI().then(() => {
          this.parkingLotName =
            this.parkingLotService
              .getParkingLot()
              .filter((p) => p.ParkingLotID === this.details.parkingLotID).length > 0
              ? this.parkingLotService
                .getParkingLot()
                .filter((p) => p.ParkingLotID === this.details.parkingLotID)[0].Name
              : "";
        });
      }
    } else {
      this.disableParkingPass = true;
    }
    this.elementType = "img";
    this.level = "M";
    this.width = 58;

    this.createdDate = this.utilService
      .getSiteTime(this.details.createdDate)
      .toString();
    this.modifiedDate = this.utilService
      .getSiteTime(this.details.modifiedDate)
      .toString();
  }
  ngOnInit() {
    const splitword = "visitor";
    const resultt = splitword.split(',');
    this.callInUser = this.authService.validCaller;
    this.passService.image = '';
    this._loadSiteSettings();

    //labels by message service
    if (localStorage.getItem('ViewPassLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewPassLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Pass Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewPassLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    var expDate = this.details.expirationDate;
    WebcamUtil.getAvailableVideoInputs();
    if (this.details.passStatus == "Expired") this.expired = true;
    else this.expired = false;
    if (this.details.passStatus == "Scheduled") this.scheduledPass = true;
    else this.scheduledPass = false;
    this.passService.isPassCheckedInAPI(this.details.guestId);

    this.isCorrectEntryPointGroup = this.details.isValidEntryPointGroup;

    // this.passService.recieveData().subscribe(data => {
    //   this.printerList = data;
    // })
    this.entryPointService
      .parseGetEndpointAPI()
      .then(() => {
        // this.isEntryPointLoading = false;
        const entryPointID = this.searchService.getEntryPointID();
        let entryPointStationValue = Number(sessionStorage.getItem("EntryPointStationID"));
        let EPStationID = sessionStorage.getItem("EntryPointStationID");
        if (EPStationID !== entryPointStationValue.toString()) {
          sessionStorage.setItem("EntryPointStationID", entryPointStationValue.toString());
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  private async _loadSiteSettings() {
    try {
      this._settingsAPIResponse = await this.siteService.getSiteSettingsAPI();
      const readSiteSettingsOperator = new ReadSiteSettingsOperations(
        cloneDeep(this._settingsAPIResponse)
      );
      await this.timezoneService.setSiteUTCOffset();
      this.passCheckInThreshold = readSiteSettingsOperator.getPassCheckInThreshold();
      this.currentTime = moment(new Date(this.timezoneService.currentTime));
      this.passThresholdTime = moment(this.details.EstArrivalTime).subtract(this.passCheckInThreshold, "minute").format("LLLL");
      var timeString = this.details.expirationDate + " " + this.details.guestDeparture;
      this.passExpires = moment(timeString).format("LLLL");
      if (moment(this.currentTime).isAfter(this.passThresholdTime) && moment(this.currentTime).isBefore(this.passExpires)) {
        if (this.isCorrectEntryPointGroup === true) {
          this.printPassDisabled = false;
          this.partyPrintPassDisabled = this.printPassDisabled;
        } else {
          this.printPassDisabled = true;
        }
        this.thresholdExceeded = false;
      } else {
        this.thresholdExceeded = true;
        this.printPassDisabled = true;
        this.partyPrintPassDisabled = this.printPassDisabled;
      }
    } catch (err) {
      console.error(err);
    }
  }

  onNoClick() {
    this.dialogRef.close();
    this.passService.viewPassOpen = false;
    this.passService.photoURL = "";
    this.passService.existingPhotoUrl = "";
    this.passService.existingImage = false;
  }
  public toggleWebcam(guest) {
    this.printPassDisabled = true;
    this.passService.getPrimaryPhotoFiles(guest.PassID);
    setTimeout(() => {
      this.dialog.open(WebcamComponent, {
        data: {
          siteId: this.siteService.selectedSiteID,
          passId: guest.PassID,
          guestFirstName: guest.VisitorFirstName,
          guestLastName: guest.VisitorLastName,
          guestDestination: this.details.guestDestination,
          hostName: this.details.hostName,
          guestDate: this.details.guestDate,
          guestTime: this.details.guestArrival
        },
        backdropClass: "backdropBackground",
      }).afterClosed().subscribe(res => {
        if (this.passFromSameParty.length > 1) {
          setTimeout(() => {
            this.printPassDisabled = false;
            for (var pass of this.passFromSameParty) {
              pass.printPassDisabled = false;
              pass.printCarParkingPassDisabled = false;
            }
          }, 4000);
        }
      });  
    },1800);
    
  }
  // invoked when an individual guest pass is checked in
  public checkInGuest(passID: number): void {
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, "CheckIn")
          .then(() => {
            this.passService.passCheckedIn = true;
            this.passCheckInValue = passID;
            this.notificationService.success(this.passService.message);
            //to notify that pass is checked in
            this.passService.redeemPassAPI(passID).then(() => {
            })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  // invoked when a pass is being print
  public printPassTransaction(passID: number): void {
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, "Print")
          .then(() => {
            //this.notificationService.success(this.passService.message);
            this.passService.redeemPassAPI(passID).then(() => {
            })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  // invoked when a parking pass is being print
  public printParkingPassTransaction(passID: number): void {
    // this.isCheckInDisabled = true;
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, "ParkingPrint")
          .then(() => {
            //this.notificationService.success(this.passService.message);
            this.passService.redeemPassAPI(passID).then(() => {
            })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  // the method is invoked when a pass is checked ot
  public checkOutGuest(passID: number): void {
    // this.isCheckInDisabled = true;
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, "CheckOut")
          .then(() => {
            this.notificationService.success(this.passService.message);
            this.passService.redeemPassAPI(passID).then(() => {
            })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  // the method is invoked when a pass approval is triggered
  public triggerApprovalPassTransaction(passID: number): void {
    // this.isCheckInDisabled = true;
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, "CheckInApprovalRequest")
          .then(() => {
            this.notificationService.success(this.passService.message);
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  get arrivalTime() {
    return (this.timeIssued = this.utilService.getCurrentSiteDT());
  }

  checkPartyPass() {
    if ((this.details.partyID === null) || (this.details.partyID === 0))
      this.getPassesForSinglePass();
    else
      this.getPassesFromPartyPass();
  }
  //get passes for single pass
  async getPassesForSinglePass() {
    this.passFromSameParty = this.data;
    this.passInfoLoaded = true;
    this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
    const passEntryPointGroupID = +this.passFromSameParty[0].entryPointGroupID;
    for (var i = 0; i < this.entryPointGroups.length; i++) {
      if (
        this.entryPointGroups[i].EntryPointGroupID == passEntryPointGroupID
      ) {
        this.entryPointGroupName = this.entryPointGroups[i].EntryPointGrpName;
      }
    }
    this.isCorrectEntryPointGroup = this.details.isValidEntryPointGroup;

    var qrCode = this.details.guestId.toString();
    this.qrdata = "#" + qrCode + "#";
    this.isPartyInfoAvailable = this.passFromSameParty !== undefined && this.passFromSameParty.length === 1 ? true : false;
    if (this.isPartyInfoAvailable) {
      for (var pass of this.passFromSameParty) {
        pass.ApprovalCheckInStatus = pass.ApprovalCheckInPreCheck;
        pass.ApprovalCreateStatus = pass.ApprovalPassCreateStatus;
        pass.disableTriggerBtn = false;
        this.passtriggeredAlready = pass.disableTriggerBtn;
        if (this.details.passStatus === "Active")
          this.enablePassButtons(pass, true);
         //for changing width of the modal based on forms column present
        if (pass.PassForms.length > 0 && pass.ApprovalCreateStatus === 'Approved' && (pass.ApprovalCheckInStatus == 'Approved' || pass.ApprovalCheckInStatus == 'Redeem' || pass.ApprovalCheckInStatus == 'Automatic Approval'))
          this.passWithFormsApproved = true;
        else
          this.passWithFormsApproved = false;
      }
    }
  }
  // get passes from the same party
  getPassesFromPartyPass() {
    this.passService.getPartyPassByPartyIDAPI(this.details.partyID, this.details.PassID).then(async () => {
      this.passFromSameParty = this.passService.getPartyPassResponse();
      this.passResponse = this.passService.getPartyPassResponse();
      this.passInfoLoaded = true;
      this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
      const passEntryPointGroupID = +this.passFromSameParty[0].EntryPointGroupID;
      for (var i = 0; i < this.entryPointGroups.length; i++) {
        if (
          this.entryPointGroups[i].EntryPointGroupID == passEntryPointGroupID
        ) {
          this.entryPointGroupName = this.entryPointGroups[i].EntryPointGrpName;
        }
      }
      // this.isCorrectEntryPointGroup = this.userService.entryPointGroupIDs.includes(
      //   passEntryPointGroupID
      // );

      this.isCorrectEntryPointGroup = this.details.isValidEntryPointGroup;

      var qrCode = this.details.guestId.toString();
      this.qrdata = "#" + qrCode + "#";

      if (this.passResponse.length > 0 && this.passResponse !== "Too Many Rows!!") {
        //show all partypasses
        this.isPartyInfoAvailable = this.passFromSameParty !== undefined && this.passFromSameParty.length > 0 ? true : false;
        if (this.isPartyInfoAvailable) {
          for (var pass of this.passFromSameParty) {
            pass.ApprovalCheckInStatus = pass.ApprovalCheckInPreCheck;
            pass.ApprovalCreateStatus = pass.ApprovalPassCreateStatus;
            pass.disableTriggerBtn = false;
            pass.isScheduled = pass.PassDayStatus === "Scheduled";
            this.passtriggeredAlready = pass.disableTriggerBtn;
            if (this.details.passStatus === "Active")
              this.enablePassButtons(pass);

            // if(pass.PassForms.length>0 && pass.ApprovalCreateStatus ==='Approved' && (pass.ApprovalCheckInStatus == 'Approved' || pass.ApprovalCheckInStatus == 'Redeem' || pass.ApprovalCheckInStatus == 'Automatic Approval')) 
            //   this.passWithFormsApproved = true;
            // else
            // this.passWithFormsApproved = false;
          }
        }
      }
      else if (this.passResponse == "Too Many Rows!!") {
        //show 1 pass
        this.getPassesForSinglePass();
      }
    });
  }

  async enablePassButtons(pass: any, singlepass?: boolean) {
    this.passService.checkInApprovalAPI(pass.PassID, pass.ApprovalCheckInStatus).subscribe((res: HttpResponse<any>) => {
      this.showCheckInProgress = false;
      pass.ApprovalCheckInStatus = res.body.ApprovalWorkFlowStatus;
      if (singlepass) {
        //for changing width of the modal based on forms column present
        if (pass.PassForms.length > 0 && pass.ApprovalCreateStatus === 'Approved' && (pass.ApprovalCheckInStatus == 'Approved' || pass.ApprovalCheckInStatus == 'Redeem' || pass.ApprovalCheckInStatus == 'Automatic Approval'))
          this.passWithFormsApproved = true;
        else
          this.passWithFormsApproved = false;
      }
    });

    try {
      this._settingsAPIResponse = await this.siteService.getSiteSettingsAPI();
      const readSiteSettingsOperator = new ReadSiteSettingsOperations(
        cloneDeep(this._settingsAPIResponse)
      );
      await this.timezoneService.setSiteUTCOffset();
      this.passCheckInThreshold = readSiteSettingsOperator.getPassCheckInThreshold();
      this.currentTime = moment(new Date(this.timezoneService.currentTime));
      this.passThresholdTime = moment(this.details.EstArrivalTime).subtract(this.passCheckInThreshold, "minute").format("LLLL");
      var timeString = this.details.expirationDate + " " + this.details.guestDeparture;
      this.passExpires = moment(timeString).format("LLLL");
      if (moment(this.currentTime).isAfter(this.passThresholdTime) && moment(this.currentTime).isBefore(this.passExpires)) {
        if (this.isCorrectEntryPointGroup === true) {
          pass.printPassDisabled = false;
          pass.printCarParkingPassDisabled = false;
          pass.checkDisabled = false;
          if (pass.PassID === this.passCheckInValue) {
            pass.checkOutDisabled = true;
          }
          else {
            pass.checkOutDisabled = false;
          }
        } else {
          pass.printPassDisabled = true;
          pass.printCarParkingPassDisabled = true;
          pass.checkDisabled = true;
          pass.checkOutDisabled = false;
        }
        pass.thresholdExceeded = false;
      } else {
        pass.thresholdExceeded = true;
        pass.printPassDisabled = true;
        pass.printCarParkingPassDisabled = true;
        pass.checkDisabled = true;
        pass.checkOutDisabled = false;
      }
    } catch (err) {
      console.error(err);
    }
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }
  openActionLegend() {
    const html =
      `<table width='100%'>
      <thead>
          <tr>
              <th width='100'>
              ${this.screenLabels["Action"] ? this.screenLabels["Action"] : "Action"}
              </th>
              <th>
              ${this.screenLabels["Description"] ? this.screenLabels["Description"] : "Description"}
              </th>
          </tr>
      </thead>
      <tbody>
          <tr>
              <td width='30%'><i style='vertical-align: middle; padding-bottom: 3px;'
                  class='material-icons vertical-align-middle padding-bottom-3'>directions_car</i>
                  ${this.screenLabels["Pass"] ? this.screenLabels["Pass"] : "Pass"}
              </td>
              <td>${this.screenLabels["CheckInVisitorPrint"] ? this.screenLabels["CheckInVisitorPrint"] :
        "Checks in visitor, prints drive-on and entry pass"}
              </td>
          </tr>
          <tr>
              <td width='30%'>
                      <i style='vertical-align: middle; padding-bottom: 3px;'
                          class='material-icons vertical-align-middle padding-bottom-3'>print</i>
                          ${this.screenLabels["Pass"] ? this.screenLabels["Pass"] : "Pass"}
              </td>
              <td>${this.screenLabels["CheckInVisitorPrintsEntryPass"] ?
        this.screenLabels["CheckInVisitorPrintsEntryPass"] :
        "Checks in visitor and prints entry pass"}</td>
          </tr>
          <tr>
              <td width='30%'>
                  &nbsp<img src="../../assets/icons/Asset 2.svg"></img>
                  ${this.screenLabels["CheckInBtn"] ?
        this.screenLabels["CheckInBtn"] :
        "Check In"}
              </td>
              <td>${this.screenLabels["CheckInVisitor"] ?
        this.screenLabels["CheckInVisitor"] :
        "Checks in visitor"}</td>
          </tr>
          <tr>
              <td width='30%'>
                      <i style='vertical-align: middle; padding-bottom: 3px;'
                          class='material-icons vertical-align-middle padding-bottom-3'>exit_to_app</i>
                          ${this.screenLabels["CheckOutBtn"] ?
        this.screenLabels["CheckOutBtn"] : "Check Out"}
              </td>
              <td>${this.screenLabels["CheckOutVisitor"] ?
        this.screenLabels["CheckOutVisitor"] : "Checks out visitor"}</td>
          </tr>
          <tr>
              <td width='30%'>
              <img class="request-icon" src="../../assets/icons/authorization-icon.svg"></img> 
              ${this.screenLabels["Authorization"] ? this.screenLabels["Authorization"] : "Authorization"}
              </td>
              <td>
              ${this.screenLabels["RequestCheckAuthorization"] ?
        this.screenLabels["RequestCheckAuthorization"] : "Request check-in authorization"}
              </td>
          </tr>
          <tr>
              <td width='30%'>
                      <i style='vertical-align: middle; padding-bottom: 3px;'
                          class='material-icons vertical-align-middle padding-bottom-3'>hourglass_full</i>
                          ${this.screenLabels["Pending"] ?
        this.screenLabels["Pending"] : "Pending"}
              </td>
              <td>${this.screenLabels["PassAwaitingApproval"] ?
        this.screenLabels["PassAwaitingApproval"] : "Pass awaiting entry approval"} </td>
          </tr>
      </tbody>
  </table>`;
    this.dialogService.openAlertDialogDialog(
      this.screenLabels["ActionLegend"] ?
        this.screenLabels["ActionLegend"] : "Action Legend",
      html,
      "500px",
      "277px"
    );
  }

  triggerApproval(guest) {
    this.passService.checkInApprovalAPI(guest.PassID, guest.ApprovalCheckInStatus).subscribe((res: HttpResponse<any>) => {
      guest.ApprovalCheckInStatus = res.body.ApprovalWorkFlowStatus;
      if (guest.ApprovalCheckInStatus === "Pending") {
        this.passService
          .triggerPOIApproval(guest.PassID, guest.ApprovalCheckInStatus)
          .then(() => {
            //guest.IsApprovalPending = 'Pending';
            guest.disableTriggerBtn = true;
            this.passtriggeredAlready = guest.disableTriggerBtn;
            this.notificationService.success(this.passService.message);
          })
          .catch((err: HttpErrorResponse) => {
            this.isApprovalTriggred = false;
            this.notificationService.success(err.message);
          });
      }
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  async showPrintingDialog(passID: number, tempName: string) {
    
    const entryPointID = typeof this.userService.userCheckedInEntryPoint == 'number' ? this.userService.userCheckedInEntryPoint : this.userService.userCheckedInEntryPoint.EntryPointID;
    
    this.passService.checkCloudPrintEnabledAPI(this.siteService.selectedSiteID, entryPointID).subscribe(async (res: any) => {
      if (res.body.IsCloudPrint === true) {
        const systemSettings = this._settingsAPIResponse.SystemSettings
        let passType: Array<String>;
        if (tempName === "Regular Pass") {
          this.templateID = Number(systemSettings ?
            (systemSettings.filter(ele => ele.Name === "CloudVisitorPass")).map(ele => ele.Value) : null);
          passType = ['visitor'];
        } else {
          this.templateID = Number(systemSettings ?
            (systemSettings.filter(ele => ele.Name === "CloudParkingPass")).map(ele => ele.Value) : null);
          passType = ['visitor', 'parking'];
        }
        this.passService.cloudPrintProcessAPI(
          this.siteService.selectedSiteID,
          entryPointID,
          this.userService.userID,
          passID,
          this.templateID,
          passType,
          Number(sessionStorage.getItem("EntryPointStationID")),
        )
          .then((success: HttpResponse<any>) => {
            setTimeout(() => {
              this.printPassDisabled = false;
              for (var pass of this.passFromSameParty) {
                pass.printPassDisabled = false;
                pass.printCarParkingPassDisabled = false;
              }
            }, 4000);
            this.notificationService.success(this.passService.message);
            if (this.passService.cloudPrinted === 0) {
              this.passService.passTransactionCreateAPI(passID, "CloudPrint", "Failed")
                .then(() => { }).catch((err: HttpErrorResponse) => this.notificationService.failure(err.message));
              this.regularPrint(passID, tempName)
            } else {
              this.passService.passTransactionCreateAPI(passID, "CloudPrint", "Success")
                .then(() => { }).catch((err: HttpErrorResponse) => this.notificationService.failure(err.message));
            }
          })
          .catch((err: HttpErrorResponse) => {
            setTimeout(() => {
              this.printPassDisabled = false;
              for (var pass of this.passFromSameParty) {
                pass.printPassDisabled = false;
                pass.printCarParkingPassDisabled = false;
              }
            }, 4000);
            this.passService.passTransactionCreateAPI(passID, "CloudPrint", "Error")
              .then(() => { }).catch((err: HttpErrorResponse) => this.notificationService.failure(err.message));
            this.notificationService.failure(err.error.message);
            this.regularPrint(passID, tempName)
          });
      }
      else if (res.body.IsCloudPrint === false) {
        this.regularPrint(passID, tempName);
      }
    })

  }

  async regularPrint(passID: number, tempName: string) {
    if (tempName === "Regular Pass") {
      this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
        this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "VisitorPass").map(ele => ele.Value) : null);
    } else {
      this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
        this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "ParkingPass").map(ele => ele.Value) : null);
    }
    try {
      const html = await this.passService.getPassPrintTemplateAPI(
        passID,
        this.templateID
      );
      this.renderer.setProperty(
        this.printHtmlContainer.nativeElement,
        "innerHTML",
        html
      );
      this.hiddenBtn.nativeElement.click();
      setTimeout(() => {
        this.printPassDisabled = false;
        for (var pass of this.passFromSameParty) {
          pass.printPassDisabled = false;
          pass.printCarParkingPassDisabled = false;
        }
      }, 4000);
    } catch (err) {
      setTimeout(() => {
        this.printPassDisabled = false;
        for (var pass of this.passFromSameParty) {
          pass.printPassDisabled = false;
          pass.printCarParkingPassDisabled = false;
        }
      }, 4000);
      this.dialogService.openAlertDialogDialog(
        this.screenLabels["PrintErrorMsg"] ? this.screenLabels["PrintErrorMsg"] : "Print Error",
        this.screenLabels["CouldNotShowPrintMsg"] ? this.screenLabels["CouldNotShowPrintMsg"] : "Could not show print dialog"
      );
    }
  }

  getTooltipContent(forms: any[]): string {
    return forms
      .map(
        (form) =>
          `${form.Description}: ${
            form.PassFormStatus === 'Completed' ? '✅' : '⏳'
          }`
      )
      .join('\n');
  }
}

