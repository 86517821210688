import { Injectable, Output, EventEmitter } from "@angular/core";
import {
  HttpHeaders,
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { DatePipe } from "@angular/common";
import { MultiSortService } from "./multi-sort.service";
import { SpinnerService } from "../services/spinner.service";
import { environment } from "src/environments/environment";
import { EntryPointGroupService } from "./entry-point-group.service";
import { SiteService } from "./site.service";
import { UtilityService } from "./utility.service";
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private pageName: string;
  public message: string;
  protected searchText: string;
  public searchRecords: Array<any>;
  public smartSearchPromise: Promise<any>;
  public key: string;
  private organizationID: number;
  private entryPointID: number;
  private isSearchBarHidden: boolean = false;
  private parentFieldName: string;
  private objectID: number;

  private filterData: Array<{
    UserID: number;
    SearchFilterID: number;
    UserSearchFilterID: number;
    Label: string;
    Description: string;
    EnabledByDefault: number;
    Active: boolean;
  }>;
  httpOptions: { headers; observe } = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    observe: "response",
  };

  // @Output() search: EventEmitter<string>;
  @Output() searchInitiated: EventEmitter<boolean>;
  @Output() entryPointDataChanged: EventEmitter<boolean>;

  constructor(
    private titleService: Title,
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private multiSortService: MultiSortService,
    private spinnerService: SpinnerService,
    private entryPointGroupService: EntryPointGroupService,
    private siteService: SiteService,
    private utilService: UtilityService
  ) {
    this.pageName = this.getPageName();
    this.filterData = [];
    this.searchRecords = [];

    this.searchInitiated = new EventEmitter<boolean>();
    this.entryPointDataChanged = new EventEmitter<boolean>();
    // this.multiSortService
    //   .getMultiSortAPI()
    //   .then(() => {
    //   })
    //   .catch((err: HttpErrorResponse) => {
    //     this.notificationService.failure(err.message);
    //   });

    this.entryPointDataChanged.subscribe((hasChanged: boolean) => {
      if (hasChanged) {
        this.entryPointGroupService.getAllEntryPointGroupsAPI(this.userService.organizationID).then(() => {
          const entryPointGroups = this.entryPointGroupService.entryPointGroupAPIResponse.filter(
            (group) => group.EntryPointID === this.entryPointID
          );


          this.userService.setUserEntryPointGroupIDs(
            entryPointGroups.map((epg) => epg.EntryPointGroupID)
          );
        });
      }
    });
  }

  // get DB Table Name
  private getTableName(screenName?: string): string {
    let tableName = "";

    const pageName = screenName ? screenName.toLowerCase() : this.pageName.toLowerCase();

    switch (pageName) {
      case "Pass".toLowerCase():
        tableName = "Pass";
        break;
      case "Pass Approvals".toLowerCase():
        tableName = "ApprovalRequest";
        break;
      case "Pass Create Approvals".toLowerCase():
        tableName = "ApprovalRequest";
        break;
      case "Building".toLowerCase():
        tableName = "Building";
        break;
      case "Entry Point".toLowerCase():
        tableName = "EntryPoint";
        break;
      case "User".toLowerCase():
      case "Call In User".toLowerCase():
        tableName = "User";
        break;
      case "User Org".toLowerCase():
        tableName = "User";
        break;
      case "Entry Point Group".toLowerCase():
        tableName = "EntryPointGroup";
        break;
      case "Organizations".toLowerCase():
        tableName = "Organization";
        break;
      case "Roles".toLowerCase():
        tableName = "Role";
        break;
      case "Domain".toLowerCase():
        tableName = "Domain";
        break;
      case "Parking Lot".toLowerCase():
        tableName = "ParkingLot";
        break;
      case "Parking Lot Group".toLowerCase():
        tableName = "ParkingLotGroup";
        break;
      case "Entry Point Zone".toLowerCase():
        tableName = "EntryPointZone";
        break;
      case "Limit Requests".toLowerCase():
        tableName = "ApprovalRequest";
        break;
      case "Limit Approvals".toLocaleLowerCase():
        tableName = "ApprovalRequest";
        break;
      case "User Access Request".toLowerCase():
        tableName = "UserAccessApprovalRequest";
        break;
      case "Person Of Interest".toLowerCase():
        tableName = "PersonOfInterest";
        break;
      case "Pass Audit".toLowerCase():
        tableName = "AuditLog";
        break;
      case "Pass Transaction".toLowerCase():
        tableName = "PassTransaction";
        break;
      case "User Organization".toLowerCase():
        tableName = "UserOrganization";
        break;
      case "Template Category".toLowerCase():
        tableName = "TemplateCategory";
        break;
      case "Pass Template".toLowerCase():
        tableName = "PassTemplate";
        break;
      case "Team".toLowerCase():
        tableName = "Team";
        break;
      case "Approval Group".toLowerCase():
        tableName = "ApprovalGroup";
        break;
      case "Approval Type".toLowerCase():
        tableName = "ApprovalType";
        break;
      case "Approval Type Site Settings".toLowerCase():
        tableName = "ApprovalTypeSiteSettings";
        break;
      case "Table Function".toLowerCase():
        tableName = "TableFunction";
        break;
      case "Email Template".toLowerCase():
        tableName = "EmailTemplate";
        break;
      case "Approval Request Configuration".toLowerCase():
        tableName = "ApprovalRequestConfig";
        break;
      case "Table Function Return Values".toLowerCase():
        tableName = "TableFunctionReturnValues";
        break;
      case "User Access Approvals".toLowerCase():
        tableName = "ApprovalRequest";
        break;
      case "User Organization Request".toLowerCase():
        tableName = "UserOrganizationRequest";
        break;
      case "UserPhysicalAccessMgmt".toLowerCase():
        tableName = "UserPhysicalAccessMgmt";
        break;
      case "Integration".toLowerCase():
        tableName = "Integration";
        break;
      case "UserPeopleMgmt".toLowerCase():
        tableName = "UserPeopleMgmt";
        break;

    }
    return tableName;
  }

  // returns boolean to check if the search records are to be read from s3/pre-signed url
  private getRecordsFromS3Flag(): boolean {
    switch (this.pageName.toLowerCase()) {
      case "Audit Logs".toLowerCase():
        // return true;
        return false;

      default:
        return false;
    }
  }

  // calls search filter api and returns promise
  public getFiltersAPI(): Promise<any> {
    const apiURL = environment.getAPI("getSearchFilter");

    const body = {
      UserID: this.userService.userID,
      TableName: this.getTableName(),
      ScreenName: this.getPageName(),
      SiteID: this.siteService.selectedSiteID
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.filterData = success.body;
            // console.log(success.body);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // call update search filter api and returns promise
  public updateFilterAPI(filters: Array<any>): Promise<any> {
    const apiURL = environment.getAPI("userSearchFilterCreateUpdate");

    const body = [];

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    filters.forEach((item) => {
      body.push({
        UserID: this.userService.userID,
        SearchFilterID: item.SearchFilterID,
        UserSearchFilterID:
          item.UserSearchFilterID === null ? 0 : item.UserSearchFilterID,
        Active: item.Active ? 1 : 0,
      });
    });

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            // console.log(this.filterData);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // call smart search records through api and return promise
  public smartSearchAPI(key: string, screenName?: string): Promise<any> {
    this.key = key;
    const apiURL = environment.getAPI("getUpdatedSmartSearch");

    if (
      (this.isSearchKeyRequired() && key !== null) ||
      !this.isSearchKeyRequired()
    ) {
      // regex for 24 hrs time format
      const isTimeField = new RegExp(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
      ).test(this.key);

      if (isTimeField) {
        let tempDate = this.utilService.getCurrentSiteDT();
        tempDate = new Date(
          tempDate.getFullYear() +
          "-" +
          tempDate.getMonth() +
          "-" +
          tempDate.getDate() +
          " " +
          this.key
        );
        this.key = this.datePipe
          .transform(tempDate, "yyyy-MM-dd HH:mm")
          .split(" ")[1]
          .trim();
      }

      const body: {
        UserID: number;
        EntryPointID: number;
        OrganizationID: number;
        TableName: string;
        // CsvColumns: string;
        Key: string;
        ScreenName: string;
        SiteID: number;
      } = {
        UserID: this.userService.userID,
        EntryPointID: this.entryPointID,
        OrganizationID: this.organizationID,
        TableName: this.getTableName(screenName),
        // CsvColumns: this.getColumns(),
        Key: this.key,
        ScreenName: screenName ? screenName : this.getPageName(),
        SiteID: this.siteService.selectedSiteID,
      };

      const httpOptions: { headers; observe } = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        observe: "response",
      };

      const promise = new Promise((resolve, reject) => {
        this.http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              // success
              if (!this.getRecordsFromS3Flag())
                this.searchRecords = success.body;
              else {
                // store the pre - signed url in a const from response body
                const signedURL = "";
                this.readFromS3URL(signedURL);
              }
              setTimeout(() => {
                this.spinnerService.setIsLoading(false);
              }, 0);
              resolve();
            },
            (err) => {
              // error
              setTimeout(() => {
                this.spinnerService.setIsLoading(false);
              }, 0);
              reject(err);
            }
          );
      });
      this.smartSearchPromise = promise;
      return promise;
    } else {
      // do nothing and resolve an empty promise
      return new Promise((resolve, reject) => resolve());
    }
  }

  // the method is invoked to get the results from the smart search (multi site)

  public smartSearchMultiSiteAPI(key: string, screenName?: string, tableName?: string, limitNeeded?: boolean, orgID?: number, siteID?: number): Promise<any> {
    this.key = key;
    const apiURL = environment.getAPI("getMultiSiteSmartSearch");
    if (
      (this.isSearchKeyRequired() && key !== null) ||
      !this.isSearchKeyRequired()
    ) {
      // regex for 24 hrs time format
      const isTimeField = new RegExp(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
      ).test(this.key);

      if (isTimeField) {
        let tempDate = this.utilService.getCurrentSiteDT();
        tempDate = new Date(
          tempDate.getFullYear() +
          "-" +
          tempDate.getMonth() +
          "-" +
          tempDate.getDate() +
          " " +
          this.key
        );
        this.key = this.datePipe
          .transform(tempDate, "yyyy-MM-dd HH:mm")
          .split(" ")[1]
          .trim();
      }

      const body: {
        UserID: number;
        EntryPointID: number;
        OrganizationID: number;
        TableName: string;
        Key: string;
        ScreenName: string;
        SiteID: number;
        ObjectID: number;
        ParentObjectField: string;
        LimitNeeded: boolean;
      } = {
        UserID: this.userService.userID,
        EntryPointID: this.entryPointID,
        OrganizationID: orgID ? orgID : this.organizationID,
        TableName: tableName ? tableName : this.getTableName(screenName),
        // CsvColumns: this.getColumns(),
        Key: this.key,
        ScreenName: screenName ? screenName : this.getPageName(),
        SiteID: siteID === 0 ? siteID : this.siteService.selectedSiteID,
        ObjectID: this.objectID ? this.objectID : null,
        ParentObjectField: this.parentFieldName ? this.parentFieldName : null,
        LimitNeeded: limitNeeded === false ? limitNeeded : true
      };

      const httpOptions: { headers; observe } = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        observe: "response",
      };

      const promise = new Promise((resolve, reject) => {
        this.http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              // success
              if (!this.getRecordsFromS3Flag()) {
                this.searchRecords = success.body;
              } else {
                // store the pre - signed url in a const from response body
                const signedURL = "";
                this.readFromS3URL(signedURL);
              }
              setTimeout(() => {
                this.spinnerService.setIsLoading(false);
              }, 0);
              resolve();
            },
            (err) => {
              // error
              setTimeout(() => {
                this.spinnerService.setIsLoading(false);
              }, 0);
              console.error(err);
              reject(err);
            }
          );
      });
      this.smartSearchPromise = promise;
      return promise;
    } else {
      // do nothing and resolve an empty promise
      return new Promise((resolve, reject) => resolve());
    }
  }

  public searchAPI(key: string, screenName?: string, tableName?: string, limitNeeded?: boolean) {
    this.key = key;
    const apiURL = environment.getAPI("getMultiSiteSmartSearch");
    if (
      (this.isSearchKeyRequired() && key !== null) ||
      !this.isSearchKeyRequired()
    ) {
      // regex for 24 hrs time format
      const isTimeField = new RegExp(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
      ).test(this.key);

      if (isTimeField) {
        let tempDate = this.utilService.getCurrentSiteDT();
        tempDate = new Date(
          tempDate.getFullYear() +
          "-" +
          tempDate.getMonth() +
          "-" +
          tempDate.getDate() +
          " " +
          this.key
        );
        this.key = this.datePipe
          .transform(tempDate, "yyyy-MM-dd HH:mm")
          .split(" ")[1]
          .trim();
      }

      const body: {
        UserID: number;
        EntryPointID: number;
        OrganizationID: number;
        TableName: string;
        Key: string;
        ScreenName: string;
        SiteID: number;
        ObjectID: number;
        ParentObjectField: string;
        LimitNeeded: boolean;
      } = {
        UserID: this.userService.userID,
        EntryPointID: this.entryPointID,
        OrganizationID: this.organizationID,
        TableName: tableName ? tableName : this.getTableName(screenName),
        // CsvColumns: this.getColumns(),
        Key: this.key,
        ScreenName: screenName ? screenName : this.getPageName(),
        SiteID: this.siteService.selectedSiteID,
        ObjectID: this.objectID ? this.objectID : null,
        ParentObjectField: this.parentFieldName ? this.parentFieldName : null,
        LimitNeeded: limitNeeded === false ? limitNeeded : true
      };

      const res = this.http.post<any>(apiURL, body, this.httpOptions);
      return res;
    } else {
      // do nothing and resolve an empty 
      return null;
    }
  }

  // called to check if search key is mandatory to invoke the search
  private isSearchKeyRequired() {
    switch (this.pageName.toLowerCase()) {
      case "Audit Logs".toLowerCase():
        return true;
      default:
        return false;
    }
  }

  // invoke to actually read records from pre-signed url
  private readFromS3URL(signedURL: string) {
    // TODO: Read from Pre - signed URL
  }

  // returns search filter array
  public getFilters() {
    return [...this.filterData];
  }

  // the method is invoked to set the page name and it assumes that the search bar would be displayed  
  public setPageName(pageName: string) {
    this.pageName = pageName;
    this.key = null;
    this.isSearchBarHidden = false;
    this.searchRecords = [];
    this.titleService.setTitle(this.pageName);

    // the below line resets the parentFieldName and objectId upon the page name initialization
    this.parentFieldName = null;
    this.objectID = null;
  }

  /**
   * The method is used to configure the smart search to do look ups against a foreign key, specially useful for searches within modals
   * @param parentFieldName Sets the Parent Field Name the search has be looked up against
   * @param objectID Foreign key row ID that references the parent
   */
  public configParentObject(parentFieldName: string, objectID: number) {
    this.parentFieldName = parentFieldName;
    this.objectID = objectID;
  }

  // the method is invoked to hide the search bar
  public hideSearch() {
    this.isSearchBarHidden = true;
  }

  // the method is invoked to return a boolean which tells if the search bar should be displayed in the nav bar
  public displaySearchBar(): boolean {
    return !this.isSearchBarHidden;
  }

  // return the page name
  public getPageName(): string {
    return this.pageName;
  }

  public setOrganizationID(orgId: number) {
    this.organizationID = orgId;
    this.key = null;
  }

  public getOrganizationID(): number {
    return this.organizationID;
  }

  public setEntryPointID(epId: number) {
    this.entryPointID = epId;
  }

  public getEntryPointID(): number {
    return this.entryPointID;
  }
  // Returns the sorting criterion for the particular View
  public getSortingRules() {
    return this.multiSortService
      .getSortingRulesByTableName(this.getTableName())
      .map((sr) => ({
        colId: sr.FieldName,
        // sort: sr.Order.toLowerCase(),
        sort: sr.Order,
      }));
  }

  public userSearchTypeUpdate(searchType: string, userID: number) {
    const apiURL = environment.getAPI("userSearchTypeCreateUpdate");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      SearchType: searchType,
      UserID: userID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}
