import { Component, OnInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { UserService } from "../services/user.service";
import { RoleMapService } from "../services/role-map.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { OrganizationService } from "../services/organization.service";
import { DialogService } from "../services/dialog.service";
import { SpinnerService } from "../services/spinner.service";
import { Site, SiteService } from "../services/site.service";
import { MatSelectChange } from "@angular/material";
import { AuthService } from "../services/auth.service";
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  protected isEditable = false;
  protected organizationsLoaded = false;
  protected organizationsBasketReady = false;
  protected organizationBasket: Array<any> = [];
  protected organizationItems: Array<any> = [];
  protected roleItems: Array<any> = [];
  protected roleBasket: Array<any> = [];
  protected rolesLoaded = false;

  protected firstName: string;
  protected middleName: string;
  protected lastName: string;
  protected suffix: string;
  protected pin: number;
  protected email: string;
  protected phone: string;

  protected user: any = {};

  protected sites: Array<Site> = [];

  private previouslySelectedSiteID: number;
  protected preferredlanguage = [];
  protected phoneIsUSA:boolean = false;
  protected oldLanguageID:any;
  protected languageChanged: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  
  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private roleMapService: RoleMapService,
    private notificationService: NotificationService,
    private organizationService: OrganizationService,
    private dialogService: DialogService,
    protected siteService: SiteService,
    protected authService: AuthService,
    protected messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    this.searchService.setPageName("User Profile");
    this.searchService.hideSearch();
    this.userService.getPreferredLanguage().subscribe((response)=>{
      this.preferredlanguage = response ;
    })

    // initialize the previouslySelectedSiteID
    this.previouslySelectedSiteID = this.siteService.selectedSiteID;

    // Load user details
    this.loadUserDetails();

    // Load Roles
    this.loadRoles();

    // Load Organization Data
    this.loadOrganization();

    // load available sites
    this.sites = this.siteService.getSites();

//labels by message service
if (localStorage.getItem('UserProfileLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('UserProfileLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "User",
      "User"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('UserProfileLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();

  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    console.log("check",labels )
}

  private loadRoles() {
    // Load Role Data
    // need to call API
    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => this.prepareRoleList())
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  private loadOrganization() {
    // call API
    this.organizationService
      .getOrganizationAPI()
      .then(() => {
        // load the organization basket
        this.prepareOrganizationList();
        this.organizationsLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private prepareOrganizationList() {
    // console.log("Organization Item: ");
    // console.log(this.organizationItems);

    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => {
        // load the organization basket
        const organizationBasket = this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            UserOrganizationID: o.UserOrganizationID,
            Active: o.UserOrganizationActive,
            OrganizationID: o.OrganizationID,
            Name: this.organizationService
              .getOrganizations()
              .filter((f) => f.OrganizationID == o.OrganizationID)[0].Name,
            IsAdmin: o.IsAdmin ? 1 : 0,
          }))
          .filter((f) => f.Active);
        this.organizationBasket = organizationBasket;

        const organizationItems = [];
        for (let j = 0; j < this.organizationItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.organizationBasket.length; i++) {
            if (
              this.organizationItems[j].OrganizationID ==
              this.organizationBasket[i].OrganizationID
            ) {
              matchFound = true;
            }
          }
          if (!matchFound) organizationItems.push(this.organizationItems[j]);
        }
        this.organizationItems = organizationItems;

        // console.log("Organization Basket:");
        // console.log(this.organizationBasket);

        this.user.Organization = this.organizationBasket;
        this.organizationsBasketReady = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.organizationItems = this.organizationService
      .getOrganizations()
      .map((o) => ({
        OrganizationID: o.OrganizationID,
        Name: o.Name,
        Active: o.Active,
      }))
      .filter((o) => o.Active === 1);
  }

  private prepareRoleList() {
    const response = this.roleMapService
      .getRoleMappingResponse()
      .filter((rm) => rm.RoleActive)
      .map((rm) => ({
        RoleID: rm.RoleID,
        RoleName: rm.RoleName,
        Active: rm.RoleActive,
      }))
      .filter((rm) => rm.Active == 1);

    // removing duplicates
    this.roleItems = Array.from(
      new Set(response.map((i) => JSON.stringify(i)))
    ).map((i) => JSON.parse(i));

    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => {
        // load the role basket

        let roleBasket = this.userService
          .getUserDetails()[0]
          .UserRole.map((ur) => ({
            UserRoleID: ur.UserRoleID,
            RoleID: ur.RoleID,
            Active: ur.UserRoleActive,
            RoleName: this.roleMapService.getRoleMappingByID(ur.RoleID)[0]
              .RoleName,
          }));

        roleBasket = roleBasket.filter((rb) => rb.Active === 1);
        roleBasket.sort((a, b) =>
          a.RoleID > b.RoleID ? 1 : b.RoleID > a.RoleID ? -1 : 0
        );
        this.roleBasket = roleBasket;
        this.roleBasket = roleBasket.filter(
          ((set) => (f) => !set.has(f.RoleID) && set.add(f.RoleID))(new Set())
        );
        // this.roleBasket = [];

        // for (let i = 0; i < roleBasket.length; i++) {
        //   for (let j = i + 1; j < roleBasket.length; j++) {
        //     if (roleBasket[i].RoleID != roleBasket[j].RoleID) {
        //       this.roleBasket.push(roleBasket[i]);
        //       i = j - 1;
        //       break;
        //     }
        //   }
        // }
        let roleItems = [];
        for (let j = 0; j < this.roleItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.roleBasket.length; i++) {
            if (this.roleItems[j].RoleID == this.roleBasket[i].RoleID) {
              matchFound = true;
            }
          }
          if (!matchFound) roleItems.push(this.roleItems[j]);
        }
        // this.roleBasket = roleBasket;
        this.roleItems = roleItems;

        // console.log("Role Basket: ");
        // console.log(this.roleBasket);

        this.user.Role = roleBasket;

        this.rolesLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private loadUserDetails() {
    const userDetails = this.userService.getUserDetails();
    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => this.prepareUserData())
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  onSelectionChange(event: MatSelectChange) {
    if(this.oldLanguageID === event.value) {
      this.languageChanged = false;
    }
    else {
      this.languageChanged = true;
    }
  }

  private prepareUserData() {
    const userDetails = this.userService.getUserDetails();
    console.log(userDetails)

    this.firstName = userDetails[0].FirstName;
    this.middleName = userDetails[0].MiddleName;
    this.lastName = userDetails[0].LastName;
    this.suffix = userDetails[0].Suffix;
    this.phone = userDetails[0].Phone;
    this.email = userDetails[0].Email;
    this.pin = +userDetails[0].PIN;

    this.user.Active = 1;
    this.user.Email = userDetails[0].Email;
    this.user.EmloyeeRef = userDetails[0].EmployeeRef;
    this.user.FirstName = userDetails[0].FirstName;
    this.user.MiddleName = userDetails[0].MiddleName;
    this.user.LastName = userDetails[0].LastName;
    this.user.OKTAID = userDetails[0].OKTAID;
    this.user.PIN = userDetails[0].PIN;
    this.user.Phone = userDetails[0].Phone;
    this.user.Status = userDetails[0].Status;
    this.user.Suffix = userDetails[0].Suffix;
    //this.user.BadgeID = userDetails[0].UserPhysicalAccessMgmtInfo.BadgeID ? userDetails[0].UserPhysicalAccessMgmtInfo.BadgeID : "";
    this.user.BadgeID = null;
    this.user.UserID = this.userService.userID;
    this.user.UserIntegrations = userDetails[0].UserIntegrations;
    //this.user.UserIDRef = this.userService.userID;
    this.user.PrefEmail = userDetails[0].PrefEmail;
    this.user.PrefPhone = userDetails[0].PrefPhone;
    this.user.PrefLanguageID = userDetails[0].PrefLanguageID;

    this.oldLanguageID = this.user.PrefLanguageID;
  }

  protected updateUser() {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["UserDetailsUpdateConfirm"]? this.screenLabels["UserDetailsUpdateConfirm"]:"Confirm User Details Update",
        this.screenLabels["UserDetailsUpdateConfirmMsg"]? this.screenLabels["UserDetailsUpdateConfirmMsg"]:"Are you sure you want to save the changes ?"   
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm)
          console.log(this.user)
        this.userService
          .updateUserAssociationsAPI(this.user)
          .then(() => {
            this.notificationService.success(this.userService.message);
            if (this.languageChanged === true) {
              this.messageService.setPrefLanguage(this.user.PrefLanguageID);
              this.messageService.getLabelLanguageObs("User Profile","User").subscribe((res: any) => {
              this.oldLanguageID = this.user.PrefLanguageID;
              //if language is changed,triggering all the labelLangugage apis
              //this.messageService.setLanguageID(true);
            })
            }
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
        this.isEditable = false;
      });

    console.log(this.user);
  }

  // the method is invoked when the site is changed
  protected async siteChanged(event: MatSelectChange) {
    const confirmDialog = this.dialogService.openConfirmDialog(
      this.screenLabels["ChangeSite"]? this.screenLabels["ChangeSite"]:"Change Site",
      this.screenLabels["LoggingOutConfirmMsg"]? this.screenLabels["LoggingOutConfirmMsg"]:"You will be logged out of the application after the site change. Would you like to proceed?"
      
    );

    confirmDialog.afterClosed().subscribe(async (confirm: boolean) => {
      console.log(confirm);

      if (confirm) {
        this.siteService.selectedSiteID = event.value;
        this.userService.selectedSiteID = event.value;

        // save the default site preference
        try {
          await this.siteService.setDefaultSiteAPI(event.value);
          this.previouslySelectedSiteID = this.siteService.selectedSiteID;

          this.notificationService.success(this.siteService.message);
        } catch (err) {
          const errorMessage: HttpErrorResponse = err;
          this.notificationService.failure(errorMessage.message);
        }

        // emit site change
        this.siteService.siteChanged.emit(true);
        this.authService.logout();
      } else {
        this.siteService.selectedSiteID = this.previouslySelectedSiteID;
        this.userService.selectedSiteID = this.previouslySelectedSiteID
      }

      console.log("Selected Site ID", this.siteService.selectedSiteID);
    });
  }
}
