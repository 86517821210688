import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ModuleWithComponentFactories,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from "../models/entry-point.model";
import { ViewSelectorService } from "../services/view-selector.service";
import { PassService } from "../services/pass.service";
import { BuildingService } from "../services/building.service";
import { Building } from "../models/building.model";
import { UserService } from "../services/user.service";
import { DatePipe } from "@angular/common";
import { NotificationService } from "../services/notification.service";
import { EntryPointService } from "../services/entry-point.service";
import {
  MatChipInputEvent,
  MatRadioChange,
  MatSelectChange,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatStepper,
} from "@angular/material";
import { DialogService } from "../services/dialog.service";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { OrganizationService } from "../services/organization.service";
import { ParkingLotService } from "../services/parking-lot.service";
import { RequiredFieldsService } from "../services/required-fields.service";
import { SearchService } from "../services/search.service";
import { start } from "repl";
import {
  DataValidator,
  ValidationErrorCodes,
} from "../common/utils/data-validation";
import { SEMICOLON } from "@angular/cdk/keycodes";
import moment from "moment";
import { default as _rollupMoment } from "moment";
import { SiteService } from "../services/site.service";
// import { default as _rollupMoment } from "moment";
import { UtilityService } from "../services/utility.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "../common/mat-date-picker-formatter/mat-date-picker-formatter";
import { SpinnerService } from '../services/spinner.service';
import { ISystemSettingsGetAPIResponse } from '../models/interfaces/system-settings-get-api.model';
import { HolidayService } from "../services/holiday-calendar.service";
import { MessageService } from "../services/message.service";
import { IgxTimePickerComponent } from "igniteui-angular";
import { ApprovalService } from "../services/approval.service";

export interface iAPIBody {
  PassID: number;
  VisitorFirstName: string;
  VisitorMiddleName: string;
  VisitorLastName: string;
  VisitorNameSuffix: string;
  VisitorCompany: string;
  VisitorEmail: string;
  VisitorPhone: string;
  VisitorCountry: number;
  StartTime: string;
  pdist;
  EndTime: string;
  EstArrivalTime: string;
  EstDepartureTime: string;
  VisitReason: number;
  IncludeNonWorkingdays: boolean;
  ExpectedEntryPoint: number;
  EntryPointGroupID: number;
  EntryType: number;
  NotesInternal: string;
  NotesPass: string;
  ParkingLotID: number;
  ParkingSpot: string;
  DestinationBldg: number;
  DestinationBlgFlr: number;
  DestinationRoom: string;
  HostFirstName: string;
  HostMiddleName: string;
  HostLastName: string;
  HostNameSuffix: string;
  HostCompany: string;
  HostPhone: string;
  HostEmail: string;
  HostTWID: string;
  OrganizationID: number;
  Authorizer: number;
  Operator: number;
  PhotoPass: string;
  Active: boolean;
  CopyFromPassID: string;
  PartyID: number;
  EmployeeRef: number;
  UserID: number;
}

export interface DialogData { }

export interface Reason {
  value: string;
  viewValue: string;
}
export interface Point {
  name: string;
}
export interface PointGrps {
  name: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-edit-pass-modal",
  templateUrl: "./desktop/edit-pass-modal.component.desktop.html",
  styleUrls: ["./desktop/edit-pass-modal.component.desktop.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export abstract class EditPassModalComponent implements OnInit {
  @ViewChild("updatePassRef") updatePassForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;
  @ViewChild("startDate") startDate: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('estArrivalTime', { read: IgxTimePickerComponent, static: true })
  public estArrivalTime: IgxTimePickerComponent;
  @ViewChild('estDepartureTime', { read: IgxTimePickerComponent, static: true })
  public estDepartureTime: IgxTimePickerComponent;
  buildingControl = new FormControl();
  protected entryPoinIndicator: boolean = false;
  protected entryPointCrossedBy: number = 0;
  filteredOptions: Observable<string[]>;
  hostFirstNameControl = new FormControl("", Validators.required);
  hostLastNameControl = new FormControl("", Validators.required);
  activationDate = new FormControl("", Validators.required);
  expirationDateControl = new FormControl("", Validators.required);
  departureTimeControl = new FormControl("", Validators.required);
  arrivalTimeControl = new FormControl("", Validators.required);
  entryPointGroupFormControl = new FormControl("", Validators.required);
  visitorLastNameControl = new FormControl("", Validators.required);
  visitorFirstNameControl = new FormControl("", Validators.required);
  protected enableNext: boolean = false;
  protected floor: boolean = false;
  protected displayStartDate;
  protected displayEndDate;
  public entryPointGroupName: string = "";
  public errorMessage: string;
  public isInvalid: Boolean = false;
  private apiBody: any = {};
  protected entryPoints: Array<EntryPoint> = [];
  protected entryPointGroups: Array<EntryPointGroup> = [];
  protected reasonOfVisit: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected isReasonOfVisitLoading = true;
  protected isBuildingLoading = true;
  protected isEntryPointLoading = true;
  protected isParkingDisabled = true;
  protected isExpirationDateDisabled = true;
  protected ModifiedData;
  protected currentData;
  protected orgStartDate: string;
  protected orgEndDate: string;
  protected selectedStartDate;
  protected selectedEndDate;
  protected minDate;
  protected maxDate;
  protected minDuration;
  protected maxDuration;
  protected assignedParking: Array<{
    Name: string;
    ParkingLotID: number;
  }> = [];
  public diffGrp = false;
  public parkingLotName: string;
  public remainingParkingCount: number;
  protected buildings: Array<Building>;
  public remainingParking: any = [];
  protected entryPointZoneLimit: number;
  protected parkingLimit: number;
  protected allZonesSelfGoverened: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [SEMICOLON];
  protected hostEmails: Array<{
    email: String;
    valid: String;
  }> = [];
  public onBackspaceKeydown(event) {
    event.stopImmediatePropagation();
  }

  private chosenBuildingId;
  protected entryPointGroupID: number;
  public buildingName: string = "";

  public buildingNames: string[];
  public buildingList;
  public minTime: string = "";
  public validEntryPointGroup: boolean = false;

  protected holidays: any[] = [];
  protected checkedDates: boolean = false;
  protected checkDatesNext: boolean = false;
  protected checkDatesError: boolean = false;
  protected startElement: boolean = false;
  protected endElement: boolean = false;
  protected dayRangeArray: any[] = [];
  protected workingDays: any[] = [];

  protected floors: any[] = [];
  protected phoneIsUSA: boolean = false;
  protected appLanguage;
  protected capacityMessage;
  public screenLabels: Array<string> = [];
  protected countryList: any;
  protected reqArrivalTime: boolean = false;
  protected reqDepartureTime: boolean = false;
  protected Getfloors: any[] = [];
  protected buildingArr: any[] = [];
  protected passCheckedIn: boolean = false;
  private isIgnoreLocks: boolean = false;
  protected displayWarningMsg: boolean = false;
  protected emailValidation: boolean = false;
  isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
    return emailPattern.test(email);
  }

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: iAPIBody,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPassModalComponent>,
    private _snackBar: MatSnackBar,
    service: ViewSelectorService,
    protected passService: PassService,
    protected buildingService: BuildingService,
    protected userService: UserService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    protected entryPointService: EntryPointService,
    protected dialogService: DialogService,
    private entryPointGroupService: EntryPointGroupService,
    private organizationService: OrganizationService,
    private parkingLotService: ParkingLotService,
    protected searchService: SearchService,
    protected requiredFieldsService: RequiredFieldsService,
    protected siteService: SiteService,
    private utilService: UtilityService,
    protected spinnerService: SpinnerService,
    protected holidayService: HolidayService,
    protected messageService: MessageService,
    protected approvalService: ApprovalService
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
    this.ModifiedData = { ...data };
    this.currentData = { ...data }

    this.passCheckedIn = this.ModifiedData.CheckedIn != 0 ? true : false;
    this.isParkingDisabled = this.ModifiedData.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on

    // set Start Time (Activation Date) to '' if start date is less than the current date
    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => this.initializeDates());
    // Format Arrival and Departure Dates
    var arDate = moment(this.data.StartTime).format("YYYY-MM-DD h:mm:ss");
    var depDate = moment(this.data.EndTime).format("YYYY-MM-DD h:mm:ss");
    this.ModifiedData.StartTime = new Date(arDate);
    this.ModifiedData.EndTime = new Date(depDate);

    // Format Arrival and Departure Times
    var estAr = moment(this.data.EstArrivalTime).format("YYYY-MM-DD h:mm A");
    var estDep = moment(this.data.EstDepartureTime).format("YYYY-MM-DD h:mm A");
    this.ModifiedData.EstArrivalTime = new Date(estAr);
    this.ModifiedData.EstDepartureTime = new Date(estDep);
    if (this.ModifiedData.EndTime) this.isExpirationDateDisabled = false;
    
    //Api call to get ParkingLotDropdown
   /* this.organizationService
      .getOrganizationByIDAPI(this.data.OrganizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];
        details.OrganizationParkingLimitArray.map((p) => {
          if (p.ParkingLotArray) {
            p.ParkingLotArray.map((array) => {
              if (array) {
                this.assignedParking.push(array);
              }
            });
          }
        });
        if (this.data.ParkingLotIDActive === 0) {
          this.assignedParking.push({
            ParkingLotName: this.data.Name,
            ParkingLotID: this.data.ParkingLotID,
          });
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
      */
      this.parkingLotService.getParkingLotLimitWithDateAPI(
        this.datePipe.transform(this.ModifiedData.StartTime, "yyyy-MM-dd"),
        this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"), 
        this.userService.organizationID,
        this.siteService.selectedSiteID      
      )
      .subscribe((res: HttpResponse<any>) => {
        const details = res.body;
        this.assignedParking = details;
        if (this.data.ParkingLotIDActive === 0) {
          this.assignedParking.push({
            Name: this.data.Name,
            ParkingLotID: this.data.ParkingLotID,
          });
        }
      },
        error => {
          this.notificationService.failure(error.message);
        }
      );
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  nextStepValid(startDate, endDate, entryPointGroupId) {
    const capacity = this.screenLabels["CapacityUtilizationMsg"];
    this.entryPointGroupName = this.entryPointGroups.find((element: any) => element.EntryPointGroupID === entryPointGroupId).EntryPointGrpName;
    this.capacityMessage = capacity.replace("<<id1>>", this.entryPointGroupName).replace("<<id2>>", moment(startDate).format("MM/DD/YYYY")).replace("<<id3>>", moment(endDate).format("MM/DD/YYYY"));
   // const groupNames: any[] = this.entryPointGroupService.getEntryPointGroups();
    this.setEntryLimit(
      startDate,
      endDate,
      this.userService.organizationID,
      entryPointGroupId
    );

    this.checkDatesOnNext();

    if (this.ModifiedData.IncludeNonWorkingdays) {
      if (this.checkDatesNext) {
        this.checkDatesError = false;
        this.entryPoinIndicator = false;
      }
    }
    else if (!this.ModifiedData.IncludeNonWorkingdays) {
      if (!this.checkDatesNext) {
        this.checkDatesError = false;
        // this.entryPoinIndicator = false;
      }
      else if (this.checkDatesNext) {
        this.checkDatesError = true;
        this.enableNext = true;
      }
    }
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);

  //'Reason for visit' dropdown
  reasons: Reason[] = [
    { value: "vendor", viewValue: "Vendor" },
    { value: "business meeting", viewValue: "Business Meeting" },
    { value: "screening", viewValue: "Screening" },
    { value: "consultant", viewValue: "Consultant" },
    { value: "interview", viewValue: "Interview" },
    { value: "personal", viewValue: "Personal" },
  ];

  //Building autocomplete
  myControl = new FormControl();

  //Entry type autocomplete
  entryControl = new FormControl();
  entryOptions: Point[] = [
    { name: "1658" },
    { name: "153" },
    { name: "8080" },
    { name: "16616" },
  ];
  filteredPoints: Observable<Point[]>;
  private siteResponse: ISystemSettingsGetAPIResponse;
  entrygrpControl = new FormControl();
  entrygrpOptions: PointGrps[] = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
  ];
  filteredPointGrps: Observable<PointGrps[]>;

  ngOnInit() {
    this.buildingService.getFloors(this.ModifiedData.DestinationBldg).subscribe((res) => {
      this.Getfloors = res.body;
    });

    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    if (this.siteService._siteHasCountry) {
      this.passService.getCountryListAPI().subscribe((res: HttpResponse<any>) => {
        this.countryList = res.body;
      }, error => {
        console.log(error);
      });
    }

    //condition for the host information to be disable for guards
    //It should be enabled for Site Admin, Org Admins, people who can ignore locks
    //Site Admin and Org Admins, by default ignore lock should be 1
    const userOrg = this.userService.getUserDetails();
    let selectedPassOrgID = userOrg[0].UserOrganization.filter(e => e.Name === this.ModifiedData.OrganizationName).map(ele => ele.OrganizationID);
    let isOrgAdmin = 0;
    isOrgAdmin = userOrg[0].UserOrganization.filter(e => e.Name === this.ModifiedData.OrganizationName).map(ele => ele.IsAdmin);

    if(this.userService.organizationID !== selectedPassOrgID[0]) {
      this.passService.checkIsIgnoreLocksAPI(this.userService.userID, selectedPassOrgID[0],false).subscribe((res: HttpResponse<any>) => {
        this.passService.ignoreLocks = res.body.IsIgnoreLocks;
        if (this.passService.ignoreLocks == 1) this.isIgnoreLocks = true;
        if(isOrgAdmin[0] === 1) this.isIgnoreLocks = true;
      })
    }
    else {
      if(isOrgAdmin[0] === 1) this.isIgnoreLocks = true;
      if (this.passService.ignoreLocks == 1) this.isIgnoreLocks = true;  
    }

    // API to call Visit Reason
    this.passService.getVisitReasonsAPI().then(() => {
      this.isReasonOfVisitLoading = false;
      this.reasonOfVisit = this.passService.reasonOfVisit;

      if (this.data.VisitReasonActive === 0) {
        this.reasonOfVisit.push({
          PickListID: this.data.VisitReason,
          TableName: this.data.TableName,
          FieldName: this.data.FieldName,
          DisplayValue: this.data.DisplayValue,
          Active: this.data.VisitReasonActive,
        });
      }
    });

    //Initialise Site Settings  getSiteSettingsAPI
    if (this.ModifiedData.VisitReason === null) {
      this.siteResponse = this.siteService.siteSettings;
      if (this.siteResponse) {
        const defaultReason = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.find(x => x.Name === "DefaultVisitReason")) : null;
        this.ModifiedData.VisitReason = Number(defaultReason.Value);
      }
    }
    this.buildingArr = this.buildingService.getBuildings();
    this.buildingList = this.buildingArr;
    this.buildingNames = this.buildingArr.map(function (item) {
      return item["BuildingName"];
    });
    this.filteredOptions = this.buildingControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    if (this.buildingList.length === 1) {
      this.buildingName = this.buildingList[0].BuildingName;
      this.chosenBuildingId = this.buildingList[0].BuildingID;
    }

    //labels by message service
    if (localStorage.getItem('EditPassLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('EditPassLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Edit Pass Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('EditPassLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    var hostEmails: String[] = this.ModifiedData.HostEmail ? this.ModifiedData.HostEmail.split(";") : null;
    if (this.ModifiedData.HostEmail) {
      hostEmails.forEach((element) => {
        this.hostEmails.push({ email: element, valid: "valid" });
      });
    }

    this.buildingService.parseAPI().then(() => {
      this.isBuildingLoading = false;
      this.buildings = [...this.buildingService.getBuildings()];
      if (this.data.DestinationBldgActive === 0) {
        this.buildings.push({
          BuildingID: this.data.BuildingID,
          BuildingNumber: this.data.BuildingNumber,
          BuildingName: this.data.BuildingName,
          ModifiedBy: null,
          ModifiedDate: null,
          CreatedBy: null,
          CreatedDate: null,
          disabled: true,
          Floors: this.data.Floors,
        });
      }
    });

    if (this.ModifiedData.DestinationBldg > 0) {
      var buildings = this.buildingService.getBuildings();
      this.chosenBuildingId = this.ModifiedData.DestinationBldg;
      this.buildingName = buildings.filter(
        (f) => f.BuildingID == this.ModifiedData.DestinationBldg
      )[0].BuildingName;
    }

    this.isEntryPointLoading = false;

    this.filteredPointGrps = this.entrygrpControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.name)),
      map((name) =>
        name ? this._filterPointGrp(name) : this.entrygrpOptions.slice()
      )
    );

    // Load Entry Point Groups
    // API call is required
   /* this.entryPointGroupService
      .getAllEntryPointGroupsAPI(this.data.OrganizationID)
      .then(() => {
        this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
        this.entryPoints = this.entryPointGroupService.getGroupMappings(
          this.data.EntryPointGroupID
        ).EntryPoints;
        if (this.data.EntryPointGroupIDActive === 0) {
          this.entryPointGroups.push({
            EntryPointGroupID: this.data.EntryPointGroupID,
            EntryPointGrpName: this.data.EntryPointGrpName,
            ModifiedDate: null,
            ModifiedBy: null,
            CreatedBy: null,
            CreatedDate: null,
            Active: this.data.Active,
          });
        }
        if (this.data.EntryPointGroupIDActive === 0) {
          this.ModifiedData.EntryPointGroupID = null;
        }
        if (this.entryPointGroups.length === 1) {
          this.ModifiedData.EntryPointGroupID = this.entryPointGroups[0].EntryPointGroupID;
          this.entryPointGroupID = this.entryPointGroups[0].EntryPointGroupID;
        }
        this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
      */
      this.entryPointGroupService.getEntryPointGroupLimitWithDateAPI(
        this.datePipe.transform(this.ModifiedData.StartTime, "yyyy-MM-dd"),
        this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
        this.userService.organizationID, 
        this.siteService.selectedSiteID
      )
      .subscribe((res: HttpResponse<any>) => {
        this.entryPointGroups = res.body;
        this.entryPointService.parseGetEndpointAPI(this.ModifiedData.EntryPointGroupID)
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
        if (this.data.EntryPointGroupIDActive === 0) {
          this.entryPointGroups.push({
            EntryPointGroupID: this.data.EntryPointGroupID,
            EntryPointGrpName: this.data.EntryPointGrpName,
            ModifiedDate: null,
            ModifiedBy: null,
            CreatedBy: null,
            CreatedDate: null,
            Active: this.data.Active,
          });
        }
        if (this.data.EntryPointGroupIDActive === 0) {
          this.ModifiedData.EntryPointGroupID = null;
        }
        if (this.entryPointGroups.length === 1) {
          this.ModifiedData.EntryPointGroupID = this.entryPointGroups[0].EntryPointGroupID;
          this.entryPointGroupID = this.entryPointGroups[0].EntryPointGroupID;
        }
        this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
      },
        error => {
          this.notificationService.failure(error.message);
        }
      );

    //initialize minTime for timePicker based on arrival time

    if (this.ModifiedData.EstArrivalTime) {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }

    this.holidayService.getHolidaysYearAPI().subscribe(response => {
      response.HolidayDates.forEach(element => {
        let formatDate = moment(element).format("YYYY-MM-DD");
        this.holidays.push(formatDate);
      })
      response.NonworkingDates.forEach(element => {
        let nonworking = moment(element).format("YYYY-MM-DD");
        this.holidays.push(nonworking);
      })
    }, error => {
      console.log(error);
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  //building typeahead
  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.utilService.searchSortKeyUtil(this.buildingNames, value);
  }

  onInputChange () {
    if (this.buildingName == "") {
      this.ModifiedData.DestinationBlgFlr = null;
      this.Getfloors  = [];
    }
  }

  //start code for building dropdown selection //
  private setTimeoutRef: any = null;
  onChangeBuilding(event: string, isBlur?: boolean) {

    if (this.buildingName == "") {
      this.ModifiedData.DestinationBlgFlr = null;
      this.ModifiedData.DestinationBldg = null;
      this.floors.length = null;
    }

    if (this.buildingName && this.ModifiedData.DestinationBlgFlr) {
      this.ModifiedData.DestinationBldg = null;
      this.ModifiedData.DestinationBlgFlr = null;
    }

        // Find the building in buildingArr that matches the provided event (building name)
        const matchingBuilding = this.buildingArr.find(
          (building) => building.BuildingName === event
        );
    
        if (matchingBuilding) {
          // Extract the necessary information from the matching building
          this.chosenBuildingId = matchingBuilding.BuildingID;
        }
        if (this.buildingName) {
          // this is an asynchronous call, subscribe to getFloors
         
          this.floor = false;
          this.buildingService.getFloors(this.chosenBuildingId).subscribe((res) => {
            this.Getfloors = res.body;
            if (this.Getfloors.length < 1) {
              this.floor = false;
            } else if (this.Getfloors.length === 1) {
              this.floor = false;
              this.ModifiedData.DestinationBlgFlr = this.Getfloors[0].BuildingFloorID;
            } else
              this.floor = true;
          });
        }

    // const buildingNames: any[] = this.buildingService.getBuildings();
    // const selectedBuild: any = buildingNames.find((element: any) => element.BuildingName === event);
    // if (selectedBuild) {
    //   if (this.setTimeoutRef) {
    //     clearTimeout(this.setTimeoutRef);
    //     this.setTimeoutRef = null;
    //   }
    //   this.buildingName = selectedBuild.BuildingName;
    //   this.ModifiedData.DestinationBldg = selectedBuild.BuildingID;
    //   this.chosenBuildingId = selectedBuild.BuildingID;
    //   this.buildingService.parseAPI().then(() => {
    //     var buildingArr = this.buildingService.getBuildings();
    //     this.buildingList = buildingArr;
    //     this.buildingNames = buildingArr.map(function (item) {
    //       return item["BuildingName"];
    //     });
    //     this.filteredOptions = this.buildingControl.valueChanges.pipe(
    //       startWith(""),
    //       map((value) => this._filter(value))
    //     );
    //   });
    // } else if (isBlur) {
    //   this.setTimeoutRef = setTimeout(() => {
    //     this.buildingName = "";
    //     this.buildingControl.setValue("");
    //     this.setTimeoutRef = null;
    //   }, 500);
    // }
    // if (this.buildingName) {
      // this.floors = this.buildingService.getBuildingFloors(this.chosenBuildingId ? +this.chosenBuildingId : null);
      // if (this.floors.length >= 1) {
      //   this.enableNext = true;
      // }
      // else if (this.floors.length == 0) {
      //   this.enableNext = false;
      // }

    //   this.buildingService.getFloors(this.chosenBuildingId).subscribe((res)=>{
    //     this.Getfloors = res.body
    //   })

    // }
  }

  onFloor(event) {
    this.floor = false;
    if (this.Getfloors.length >= 1 && this.ModifiedData.DestinationBlgFlr) {
      this.enableNext = false;
    }
    else if (this.Getfloors.length >= 1 && !this.ModifiedData.DestinationBlgFlr) {
      this.enableNext = true;
    }
  }

  ngAfterViewChecked() {
    // setting up the required fields
    // this.requiredFieldsService.setRequiredFields(
    //   'Edit Pass',
    //   this.updatePassForm
    // );
    this.timeValidation();
  }

  //entry point type ahead
  displayPoints(point?: Point): string | undefined {
    return point ? point.name : undefined;
  }

  private _filterPoint(name: string): Point[] {
    const filterValue = name?.toLowerCase();

    return this.entryOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterPointGrp(name: string): PointGrps[] {
    const filterValue = name?.toLowerCase();

    return this.entrygrpOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  //gets chosen destination
  getDestination(building) {
    // this.data.destination = building.name;
  }
  //gets chose entry point
  getPoint(point) {
    // this.data.entryPoint = point.name;
  }

  onNoClick() {
    this.dialogRef.close(false);
    this.passService.editPassOpen = false;
  }

  //email validation
  getErrorMessage() {
    if (this.email.hasError("required")) {
      return this.screenLabels["ValueEnterMsg"] ? this.screenLabels["ValueEnterMsg"] : "You must enter a value";
    }

    return this.email.hasError("email") ? this.screenLabels["NotValidEmailMsg"] ? this.screenLabels["NotValidEmailMsg"] : "Not a valid email" : "";
  }

  onEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      if (!this.ModifiedData.ParkingLotID) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      this.isParkingDisabled = false;
    } else {
      this.enableNext = false;
      this.isParkingDisabled = true;
    }
    if (this.ModifiedData.ParkingLotID > 0) {
    this.setParkingLimit(
      this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
      this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
      this.userService.organizationID
    );
  }
  }

  //when parkingLot Changes
  onParkingLotChange() {
    this.organizationService
      .getParkingLimitAnalyticsAPI(
        this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
      this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
        this.data.OrganizationID,
        this.data.PassID,
        this.ModifiedData.ParkingLotID,
        this.ModifiedData.IncludeNonWorkingdays
      )
      .then(() => {
        this.remainingParking = this.organizationService.parkingLimitAPIResponse;
        if (
          this.remainingParking != undefined &&
          this.remainingParking.length > 0
        ) {
          this.remainingParkingCount = this.remainingParking[0].RemainingParkings; 
          if (this.remainingParking[0].Regulated === 1) this.parkingLimit = 1;
          else
          this.parkingLimit = this.remainingParking[0].RemainingParkings; 
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    if (this.ModifiedData.ParkingLotID) {
      this.enableNext = false;
    }
    else if (!this.ModifiedData.ParkingLotID) {
      this.enableNext = true;
    }
  }

  
  onUpdatePass() {
    this.approvalService.checkApprovalRequestExistsAPI(this.ModifiedData.PassID).subscribe((data: HttpResponse<any>) => {
      if(data.body.ApprovalRequestExists === 1) {
        const passTrigger = this.screenLabels["PassTrigger"] ? this.screenLabels["PassTrigger"] : "Approval Triggered";
        const passTriggerMsg = this.screenLabels["PassTriggerMsg"] ? this.screenLabels["PassTriggerMsg"] : "Approval Process is triggered, pass can't be edited.";
        this.dialogService.openAlertDialogDialog(
          passTrigger,
          passTriggerMsg
        );
      }
      else if(this.passCheckedIn) {
        const passCheckIn = this.screenLabels["PassCheckIn"] ? this.screenLabels["PassCheckIn"] : "Pass Checked-in";
        const passCheckInMsg = this.screenLabels["PassCheckInMsg"] ? this.screenLabels["PassCheckInMsg"] : "Pass is checked-in, can't be edited.";
        this.dialogService.openAlertDialogDialog(
          passCheckIn,
          passCheckInMsg
        );
      }
      else this.onSaveThePass();
    })
  }

  onSaveThePass() {
    if (this.data.EntryPointGroupID !== this.ModifiedData.EntryPointGroupID) {
      this.diffGrp = true;
    } else {
      this.diffGrp = false;
    }
    this.ModifiedData.HostMiddleName = '';
    this.ModifiedData.VisitorMiddleName = '';
    this.ModifiedData.AllowDup = false;
    var hostEmails: String[] = [];
    this.hostEmails.forEach((element) => {
      if (element.valid === "valid") {
        hostEmails.push(element.email);
      }
    });
    this.ModifiedData.HostEmail = hostEmails.join(";");
    console.log("chosenbuildingid",this.chosenBuildingId);
    this.ModifiedData.DestinationBldg = this.chosenBuildingId;
    this.apiBody.HostTWID = null;
    if (this.ModifiedData.EntryType == 2) {
      // If Entry Type is Walk On set Parking Lot ID to null
      this.ModifiedData.ParkingLotID = null;
    }
    if (this.ModifiedData.EntryPointGroupID) {
      this.organizationService
        .getEntryPointZoneLimitAnalyticsAPI(
          this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
          this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
          this.ModifiedData.OrganizationID,
          this.ModifiedData.PassID,
          this.ModifiedData.EntryPointGroupID,
          this.ModifiedData.IncludeNonWorkingdays,
          this.siteService.selectedSiteID
        )
        .then(() => {
          const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse;
          if (
            this.diffGrp &&
            entryPointGrpDetails[0].RemainingEntries <= 0 &&
            !entryPointGrpDetails[0].Regulated
          ) {
            const entryPointZone = this.screenLabels["EntryPointZone"] ? this.screenLabels["EntryPointZone"] : "Entry Point Zone";
            const entryPointZoneFilledMsg = this.screenLabels["EntryPointZoneFilledMsg"] ? this.screenLabels["EntryPointZoneFilledMsg"] : "Entry Point Zone Filled for selected Entry Point Group";
            this.dialogService.openAlertDialogDialog(
              entryPointZone,
              entryPointZoneFilledMsg
            );
          } else if (this.ModifiedData.ParkingLotID > 0) {
            this.organizationService
              .getParkingLimitAnalyticsAPI(
                this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
                this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
                this.data.OrganizationID,
                this.data.PassID,
                this.ModifiedData.ParkingLotID,
                this.ModifiedData.IncludeNonWorkingdays
              )
              .then(() => {
                this.remainingParking = this.organizationService.parkingLimitAPIResponse;
                if (
                  this.remainingParking != undefined &&
                  this.remainingParking.length > 0
                ) {
                  this.remainingParkingCount = this.remainingParking[0].RemainingParkings; 
                }
                if (
                  this.remainingParking.length > 0 && !this.remainingParking[0].Regulated && this.remainingParkingCount <= 0
                ) {
                  if (this.assignedParking.length > 0) {
                    this.parkingLotName = this.assignedParking.filter(
                      (p) => p.ParkingLotID === this.ModifiedData.ParkingLotID
                    )[0].Name;
                  }
                  const parkingLot = this.screenLabels["ParkingLot"] ? this.screenLabels["ParkingLot"] : "Parking Lot";
                  const parkingFilledMsg = (this.screenLabels["ParkingFilledMsg"]).replace("<<id>>", this.parkingLotName);
                  this.dialogService.openAlertDialogDialog(
                    parkingLot,
                    parkingFilledMsg
                  );
                } else {
                  this.dialogService

                 // this.ModifiedData.Status = "Valid";
                  this.ModifiedData.ModifiedBy = this.userService.userID;
                  this.ModifiedData.StartTime = this.datePipe.transform(
                    new Date(this.ModifiedData.StartTime),
                    "yyyy-MM-dd"
                  );
                  this.ModifiedData.EndTime = this.datePipe.transform(
                    new Date(this.ModifiedData.EndTime),
                    "yyyy-MM-dd"
                  );
                  let ArrivalDate = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
                  let ArrivalTime = moment(this.ModifiedData.EstArrivalTime).format("HH:mm:ss");
                  let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
                  this.ModifiedData.EstArrivalTime = this.formatDate(
                    ArrivalTimeDate
                  );
                  let DepatureDate = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
                  let DepatureTime = moment(this.ModifiedData.EstDepartureTime).format("HH:mm:ss");
                  let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
                  this.ModifiedData.EstDepartureTime = this.formatDate(
                    DepatureTimeDate
                  );
                  this.ModifiedData.Operator =
                    this.userService.loggedInUserID > 0
                      ? this.userService.loggedInUserID
                      : this.userService.userID;
                  this.ModifiedData.Authorizer = this.userService.userID;
                  this.ModifiedData.UserID = this.ModifiedData.ModifiedBy;

                  if (this.ModifiedData.PartyID != null) {
                    for (const key in this.ModifiedData) {
                      if (this.ModifiedData[key] !== this.currentData[key]) {
                        this.ModifiedData.PartyID = null;
                      }
                    }
                  }
                  console.log("modifieddata",this.ModifiedData);
                  this.passService.updatePassAPI([this.ModifiedData]).then(() => { })
                    .catch((err) => {
                      this.notificationService.failure(err.message);
                    });
                  this.onNoClick();
                  //   }
                  // });
                }
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          } else {

           // this.ModifiedData.Status = "Valid";
            this.ModifiedData.ModifiedBy = this.userService.userID;
            this.ModifiedData.StartTime = this.datePipe.transform(
              new Date(this.ModifiedData.StartTime),
              "yyyy-MM-dd"
            );
            this.ModifiedData.EndTime = this.datePipe.transform(
              new Date(this.ModifiedData.EndTime),
              "yyyy-MM-dd"
            );
            let ArrivalDate = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
            let ArrivalTime = moment(this.ModifiedData.EstArrivalTime).format("HH:mm:ss");
            let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
            this.ModifiedData.EstArrivalTime = this.formatDate(
              ArrivalTimeDate
            );
            let DepatureDate = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
            let DepatureTime = moment(this.ModifiedData.EstDepartureTime).format("HH:mm:ss");
            let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
            this.ModifiedData.EstDepartureTime = this.formatDate(
              DepatureTimeDate
            );
            this.ModifiedData.UserID = this.ModifiedData.ModifiedBy;
            if (this.ModifiedData.PartyID != null) {
              for (const key in this.ModifiedData) {
                if (this.ModifiedData[key] !== this.currentData[key]) {
                  this.ModifiedData.PartyID = null;
                }
              }
            }
            console.log("modifieddata 2",this.ModifiedData);
            this.passService.updatePassAPI([this.ModifiedData]).then(() => {
              this.notificationService.success(this.passService.message);
            });
            this.onNoClick();
            //   }
            // });
          }
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    } else if (
      this.ModifiedData.EntryType == 1 &&
      this.ModifiedData.ParkingLotID > 0
    ) {
      this.organizationService
        .getParkingLimitAnalyticsAPI(
          this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
          this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
          this.data.OrganizationID,
          this.data.PassID,
          this.ModifiedData.ParkingLotID,
          this.ModifiedData.IncludeNonWorkingdays
        )
        .then(() => {
          this.remainingParking = this.organizationService.parkingLimitAPIResponse;
          if (
            this.remainingParking != undefined &&
            this.remainingParking.length > 0
          ) {
            this.remainingParkingCount = this.remainingParking[0].RemainingParkings; 
          }
          if (
            this.remainingParking.length > 0 && !this.remainingParking[0].Regulated && this.remainingParkingCount <= 0
          ) {
            if (this.assignedParking.length > 0) {
              this.parkingLotName = this.assignedParking.filter(
                (p) => p.ParkingLotID === this.ModifiedData.ParkingLotID
              )[0].Name;
            }
            const parkingLot = this.screenLabels["ParkingLot"] ? this.screenLabels["ParkingLot"] : "Parking Lot";
            const parkingFilledMsg = (this.screenLabels["ParkingFilledMsg"]).replace("<<id>>", this.parkingLotName);
            this.dialogService.openAlertDialogDialog(
              parkingLot,
              parkingFilledMsg
            );
          } else {
           // this.ModifiedData.Status = "Valid";
            this.ModifiedData.ModifiedBy = this.userService.userID;

            this.ModifiedData.StartTime = this.datePipe.transform(
              new Date(this.ModifiedData.StartTime),
              "yyyy-MM-dd"
            );
            this.ModifiedData.EndTime = this.datePipe.transform(
              new Date(this.ModifiedData.EndTime),
              "yyyy-MM-dd"
            );
            let ArrivalDate = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
            let ArrivalTime = moment(this.ModifiedData.EstArrivalTime).format("HH:mm:ss");
            let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
            this.ModifiedData.EstArrivalTime = this.formatDate(
              ArrivalTimeDate
            );
            let DepatureDate = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
            let DepatureTime = moment(this.ModifiedData.EstDepartureTime).format("HH:mm:ss");
            let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
            this.ModifiedData.EstDepartureTime = this.formatDate(
              DepatureTimeDate
            );
            this.ModifiedData.Operator = this.userService.loggedInUserID;
            this.ModifiedData.Authorizer = this.userService.userID;

            this.ModifiedData.UserID = this.ModifiedData.ModifiedBy;

            if (this.ModifiedData.PartyID != null) {
              for (const key in this.ModifiedData) {
                if (this.ModifiedData[key] !== this.currentData[key]) {
                  this.ModifiedData.PartyID = null;
                }
              }
            }
            console.log("modifieddata 3",this.ModifiedData);
            this.passService.updatePassAPI([this.ModifiedData]).then(() => {
              this.notificationService.success(this.passService.message);
            });
            this.onNoClick();
            //   }
            // });
          }
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    } else {

     // this.ModifiedData.Status = "Valid";
      this.ModifiedData.ModifiedBy = this.userService.userID;
      this.ModifiedData.StartTime = this.datePipe.transform(
        new Date(this.ModifiedData.StartTime),
        "yyyy-MM-dd"
      );
      this.ModifiedData.EndTime = this.datePipe.transform(
        new Date(this.ModifiedData.EndTime),
        "yyyy-MM-dd"
      );
      let ArrivalDate = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
      let ArrivalTime = moment(this.ModifiedData.EstArrivalTime).format("HH:mm:ss");
      let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
      this.ModifiedData.EstArrivalTime = this.formatDate(
        ArrivalTimeDate
      );
      let DepatureDate = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
      let DepatureTime = moment(this.ModifiedData.EstDepartureTime).format("HH:mm:ss");
      let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
      this.ModifiedData.EstDepartureTime = this.formatDate(
        DepatureTimeDate
      );
      this.ModifiedData.UserID = this.ModifiedData.ModifiedBy;
      if (this.ModifiedData.PartyID != null) {
        for (const key in this.ModifiedData) {
          if (this.ModifiedData[key] !== this.currentData[key]) {
            this.ModifiedData.PartyID = null;
          }
        }
      }
      console.log("modifieddata 4",this.ModifiedData);
      this.passService.updatePassAPI([this.ModifiedData]).then(() => {
        this.notificationService.success(this.passService.message);
      });
      this.onNoClick();
      //   }
      // });
    }
  }

  formatDate(data: any) {
    let date = this.utilService.stripLocalTimeZone(data.toString());
    return data ? date : null;
  }

  onEntryPointGroupChange(event: MatSelectChange) {
    //For the limit funtionality
    if(event !== this.ModifiedData.EntryPointGroupID) {
      if (this.entryPoinIndicator) {
        this.enableNext = false;
        this.entryPoinIndicator = false;
      }
    }
    if (this.data.EntryPointGroupID !== this.ModifiedData.EntryPointGroupID) {
      this.diffGrp = true;
    } else {
      this.diffGrp = false;
    }
    const entryPointGroupID = event.value;
    this.entryPointService.parseGetEndpointAPI(this.ModifiedData.EntryPointGroupID)
    .then(() => {
      this.entryPoints = [...this.entryPointService.getEntryPoints()];
    })
    .catch((err: HttpErrorResponse) => {
      this.notificationService.failure(err.message);
    });
    this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
  }

  startDateChanged(event) {
    this.ModifiedData.EndTime = "" ; 
    this.checkDateValidation();
    //For the limit funtionality
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    const tempDate = new Date(event.value).getDate();
    if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") < this.datePipe.transform(new Date(this.minDate), "yyyy-MM-dd")) {
      this.ModifiedData.StartTime = undefined;
      this.expirationDate.nativeElement.value = null;
      this.isExpirationDateDisabled = false;
    } else
      if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") > this.datePipe.transform(new Date(this.maxDate), "yyyy-MM-dd")) {
        this.ModifiedData.StartTime = undefined;
        this.expirationDate.nativeElement.value = null;
        this.isExpirationDateDisabled = false;
      } else {

        this.minDuration = new Date(event.value);
        this.maxDuration =
          new Date(this.orgEndDate) <
            new Date(new Date(event.value).setDate(tempDate + 30))
            ? new Date(this.orgEndDate)
            : new Date(new Date(event.value).setDate(tempDate + 30));
        this.expirationDate.nativeElement.value = null;
        this.isExpirationDateDisabled = false;
        this.selectedStartDate = this.minDuration;
        this.selectedEndDate = this.maxDuration;

        //Set Parking Limit based on date change if parking lot is selected
        if (
          this.ModifiedData.ParkingLotID != undefined &&
          this.ModifiedData.ParkingLotID > 0
        ) {
          this.setParkingLimit(
            this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
            this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
            this.userService.organizationID
          );
        }
      }
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.startElement = false;
    this.endElement = false;
    this.dayRangeArray = [];
  }

  endDateChanged(event) {
    this.checkDateValidation();
    //For the limit funtionality
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    this.selectedEndDate = new Date(event.value);
    if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") < this.datePipe.transform(new Date(this.minDuration), "yyyy-MM-dd")) {
      this.ModifiedData.EndTime = undefined;
    } else
      if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") > this.datePipe.transform(new Date(this.maxDuration), "yyyy-MM-dd")) {
        this.ModifiedData.EndTime = undefined;
      } else {
        //Set Parking Limit based on date change if parking lot is selected
        if (
          this.ModifiedData.ParkingLotID != undefined &&
          this.ModifiedData.ParkingLotID > 0
        ) {
          this.setParkingLimit(
            this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
            this.datePipe.transform(this.ModifiedData.EndTime, "yyyy-MM-dd"),
            this.userService.organizationID
          );
        }
      }
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.startElement = false;
    this.endElement = false;
    this.dayRangeArray = [];

    //call entrypointgroup & parkinglotAPIs to populate the dropdown acc to dates
    this.entryPointGroupService.getEntryPointGroupLimitWithDateAPI(
      this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
      this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
      this.userService.organizationID, 
      this.siteService.selectedSiteID
    )
    .subscribe((res: HttpResponse<any>) => {
      this.entryPointGroups = [];
      this.entryPointGroups = res.body;
      this.entryPointService.parseGetEndpointAPI(this.ModifiedData.EntryPointGroupID)
      .then(() => {
        this.entryPoints = [...this.entryPointService.getEntryPoints()];
  
        // this.entryPoints = this.entryPointGroupService.getGroupMappings(
        //   this.data.EntryPointGroupID,
        //   this.entryPointGroups,
        //   true
        // ).EntryPoints;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
      if (this.data.EntryPointGroupIDActive === 0) {
        this.entryPointGroups.push({
          EntryPointGroupID: this.data.EntryPointGroupID,
          EntryPointGrpName: this.data.EntryPointGrpName,
          ModifiedDate: null,
          ModifiedBy: null,
          CreatedBy: null,
          CreatedDate: null,
          Active: this.data.Active,
        });
      }
      if(this.ModifiedData.EntryPointGroupID > 0) {
        let groups = this.entryPointGroups.filter(f => f.EntryPointGroupID == this.ModifiedData.EntryPointGroupID).map(m => m.EntryPointGrpName);
         if(groups.length === 0) {
           this.ModifiedData.EntryPointGroupID = 0;
           this.entryPointGroupID = 0;
           this.displayWarningMsg = true;
         }
       }
      if (this.data.EntryPointGroupIDActive === 0) {
        this.ModifiedData.EntryPointGroupID = null;
      }
      if (this.entryPointGroups.length === 1) {
        this.ModifiedData.EntryPointGroupID = this.entryPointGroups[0].EntryPointGroupID;
        this.entryPointGroupID = this.entryPointGroups[0].EntryPointGroupID;
      }
      this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
    },
      error => {
        this.notificationService.failure(error.message);
      }
    );

    this.parkingLotService.getParkingLotLimitWithDateAPI(
      this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
      this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"), 
      this.userService.organizationID,
      this.siteService.selectedSiteID
    )
      .subscribe((res: HttpResponse<any>) => {
        this.assignedParking = [];
        const details = res.body;
        this.assignedParking = details;
        if (this.data.ParkingLotIDActive === 0) {
          this.assignedParking.push({
            Name: this.data.Name,
            ParkingLotID: this.data.ParkingLotID,
          });
        }
        if(this.ModifiedData.ParkingLotID > 0) {
          let parkings = this.assignedParking.filter(f => f.ParkingLotID == this.ModifiedData.ParkingLotID).map(m => m.Name);
           if(parkings.length === 0) {
             this.ModifiedData.ParkingLotID = 0;
             //this.ParkingLotID = 0;
             this.displayWarningMsg = true;
           }
         }
      },
        error => {
          this.notificationService.failure(error.message);
        }
      );
  }
  initializeDates() {
    var d = this.utilService.getCurrentSiteDT();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    const userAssociations = this.userService.getUserDetails();
    const selectedOrg = userAssociations[0].UserOrganization.filter(
      (uo) => uo.OrganizationID === this.data.OrganizationID
    );

    this.orgStartDate = selectedOrg[0].Startdate;

    this.orgEndDate = selectedOrg[0].Internal
      ? new Date(year + 10, month, day)
      : selectedOrg[0].EndDate;
    this.minDate =
      new Date(this.orgStartDate) > this.utilService.getCurrentSiteDT()
        ? new Date(this.orgStartDate)
        : this.utilService.getCurrentSiteDT();
    this.minDuration = this.minDate;
    this.maxDate =
      new Date(this.orgEndDate) <
        new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14))
        ? new Date(this.orgEndDate)
        : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14));

    this.maxDuration =
      new Date(this.orgEndDate) <
        new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 30))
        ? new Date(this.orgEndDate)
        : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 30));
    var arDate = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD h:mm:ss");
    const tempDate = new Date(arDate).getDate();
    this.minDuration = new Date(arDate);
    this.maxDuration =
      new Date(this.orgEndDate) <
        new Date(new Date(arDate).setDate(tempDate + 30))
        ? new Date(this.orgEndDate)
        : new Date(new Date(arDate).setDate(tempDate + 30));
    this.selectedStartDate = this.minDuration;
    this.selectedEndDate = this.maxDuration;
  }

  //set Parking limit and parking crossed by
  setParkingLimit(startDate, endDate, organizationID) {
    this.organizationService
      .getParkingLimitAnalyticsAPI(
        startDate,
        endDate,
        organizationID,
        this.ModifiedData.PassID,
        this.ModifiedData.ParkingLotID,
        this.ModifiedData.IncludeNonWorkingdays
      )
      .then(() => {
        this.remainingParking = this.organizationService.parkingLimitAPIResponse;
        if (
          this.remainingParking != undefined ||
          this.remainingParking.length > 0
        ) {
          if (this.remainingParking[0].Regulated === 1) this.parkingLimit = 1;
          else
          this.parkingLimit = this.remainingParking[0].RemainingParkings; 
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  //set Entry limit and Entry crossed by
  setEntryLimit(startDate, endDate, organizationID, entryPointGroupID) {
    this.spinnerService.setIsLoading(true);
    this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
    this.displayStartDate = moment(startDate).format("MM/DD/YYYY");
    this.displayEndDate = moment(endDate).format("MM/DD/YYYY");
    this.organizationService
      .getEntryPointZoneLimitAnalyticsAPI(
        startDate,
        endDate,
        organizationID,
        this.ModifiedData.PassID,
        entryPointGroupID,
        this.ModifiedData.IncludeNonWorkingdays,
        this.siteService.selectedSiteID
      )
      .then(() => {
        this.spinnerService.setIsLoading(false);
        const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse
        this.allZonesSelfGoverened = false;
        this.entryPoinIndicator = false;
        this.entryPointCrossedBy = 0;
        if (entryPointGrpDetails[0].Regulated) {
          this.allZonesSelfGoverened = true;
          //this.step++;
          this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        } else {
          this.entryPointZoneLimit = entryPointGrpDetails[0].RemainingEntries;
          //For the single pass creation
          if (this.entryPointZoneLimit <= 0) {
            this.entryPoinIndicator = true;
            this.enableNext = true;
            //on holiday msg...hiding entry limit msg
            if (this.checkDatesError) {
              this.entryPoinIndicator = false;
            }
          } else {
            //this.step++;
            this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.spinnerService.setIsLoading(false);
        this.notificationService.failure(err.message);
        this.enableNext = true;
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || "").trim()) {
      if (DataValidator.isEmail(value) == ValidationErrorCodes.Ok) {
        this.hostEmails.push({ email: value.trim(), valid: "valid" });
        this.emailValidation = false;
      } else {
        this.hostEmails.push({ email: value.trim(), valid: "invalid" });
        this.emailValidation = true;
      }
    }
    // Reset the input value

    if (input) {
      input.value = "";
    }
  }

  remove(index: number): void {
    if (index >= 0) {
      this.hostEmails.splice(index, 1);
      this.emailValidation = this.hostEmails.some(e => e.valid === "invalid");
    }
  }

  onEmailValueChange(index: number): void {
    var email = "hostEmail" + index;
    var changedValue = this.updatePassForm.value[email];
    if ((changedValue || "").trim()) {
      if (DataValidator.isEmail(changedValue) == ValidationErrorCodes.Ok) {
        this.hostEmails[index] = { email: changedValue.trim(), valid: "valid" };
      } else {
        this.hostEmails[index] = {
          email: changedValue.trim(),
          valid: "invalid",
        };
      }
    }
    this.emailValidation = this.hostEmails.some(e => e.valid === "invalid");
  }

  HostIsSameAsAuthoriser() {
    if (this.isIgnoreLocks) {
      const userDetails = this.userService.getUserDetails();
      if (!this.disabled) {
        this.ModifiedData.HostFirstName = userDetails[0].PrefFirstName ? userDetails[0].PrefFirstName : this.userService.loggedInUserFirstName;
        // this.ModifiedData.HostMiddleName = this.userService.loggedInUserMiddleName;
        this.ModifiedData.HostMiddleName = "";
        this.ModifiedData.HostLastName = userDetails[0].PrefLastName ? userDetails[0].PrefLastName : this.userService.loggedInUserLastName;
        this.ModifiedData.HostNameSuffix = this.userService.loggedInSuffix;
        this.hostEmails = [];
        this.hostEmails.push({ email: userDetails[0].PrefEmail ? userDetails[0].PrefEmail : this.userService.loggedInEmail, valid: "valid" });
        this.ModifiedData.HostPhone = userDetails[0].PrefPhone ? userDetails[0].PrefPhone : this.userService.loggedInPhone;
        this.ModifiedData.HostCompany = this.userService.userOrganizations.filter(
          (f) => f.OrganizationID == this.userService.organizationID
        )[0].Name;
      } else {
        this.ModifiedData.HostFirstName = "";
        this.ModifiedData.HostMiddleName = "";
        this.ModifiedData.HostLastName = "";
        this.ModifiedData.HostNameSuffix = "";
        this.hostEmails = [];
        this.ModifiedData.HostPhone = "";
        this.ModifiedData.HostCompany = "";
      }
    }
  }
  timeValidation () {
    let DepatureDate = moment(this.updatePassForm.value.endTime).format("YYYY-MM-DD");
      let DepatureTime = moment(this.updatePassForm.value.estDepartureTime).format("HH:mm:ss");
      let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
      let ArrivalDate = moment(this.updatePassForm.value.startTime).format("YYYY-MM-DD");
      let ArrivalTime = moment(this.updatePassForm.value.estArrivalTime).format("HH:mm:ss");
      let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
      if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
        this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
        this.isInvalid = true;
        //this.enableNext = true;
      }
      else {
        this.isInvalid = false;
        //this.enableNext = false;
      }
  }

  OnArrivalTimeChange(event) {
    if (this.estArrivalTime.cleared) {
      event.newValue = "Invalid Date"
    }
    //this.ModifiedData.EstDepartureTime ='';
    if (event.newValue == "Invalid Date" || this.ModifiedData.EstArrivalTime == "Invalid Date") {
      this.ModifiedData.EstArrivalTime = "";
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }
    let DepatureDate = moment(this.updatePassForm.value.endTime).format("YYYY-MM-DD");
      let DepatureTime = moment(this.updatePassForm.value.estDepartureTime).format("HH:mm:ss");
      let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
      let ArrivalDate = moment(this.updatePassForm.value.startTime).format("YYYY-MM-DD");
      let ArrivalTime = moment(this.updatePassForm.value.estArrivalTime).format("HH:mm:ss");
      let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
      if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      //this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      //this.enableNext = false;
    }
  }
  OnDepartureTimeChange(event) {
    if (this.estDepartureTime.cleared) {
      event.newValue = "Invalid Date"
    }
    this.isInvalid = false;
    if (event.newValue == "Invalid Date" || this.ModifiedData.EstDepartureTime == "Invalid Date") {
      this.ModifiedData.EstDepartureTime = "";
      this.errorMessage = this.screenLabels["TimeInvalidMsg"] ? this.screenLabels["TimeInvalidMsg"] : "Departure time is Invalid";
      this.isInvalid = true;
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.ModifiedData.EstDepartureTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.ModifiedData.EstDepartureTime).format(
          "HH:mm"
        );
      }
    }
    let DepatureDate = moment(this.updatePassForm.value.endTime).format("YYYY-MM-DD");
      let DepatureTime = moment(this.updatePassForm.value.estDepartureTime).format("HH:mm:ss");
      let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
      let ArrivalDate = moment(this.updatePassForm.value.startTime).format("YYYY-MM-DD");
      let ArrivalTime = moment(this.updatePassForm.value.estArrivalTime).format("HH:mm:ss");
      let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
      if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
     // this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      //this.enableNext = false;
    }
  }

  OnIncludeNonWorkingDays() {
    if (!this.ModifiedData.IncludeNonWorkingdays) {
      this.checkDateValidation();
    }
    if (this.checkedDates) {
      if (this.ModifiedData.IncludeNonWorkingdays) {
        this.checkedDates = false;
      }
    }
    if (this.checkDatesError) {
      if (this.ModifiedData.IncludeNonWorkingdays) {
        this.enableNext = false;
        this.checkDatesError = false;
      }
      else {
        this.enableNext = true;
      }
    }
    if (this.entryPoinIndicator) {
      if (this.ModifiedData.IncludeNonWorkingdays) {
        this.enableNext = false;
        this.entryPoinIndicator = false;
      }
      else {
        this.enableNext = true;
      }
    }
  }

  checkDateValidation() {
    this.checkedDates = false;
    let formattedStart = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
    let formattedEnd = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
    let start = Date.parse(formattedStart);
    let end = Date.parse(formattedEnd);

    this.holidays.forEach(element => {
      if ((Date.parse(element) <= end) && (Date.parse(element) >= start)) {
        this.checkedDates = true;
        return this.checkedDates;
      }
    })
    return this.checkedDates;
  }

  checkDatesOnNext() {
    this.checkDatesNext = false;
    let formattedStart = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
    let formattedEnd = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
    let start = Date.parse(formattedStart);
    let end = Date.parse(formattedEnd);

    //getting dates between start-end dates
    let daylist = this.getDaysArray(formattedStart, formattedEnd);

    daylist.forEach(element => {
      let dayRange = moment(element).format("YYYY-MM-DD");
      this.dayRangeArray.push(dayRange);
    });
    //filtering weekdays from dayrange
    this.workingDays = this.dayRangeArray.filter(val => !this.holidays.includes(val));

    this.holidays.forEach(first => {
      if ((Date.parse(first) == start)) {
        this.startElement = true;
      }
    });
    this.holidays.forEach(second => {
      if ((Date.parse(second) == end)) {
        this.endElement = true;
      }
    });
    if (this.startElement && this.endElement && this.workingDays.length == 0) {
      this.checkDatesNext = true;
      return this.checkDatesNext;
    }
    return this.checkDatesNext;
  }

  getDaysArray(startingDate, endingDate) {
    for (var arr = [], dt = new Date(startingDate); dt <= new Date(endingDate); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  onValidFailed(event) { }

}
