import { Injectable, EventEmitter, Output } from "@angular/core";
import { EntryPoint } from "../models/entry-point.model";
import { EntryPointStation } from "../models/entry-point-station.model";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { IEntryPointReadAPI } from "../models/interfaces/entrypoint-read-api";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { ParkingLot } from "../models/parking-lot.model";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root",
})
export class ParkingLotService {
  public entryPoints: EntryPoint[];
  private activeEntryPoints: EntryPoint[] = [];
  private entryPointStations: EntryPointStation[];
  protected entryPointStationApiData: Array<IEntryPointReadAPI>;
  public parkingLots: ParkingLot[] = [];
  protected parkingLotsApiData: Array<ParkingLot>;

  @Output() parkingLotDataChanged: EventEmitter<boolean>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private utilService: UtilityService
  ) {
    this.parkingLotDataChanged = new EventEmitter<boolean>();
    this.parkingLots = [];
    this.getParkingLotAPI();
  }
  //Method to return parking lot name
  public getParkingLotName(parkingLotID){
    this.parkingLots.forEach((element) => {
      if(element.ParkingLotID === parkingLotID){
        var name = element.Name
        return name;
      }
    });

  }
  // Method to return Parking lot array
  public getParkingLot(): ParkingLot[] {
    return this.parkingLots;
  }

  public getParkingLotAPI(): Promise<any> {
    const apiURL = environment.getAPI("getParkingLot");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, { SiteID: this.siteService.selectedSiteID }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.parkingLotsApiData = success.body;
            this.parkingLots = new Array<ParkingLot>();

            this.parkingLotsApiData.forEach((element) => {
              let pl = new ParkingLot(
                element.ParkingLotID,
                element.Name,
                element.Description,
                element.DriveOnRequired ? true : false,
                element.EmailDirections,
                element.DefaultEntryPoint,
                element.ParkingLotGroupID,
                element.DefaultEntryPointName,
                element.ParkingLotGroupName,
                element.Active ? 1 : 0,
                element.ModifiedBy ? +element.ModifiedBy : null,
                new Date(element.ModifiedDate),
                element.CreatedBy ? +element.CreatedBy : null,
                new Date(element.CreatedDate)
              );
              this.parkingLots.push(pl);
            });
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to add a Parking Lot
  public createParkingLot(parkingLot: ParkingLot): number {
    let temp: ParkingLot = { ...parkingLot };
    let parkingLotID: number = this.parkingLots.length + 1;
    temp.ParkingLotID = parkingLotID;
    temp.CreatedDate = this.utilService.getCurrentSiteDT();
    this.parkingLots.push(temp);

    this.createParkingLotAPI(parkingLot).then(() => {
    });

    return parkingLotID;
  }

  public createParkingLotAPI(parkingLot: ParkingLot) {
    const apiURL = environment.getAPI("parkingLotCreate");

    const postBody = {
      ParkingLotGroupId: parkingLot.ParkingLotGroupID,
      Name: parkingLot.Name,
      Description: parkingLot.Description,
      EmailDirections: "Directions",
      DriveOnRequired: parkingLot.DriveOnRequired,
      DefaultEntryPoint: parkingLot.DefaultEntryPoint,
      Active: 1,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            this.notificationService.success(success.body.Message);
            this.parkingLotDataChanged.emit(true);
          //  resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to update a Parking Lot by id
  public updateParkingLot(parkingLot: ParkingLot) {
    return this.updateParkingLotAPI(parkingLot).then(() => {
      // this.parkingLotDataChanged.emit(true);
      // this.notificationService.success("Parking Lot Updated Successfully.");
    });
  }

  public updateParkingLotAPI(parkingLot: ParkingLot) {
    const apiURL = environment.getAPI("parkingLotUpdate");

    const postBody = {
      ParkingLotID: parkingLot.ParkingLotID,
      ParkingLotGroupId: parkingLot.ParkingLotGroupID,
      Name: parkingLot.Name,
      Description: parkingLot.Description,
      EmailDirections: "Directions",
      DriveOnRequired: parkingLot.DriveOnRequired,
      DefaultEntryPoint: parkingLot.DefaultEntryPoint,
      Active: parkingLot.Active,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.notificationService.success(success.body.Message);
            this.parkingLotDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
  // Method to delete Parking Lot by id

  public deleteParkingLot(parkingLot: ParkingLot) {
    parkingLot.Active = 0; //disabling parking lot
    return this.updateParkingLotAPI(parkingLot).then(() =>
      this.parkingLotDataChanged.emit(true)
    );
  }

  public getParkingLotLimitWithDateAPI(
    startDate:string,
    endDate:string,
    organizationID: number,
    siteID:number,
  ){
    const apiURL = environment.getAPI("getParkingLotLimitWithDate");
    //const apiURL = 'https://a2kct0b4wl.execute-api.us-west-2.amazonaws.com/qa/getParkingLotLimitWithDate'

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      StartDate: startDate,
      EndDate: endDate,
      OrganizationID: organizationID,
      SiteID: siteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}
