import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { ParkingLotGroupService } from "../../../services/parking-lot-group.service";
import { ParkingLotService } from "../../../services/parking-lot.service";
import { ParkingLot } from "../../../models/parking-lot.model";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { ParkingLotGroup } from "../../../models/parking-lot-group.model";
import { disableDebugTools } from "@angular/platform-browser";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-create-parking",
  templateUrl: "./create-parking-modal.component.html",
  styleUrls: ["./create-parking-modal.component.css"],
})
export class CreateParkingModalComponent implements OnInit {
  protected step = 0;
  protected parkingLot: ParkingLot;
  protected entryPoints: Array<EntryPoint>;
  protected parkingLotGroups: Array<ParkingLotGroup>;
  // descriptionFormControl = new FormControl("", [Validators.required]);
  // nameFormControl = new FormControl("", [Validators.required]);
  // parkingLotGroupFormControl = new FormControl("", [Validators.required]);
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("createForm") createForm: NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingLot,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateParkingModalComponent>,
    protected entryPointService: EntryPointService,
    protected parkingLotGroupService: ParkingLotGroupService,
    protected parkingLotService: ParkingLotService,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {
    this.entryPoints = [];
    this.parkingLotGroups = [];
  }

  ngOnInit(): void {
    //labels by message service
if (localStorage.getItem('CreateParkinLotLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateParkinLotLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Parking Lot Modal",
      "ParkingLot"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateParkinLotLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.parkingLot = new ParkingLot(
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      this.userService.userID,
      null
    );
    if (this.entryPointService.getEntryPoints().length != 0) {
      this.entryPoints = [...this.entryPointService.getEntryPoints()];
    } else {
      this.entryPointService
        .parseGetEndpointAPI()
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
    if (this.parkingLotGroups.length == 0) {
      this.parkingLotGroupService
        .getParkingLotGroupAPI()
        .then(() => {
          this.parkingLotGroups = [
            ...this.parkingLotGroupService.getParkingLotGroups(),
          ];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Add Parking Lot',
      this.createForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {

    const name = this.parkingLot.Name.trim() === this.parkingLot.Name ? this.parkingLot.Name : this.parkingLot.Name.trim();
    this.parkingLot.Name = name ;

    if (!this.parkingLot.DriveOnRequired) {
      this.parkingLot.DefaultEntryPoint = null;
    }
    const parkingLotId = this.parkingLotService.createParkingLot(
      this.parkingLot
    );
    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.parkingLot.Name || !this.parkingLot.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // entrydataForm: FormGroup = new FormGroup({
  //   name: this.nameFormControl,
  //   description: this.descriptionFormControl,
  // });
  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  // getDescRequiredErrorMessage() {
  //   return this.descriptionFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}