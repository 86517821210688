import { Injectable, Output, EventEmitter } from "@angular/core";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { EntryPointGroupMap } from "../models/entry-point-group-map.model";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { EntryPoint } from "../models/entry-point.model";
import { EntryPointService } from "./entry-point.service";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";

@Injectable({
  providedIn: "root",
})
export class EntryPointGroupService {
  private entryPointGroups: EntryPointGroup[];
  private entryPointGroupMap: EntryPointGroupMap[];
  private postBody;
  public entryPointGroupAPIResponse: Array<{
    EntryPointGroupID: number;
    EntryPointGrpName: string;
    EntryPointGroupActive: number;
    EntryPointGroupMappingID: number;
    EntryPointGroupMappingActive: number;
    EntryPointID: number;
    Description: string;
    EntryPointActive: number;
    ModifiedBy: string;
    ModifiedDate: string;
    CreatedBy: string;
    CreatedDate: string;
  }>;
  public message: string;

  @Output() entryPointGroupDataChanged: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private entryPointService: EntryPointService,
    private siteService: SiteService
  ) {
    this.entryPointGroups = [];

    this.entryPointGroupMap = [];
  }

  createEntryPointGroupAPI(
    entryPointGrpName: string,
    isEntryPointGroupActive: boolean,
    entryPoints: Array<EntryPoint>
  ): Promise<any> {
    const apiURL = environment.getAPI("entryPointGroupCreate");

    const postBody = {
      EntryPointGrpName: entryPointGrpName,
      Active: isEntryPointGroupActive,
      EntryPoint: entryPoints.map(({ EntryPointID, Active }) => ({
        EntryPointID,
        Active,
      })),
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            console.log(success.body);
            this.entryPointGroupDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  getAllEntryPointGroupsAPI(organizationID?: number): Promise<any> {
    const apiURL = environment.getAPI("getEntryPointGroup");

    if (organizationID) {
      this.postBody = {
        SiteID: this.siteService.selectedSiteID,
        OrganizationID: organizationID
      }
    }
    else {
      this.postBody = {
        SiteID: this.siteService.selectedSiteID
      }
    }

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, this.postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.entryPointGroupAPIResponse = success.body;
            console.log(success.body);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    console.log(promise);
    return promise;
  }

  getGroupMappings(groupID: number, entrypointGroupslist?:any, onLimit?: boolean) {
    let mappings;
    if(onLimit === true) {
       mappings = entrypointGroupslist.filter(
        (x) => x.EntryPointGroupID === groupID && x.EntryPointGroupMappingActive
      );
    }
    else {
       mappings = this.entryPointGroupAPIResponse.filter(
        (x) => x.EntryPointGroupID === groupID && x.EntryPointGroupMappingActive
      );
    }
    let entryPoints: Array<EntryPoint> = [];

    console.log("Full Entry Point Group Mappings", mappings);

    this.entryPointService.getEntryPoints().forEach((epItem) => {
      mappings.forEach((mItem) => {
        if (epItem.EntryPointID == mItem.EntryPointID) {
          entryPoints.push(epItem);
        }
      });
    });

    console.log("Filtered Entry Point Group Mappings", mappings);

    if (!mappings.length) {
      return {};
    }
    // console.log(entryPoints);
    return {
      EntryPointGroupID: groupID,
      EntryPointGroupName: mappings[0].EntryPointGrpName,
      EntryPoints: entryPoints,
    };
  }

  updateEntryPointGroupAPI(
    entryPointGroupID: number,
    entryPointGrpName: string,
    isEntryPointGroupActive: boolean,
    entryPoints: Array<EntryPoint>
  ): Promise<any> {
    const apiURL = environment.getAPI("entryPointGroupUpdate");

    let associatedEntryPoints: { EntryPointID: number; Active: number }[] = [];

    console.log(entryPoints, entryPoints ? true : false);

    if (entryPoints) {
      associatedEntryPoints = entryPoints.map(({ EntryPointID, Active }) => ({
        EntryPointID,
        Active,
      }));
    }

    const postBody = {
      EntryPointGroupID: entryPointGroupID,
      EntryPointGrpName: entryPointGrpName,
      Active: isEntryPointGroupActive,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
      EntryPoint: associatedEntryPoints,
    };

    console.log("Post Body:");
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            console.log(success.body);
            this.entryPointGroupDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  IsAllEntryPointsGroupLoaded(): boolean {
    if (this.entryPointGroupAPIResponse === undefined) {
      return false;
    } else {
      return true;
    }
  }

  getEntryPointGroups() {
    console.log(this.entryPointGroupAPIResponse);
    if (!this.entryPointGroupAPIResponse) {
      // API has not been loaded
      return [];
    }
    const records = this.entryPointGroupAPIResponse
      .filter((epg) => epg.EntryPointGroupMappingActive == 1)
      .map((egAPI) => ({
        EntryPointGroupID: egAPI.EntryPointGroupID,
        EntryPointGrpName: egAPI.EntryPointGrpName,
        ModifiedBy: egAPI.ModifiedBy,
        ModifiedDate: new Date(egAPI.ModifiedDate),
        CreatedBy: egAPI.CreatedBy,
        CreatedDate: new Date(egAPI.CreatedDate),
        Active: egAPI.EntryPointGroupActive
      }));

    //remove duplicates
    const uniqueArray = records.filter((obj, index) => {
      const _thing = JSON.stringify(obj);
      return (
        index ===
        records.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
    this.entryPointGroups = uniqueArray;
    console.log(this.entryPointGroups);
    return this.entryPointGroups;
  }

  getEntryPointGroupResponse() {
    return [...this.entryPointGroupAPIResponse];
  }

  public getEntryPointGroupLimitWithDateAPI(
    startDate:string,
    endDate:string,
    organizationID:number,
    siteID:number,
  ){
    const apiURL = environment.getAPI("getEntryPointGroupLimitWithDate");
    //const apiURL = 'https://1hmwou3mb8.execute-api.us-west-2.amazonaws.com/qa/getEntryPointGroupLimitWithDate';

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      StartDate: startDate,
      EndDate: endDate,
      OrganizationID: organizationID,
      SiteID: siteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}
